
import { Appointments } from './Appointments/index'
import { employee } from './EmployeeHome';
import { customer } from './CustomerHome';
import { services } from './Services';
import { employeeAccess } from './EmployeeAccess';
//-----------------------|| MENU ITEMS ||-----------------------//
let role = localStorage.getItem('setRole')
let Role1 = [Appointments, employee, customer, services]
let Role2 = [Appointments, employee, customer, services]
let Role3 = [Appointments, employeeAccess]
let Role4 = [Appointments, employeeAccess]

let sidebarItems = {
    1: Role1,
    2: Role2,
    3: Role3,
    4: Role4
}
const menuItems = {
    items: role ? sidebarItems[role] : []
};

export default menuItems;







// items: [
//     // dashboard,
//     // widget,
//     Appointments,
//     employee,
//     customer,
//     services
//     // application,
//     // forms,
//     // elements,
//     // pages,
//     // utilities,
//     // support,
//     // other
// ]
// };