// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime } from '@tabler/icons';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const employee = {
    id: 'employee',
    title: <FormattedMessage id="employee" />,
    type: 'group',
    children: [
        {
            id: 'employeehome',
            title: <FormattedMessage id="Employee" />,
            type: 'item',
            url: '/employeeHome',
            icon: PeopleAltIcon,
            breadcrumbs: false,
        },

    ]
}