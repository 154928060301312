import React from 'react'
import { colors } from '../../customFunctions/colorsObj';
// import { backgroundColors } from '../../customFunctions/colorsObj';
import { colorsArray1 } from '../../customFunctions/ColorsArray'
export default function GenerateAvatar(props) {
    const { string, style } = props;
    // var colors = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];

    return (
        <div style={{
            width: 40,
            height: 40,
            backgroundColor: string ? colorsArray1[string.slice(0, 2).toUpperCase()] : 'grey',
            borderRadius: 40,
            color: 'white',
            fontSize: 17,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Lato',
            ...style
        }}>
            {string && string.charAt(0) || ""}
        </div>
    )
}
