import { Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation'
import { ArrowBack, Home } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { order_successful } from '../../assets/images'
import SearchBar from './Navbar'
import { useParams } from 'react-router-dom'



export default function OrderSuccessful() {
    const { orderID } = useParams()

    return (
        <div style={{ backgroundColor: 'white', padding: '0px 10px', borderRadius: 4, }}>
            {/* <Grid item container xs={12} lg={12} sm={12} md={12} mt={2} alignItems={'center'} justifyContent={'space-between'}>
                <Link to="/shopping">
                    <IconButton color='primary'>
                        <Home />
                    </IconButton>
                </Link>
                <Typography variant='h3' color={'#94c100'}>Order Successful</Typography>
                <span></span>
            </Grid> */}
            <SearchBar />
            <Grid item container xs={12} lg={12} sm={12} md={12} gap={1} sx={{ padding: '20px' }}>

                <Grid item container lg={12} xs={12} md={12} sm={12}
                    flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={1} >
                    <img src={order_successful} height={180} width={'100%'} style={{ objectFit: 'contain', padding: 20 }} />
                    <Typography variant='h3' textAlign={'center'}>Your Order has been placed successfully</Typography>
                    {/* <Typography variant='button'>Your Order ID is : <strong>{productId}</strong></Typography> */}
                </Grid>
                <Grid item container lg={12} xs={12} md={12} sm={12} justifyContent={'center'} alignItems={'flex-start'} gap={1}>
                    {/* <Grid item xs={12} md={2} sm={3} lg={2}>
                        <Link to={`/shopping/order-details/${orderID}`}>

                            <Button variant="contained" fullWidth
                            // style={{ background: '#94c100', ':hover': { background: '#94c100' } }}
                            > View Order Details</Button>
                        </Link>

                    </Grid> */}
                    <Grid item xs={12} md={2} sm={3} lg={2}>
                        <Link to="/shopping">
                            <Button variant="outlined" fullWidth> Continue Shopping</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            {/* </Grid> */}

        </div>
    )
}
