import { Button, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { page_not_found_img, page_not_found_img1 } from '../assets/images'
import { Home } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <Card container sx={{ width: '100%' }}>
            <Grid container justifyContent={'center'}>
                <img src={page_not_found_img1} width={window.innerWidth > 450 ? '40%' : '100%'} />
            </Grid>
            <Grid container justifyContent={'center'} >
                <Typography variant='h2'
                    sx={{
                        maxWidth: window.innerWidth > 450 ? '50%' : '90%',
                        textAlign: 'center', color: '#002a5c',
                        marginTop: -8,
                        fontSize: window.innerWidth > 450 ? 25 : 15
                    }}>
                    Oops 🙁 ! The page you are looking for doesn't exist
                </Typography>
            </Grid>
            <Grid container justifyContent={'center'} pb={10} mt={1}>
                <Link to={'/'}>
                    <Button variant='outlined' sx={{ backgroundColor: '#002a5c', color: 'white', borderRadius: 6 }} >
                        <Home style={{ paddingBottom: 3 }} />
                        Back to Home
                    </Button>
                </Link>
            </Grid>
        </Card>
    )
}
