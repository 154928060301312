
const initialState = {
    status: 'logged in',
    appointmentData: {},
    userData: {}

};

const userInfoReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_USER_DATA':
            return {
                ...state,
                userData: action.payload
            };
        case 'CLEAR_USER_DATA':
            return {
                status: "logged Out"
            };
        case 'SET_APPOINTMENT_DATA':
            return {
                ...state,
                appointmentData: action.payload
            };
        case 'INCREASE_SERVICE_QUANTITY':
            let updatedServicesafterInc = state?.appointmentData?.services?.map((item) => (item.serviceId === action.payload.serviceId) ? { ...item, quantity: item.quantity + 1 } : item)
            return {
                ...state,
                appointmentData: {
                    ...state.appointmentData,
                    services: updatedServicesafterInc
                }
            };
        case 'DESCREASE_SERVICE_QUANTITY':
            let updatedServicesafterDes;
            if (action.payload.quantity > 1) {
                updatedServicesafterDes = state?.appointmentData?.services?.map((item) => (item.serviceId === action.payload.serviceId) ? { ...item, quantity: item.quantity - 1 } : item)
            } else {
                updatedServicesafterDes = state?.appointmentData?.services?.filter((item) => item.serviceId !== action.payload.serviceId)
            }
            return {
                ...state,
                appointmentData: {
                    ...state.appointmentData,
                    services: updatedServicesafterDes
                }
            };
        case 'REFRESH_SALON_DATA':
            return {
                ...state,
                ...state.userData.salonDetails[0] = action.payload
            };
        case 'SAVE_SALON_UNIQUE_ID':
            return {
                ...state,
                salonUniqueId: action.payload
            };

        default:
            return state;
    }
};


export default userInfoReducer;