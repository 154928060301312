export const routeList = {
  "/employeeHome": "employeehome",
  "/serviceHome": "Services",
  "/appointments": "Schedule Appointment",
  "/dashboard": "Dashboard",
  "/customerHome": "Customers",
  "/sms-promotions": "SMS Promotions",
  "/whatsapp-promotions": "Whatsapp-Promotions",
  "/sales-report": "Sales Report",
  "/dev-area/allsalons": "Dev Area",
  "/products": "Products",
  "/shopping": "Shopping",
  "/my-cart": "Cart",
  "/print-receipt": "Print Receipt",
};
export const routeTitles = {
  "/employeeHome": "Employees List",
  "/serviceHome": "Services",
  "/appointments": "Book New Appointment",
  "/dashboard": "Dashboard",
  "/customerHome": "Customers",
  "/onboarding":
    "Buyume | Best Salon Management Software | Best Online Salon Management System",
  "/register": "Create New Account",
  "/login": "Buyume Login ",
  "/forgot-password": "Forgot Password",
  "/AppointmentsList": "Appointment List",
  "/customerList": "Clients",
  "/salon-profile": "Salon Profile",
  "/sms-promotions": "Whatsapp Promotions",
  "/sales-report": "Sales Report",
  "/dev-area/allsalons": "Salons List",
  "/products": "Products",
  "/my-cart": "Cart",
  "/shopping": "Shopping",
  "/my-cart": "Cart",
  "/order-successful": "Order Successful",
  "/whatsapp-chat": "Whatsapp Chats",
  "/print-receipt": "Print Receipt",
  "/all-subscriptions": "All Subscriptions",
  "/subscriptions-users": "Manage Users",
};
