// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconChartArcs, IconListCheck, IconUsers,
    IconClipboardList, IconListNumbers, IconChartInfographic, IconDashboard,
    IconHome2, IconCalendarTime, IconUserCircle, IconClockHour10, IconBox, IconBrandWhatsapp
} from '@tabler/icons';
import Groups2Icon from '@mui/icons-material/Groups2';
// import PeopleAltIcon from '@mui/icons-material/PeopleAltIcon';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Campaign, Assessment, ShoppingBasket } from '@mui/icons-material';
import { product_image } from '../../../../assets/images';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconUserCircle: IconUserCircle,
    IconCalendarTime: IconCalendarTime,
    IconListNumbers: IconListNumbers,
    IconClockHour10: IconClockHour10,
    PeopleAltIcon: PeopleAltIcon,
    Groups2Icon: Groups2Icon,
    CampaignIcon: Campaign,
    Assessment: Assessment

};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const menuList_2 = {
    id: 'Menu',
    title: <FormattedMessage id="Menu" />,
    type: 'group',
    children: [

        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'Schedule Appointment',
            title: <FormattedMessage id="Schedule Appointment" />,
            type: 'item',
            url: '/appointments',
            icon: icons['IconCalendarTime'],
            breadcrumbs: false,

        },
        {
            id: 'View Appointments & Billings"',
            title: <FormattedMessage id="View Appointments & Billings" />,
            type: 'item',
            url: "/AppointmentsList",
            icon: icons['IconListNumbers'],
            breadcrumbs: false,

        },
        {
            id: 'employeehome',
            title: <FormattedMessage id="Employee" />,
            type: 'item',
            url: '/employeeHome',
            icon: PeopleAltIcon,
            breadcrumbs: false,
        },
        {
            id: 'Customers',
            title: <FormattedMessage id="Customers" />,
            type: 'item',
            url: '/customerList',
            // icon: icons['IconUsers'],
            icon: Groups2Icon,
            breadcrumbs: false,

        },
        {
            id: 'Services',
            title: <FormattedMessage id="Services" />,
            type: 'item',
            url: '/serviceHome',
            icon: icons['IconListCheck'],
            breadcrumbs: false,

        },
        {
            id: 'Products',
            title: <FormattedMessage id="Products" />,
            type: 'item',
            url: '/products',
            icon: IconBox,
            breadcrumbs: false,

        },
        {
            id: 'Promotions',
            title: <FormattedMessage id="Promotions" />,
            type: 'item',
            url: '/sms-promotions',
            icon: icons['CampaignIcon'],
            breadcrumbs: false,
        },
        // {
        //     id: 'Whatsapp',
        //     title: <FormattedMessage id="Whatsapp" />,
        //     type: 'item',
        //     url: '/whatsapp-chat',
        //     icon: IconBrandWhatsapp,
        //     breadcrumbs: false,
        // },


    ]
}