import { getAnalytics, logEvent } from "firebase/analytics";
import config from './config';
import { initializeApp } from "firebase/app";

// Initialize Firebase
const app = initializeApp(config.firebase);

const analytics = getAnalytics(app);


const logFirebaseEvent = (tag) => {
    // console.log(tag, "ga event triggered", analytics)
    logEvent(analytics, tag)
}
export { logFirebaseEvent };