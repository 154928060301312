
const initialState = {
    products: [],
    rewardProducts: []

};

const userCartReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'UPDATE_CART_DATA':
            return {
                ...state,
                products: action.payload,
            };
        case 'UPDATE_REWARD_PRODUCTS':
            return {
                ...state,
                rewardProducts: action.payload
            };
        case 'ADD_PRODUCT_TO_CART':
            return {
                ...state,
                products: [...state.products, action.payload]
            };
        case 'ADD_REWARD_PRODUCT':
            return {
                ...state,
                rewardProducts: [...state.rewardProducts, action.payload]
            };
        case 'REMOVE_REWARD_PRODUCT':
            return {
                ...state,
                rewardProducts: [...state.rewardProducts, action.payload]
            };
        case 'REMOVE_PRODUCT_FROM_CART':
            let prd = state?.products?.filter((item) => item.productId !== action.payload.productId)
            return {
                ...state,
                products: prd
            };
        case 'MAKE_CART_EMPTY':
            return {
                products: [],
                rewardProducts: []
            };
        case 'INCREASE_PRODUCT_QUANTITY':
            let updatesAfterInc = state?.products?.map((item) => (item.productId === action.payload.productId) ? { ...item, quantity: item.quantity + 1 } : item)
            return {
                ...state,
                products: updatesAfterInc
            };
        case 'SET_PRODUCT_QUANTITY':
            let updatesAfterChange = state?.products?.map((item) => (item.productId === action.payload.productId) ? { ...item, quantity: action.payload.quantity } : item)
            return {
                ...state,
                products: updatesAfterChange
            };
        case 'DESCREASE_PRODUCT_QUANTITY':
            let updatesAfterDes = state?.products?.map((item) => (item.productId === action.payload.productId) ? { ...item, quantity: item.quantity - 1 } : item)
            return {
                ...state,
                products: updatesAfterDes
            };

        default:
            return state;
    }
};


export default userCartReducer;