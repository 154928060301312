// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const services = {
    id: 'services',
    title: <FormattedMessage id="services" />,
    type: 'group',
    children: [
        {
            id: 'Services',
            title: <FormattedMessage id="Services" />,
            type: 'item',
            url: '/serviceHome',
            icon: icons['IconListCheck'],
            breadcrumbs: false,

        },

    ]
}