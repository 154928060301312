const fontSizestyles = {
    container: {
        height: 'auto',
        width: (window.innerWidth > 400) ? 190 : 120,
        boxShadow: '0px 2px 6px lightgray',
        flexGrow: 1,
        borderRadius: "8px",
        padding: '0px',
        maxWidth: 250,
        flexShrink: 1,
        overflow: 'hidden'

    },
    img_container: {
        height: window.innerWidth > 600 ? 110 : 80,
        width: 110,
        marginTop: "1vw",
        overflow: 'hidden',
        padding: "10px"
    },
    fontSize: {
        fontSize: window.innerWidth > 600 ? 16 : 12,
        textAlign: 'center',
        padding: "10px",
        height: 50,
        paddingTop: 5,
        paddingBottom: 5,
        overflow: "hidden",
    },
    fontSize1: {
        fontSize: window.innerWidth > 600 ? 18 : 17,
        fontWeight: 'bold',
        padding: '5px',
        paddingLeft: "20px",
        letterSpacing: 1,
        lineHeight: 0.8,
        color: 'black'

    },
    imgSt: {
        objectFit: 'contain',
        height: window.innerWidth > 600 ? 100 : 80,
        width: 110,

    },
    fontSizeMrp: {
        lineHeight: 0.8,
        fontSize: window.innerWidth > 600 ? 14 : 12,
        paddingLeft: "20px",
    },
    btn1: { borderColor: '#94c100', ':hover': { borderColor: '#94c100' }, color: '#94c100' },
    btn2: { backgroundColor: '#94c100', borderColor: '#94c100', ':hover': { background: '#94c100' }, color: 'white', fontWeight: 'bold' },

    nameFontSize: {
        fontSize: window.innerWidth > 600 ? 18 : 20,
        fontFamily: 'Lato-Regular',
        fontWeight: 600,
        color: 'black',
        letterSpacing: 1
    }


}


const fontSizestyles2 = {
    container: {
        height: 'auto',
        // width: window.innerWidth > 600 ? 190 : 110,
        boxShadow: '0px 2px 6px lightgray',
        flexGrow: 1,
        borderRadius: "12px",
        padding: '0px',

    },
    img_container: {
        height: window.innerWidth > 600 ? 140 : 100,
        width: '100%',
        // marginTop: "1vw",
        overflow: 'hidden',
        padding: "10px",
    },
    fontSize: {
        fontSize: window.innerWidth > 600 ? 16 : 13,
        color: 'black'

    },
    fontSize3: {
        fontSize: window.innerWidth > 600 ? 12 : 10,
    },
    fontSize1: {
        fontSize: window.innerWidth > 600 ? 18 : 12,
        fontWeight: 'bold',
        padding: '5px',
        paddingLeft: "5px",
        letterSpacing: 1,
        lineHeight: 0.8,

    },
    imgSt: {
        objectFit: 'contain',
        height: window.innerWidth > 600 ? 100 : 80,
        width: 110,

    },
    fontSizeMrp: {
        lineHeight: 0.8,
        fontSize: window.innerWidth > 600 ? 12 : 10,

    },
    fontSizeMrp1: {
        fontSize: window.innerWidth > 600 ? 12 : 10,
    },
    btn1: { borderColor: '#94c100', ':hover': { borderColor: '#94c100' }, color: '#94c100' },
    btn2: { backgroundColor: '#94c100', borderColor: '#94c100', ':hover': { background: '#94c100' }, color: 'white', fontWeight: 'bold' }
}
export { fontSizestyles, fontSizestyles2 }