import { Button, Grid, Typography } from "@material-ui/core";
import { IconCameraOff, IconCapture } from "@tabler/icons";
import React from "react";
import { useRef } from "react";
// import { useEffect } from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import { face_overlay } from "../assets/images";
import './webcam.css'
import { IconButton } from "@mui/material";
import { CameraAlt, Cameraswitch, SwitchCamera } from "@material-ui/icons";
import { Camera } from "@mui/icons-material";


export default function WebcamCapture(props) {
    const { handleCaptureImage, goTohome } = props;
    const webcam = useRef()
    const [cameraError, setCameraError] = useState(false)
    const [frontCamera, setfrontCamera] = useState(true)
    const [camOff, setCamOff] = useState(false)


    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER)


    const capture = () => {
        const imageSrc = webcam.current.getScreenshot();
        // console.log('webcam.current', webcam.current)
        handleCaptureImage(imageSrc)
    };
    const videoConstraints = {
        width: window.innerWidth > 600 ? 500 : 1280,
        height: window.innerWidth > 600 ? 1080 : 950,
        facingMode: FACING_MODE_USER
    };
    const handleCameraSwitch = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);
    // {
    //     setfrontCamera(!frontCamera)
    // }


    return (
        <div style={{ height: '100vh', }}>
            {cameraError ? <Grid container sx={{ width: '100%' }} justifyContent="center" alignItems={'center'}>
                <Grid textAlign={'center'} p={4}>
                    <IconCameraOff size={60} />
                    <Typography color={'ActiveBorder'}>Sorry ! we are not able to access your camera.</Typography>
                    <Typography color={'red'}>Resons :</Typography>
                    <Typography color={'red'}>1. May be you didn't allow permissions .</Typography>
                    <Typography color={'red'}>2. Your device does not have any webcam.</Typography>

                    <Typography color={'green'} mt={2}>Solutions :</Typography>
                    <Typography color={'green'}>1. Allow camera permissions.</Typography>
                    <Typography color={'green'}>2. Connect webcam to your device.</Typography>
                    <Typography color={'green'}>3. Try with another device.</Typography>

                    <Typography mt={3}>You can upload image from gallery / file manager  <br />on previous screen. </Typography>

                    <Button variant="contained" sx={{ bgcolor: '#002a5c', marginTop: '20px' }} onClick={goTohome} size="small">Go Back</Button>
                </Grid>
            </Grid>
                :
                <><Grid container
                    sx={{
                        width: "100%",
                        // height: "100%",
                        overflow: 'hidden',
                        boxShadow: '0px 2px 6px lightgray',
                        position: 'relative',
                        // minHeight: "550px",
                        height: '70vh',
                        padding: 0,
                        backgroundColor: 'black'
                    }} >

                    <Grid container style={{ position: 'absolute', top: "10%", zIndex: 120 }} justifyContent="center" alignItems={'center'}>
                        <img src={face_overlay} alt="face_overlay" width={"90%"} style={{ opacity: 0.4 }} />
                    </Grid>

                    <Webcam
                        audio={false}
                        height={'100%'}
                        ref={webcam}

                        screenshotFormat="image/jpeg"
                        width={window.innerWidth}
                        screenshotQuality={1}
                        mirrored={frontCamera ? true : false}
                        minScreenshotHeight={1920}
                        minScreenshotWidth={1080}
                        forceScreenshotSourceSize='true'
                        onUserMediaError={() => setCameraError(true)}
                        videoConstraints={{
                            ...videoConstraints,
                            facingMode
                        }}
                        style={{
                            // border: '2px solid red',
                            objectFit: 'cover',
                            backgroundColor: 'white'
                        }}
                    />
                </Grid>

                    <Grid container p={2} >
                        <Grid item xs={8} lg={8} md={8} sm={8}
                            container justifyContent={'flex-end'} p={3} alignItems="center" gap={2}>

                            <IconButton
                                // variant='contained'

                                sx={{
                                    backgroundColor: '#94c100', color: 'white', ':focus': {
                                        backgroundColor: '#94c100',
                                    }
                                }}

                                onClick={capture}>
                                {/* Capture  */}
                                <CameraAlt style={{ height: 50, width: 50, padding: 5 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4} sm={4} container alignItems={'center'} >
                            <IconButton onClick={handleCameraSwitch} className={frontCamera ? "animate__animated animate__flipInX" : "animate__animated animate__flip"}>
                                <Cameraswitch />
                            </IconButton>
                        </Grid>
                        {/* width:{window.innerWidth}<br />
                    height:{window.innerHeight} */}
                    </Grid>
                </>}
        </div>
    );

}
