
let colorsArray1 = {
    AA: "#63b59890",
    AB: "#ce7d7890",
    AC: "#ea9e7090",
    AD: "#a48a9e90",
    AE: "#c6e1e890",
    AF: "#64817790",
    AG: "#0d5ac190",
    AH: "#f205e690",
    AI: "#1c036590",
    AJ: "#14a9ad90",
    AK: "#4ca2f990",
    AL: "#a4e43f90",
    AM: "#d298e290",
    AN: "#6119d090",
    AO: "#d2737d90",
    AP: "#c0a43c90",
    AQ: "#f2510e90",
    AR: "#651be690",
    AS: "#79806e90",
    AT: "#61da5e90",
    AU: "#cd2f0090",
    AV: "#9348af90",
    AW: "#01ac5390",
    AX: "#c5a4fb90",
    AY: "#99663590",
    AZ: "#b1157390",
    BA: "#63b59890",
    BB: "#ce7d7890",
    BC: "#ea9e7090",
    BD: "#a48a9e90",
    BE: "#c6e1e890",
    BF: "#64817790",
    BG: "#0d5ac190",
    BH: "#f205e690",
    BI: "#1c036590",
    BJ: "#14a9ad90",
    BK: "#4ca2f990",
    BL: "#a4e43f90",
    BM: "#d298e290",
    BN: "#6119d090",
    BO: "#d2737d90",
    BP: "#c0a43c90",
    BQ: "#f2510e90",
    BR: "#651be690",
    BS: "#79806e90",
    BT: "#61da5e90",
    BU: "#cd2f0090",
    BV: "#9348af90",
    BW: "#01ac5390",
    BX: "#c5a4fb90",
    BY: "#99663590",
    BZ: "#b1157390",
    CA: "#63b59890",
    CB: "#ce7d7890",
    CC: "#ea9e7090",
    CD: "#a48a9e90",
    CE: "#c6e1e890",
    CF: "#64817790",
    CG: "#0d5ac190",
    CH: "#f205e690",
    CI: "#1c036590",
    CJ: "#14a9ad90",
    CK: "#4ca2f990",
    CL: "#a4e43f90",
    CM: "#d298e290",
    CN: "#6119d090",
    CO: "#d2737d90",
    CP: "#c0a43c90",
    CQ: "#f2510e90",
    CR: "#651be690",
    CS: "#79806e90",
    CT: "#61da5e90",
    CU: "#cd2f0090",
    CV: "#9348af90",
    CW: "#01ac5390",
    CX: "#c5a4fb90",
    CY: "#99663590",
    CZ: "#b1157390",
    DA: "#63b59890",
    DB: "#ce7d7890",
    DC: "#ea9e7090",
    DD: "#a48a9e90",
    DE: "#c6e1e890",
    DF: "#64817790",
    DG: "#0d5ac190",
    DH: "#f205e690",
    DI: "#1c036590",
    DJ: "#14a9ad90",
    DK: "#4ca2f990",
    DL: "#a4e43f90",
    DM: "#d298e290",
    DN: "#6119d090",
    DO: "#d2737d90",
    DP: "#c0a43c90",
    DQ: "#f2510e90",
    DR: "#651be690",
    DS: "#79806e90",
    DT: "#61da5e90",
    DU: "#cd2f0090",
    DV: "#9348af90",
    DW: "#01ac5390",
    DX: "#c5a4fb90",
    DY: "#99663590",
    DZ: "#b1157390",
    EA: "#63b59890",
    EB: "#ce7d7890",
    EC: "#ea9e7090",
    ED: "#a48a9e90",
    EE: "#c6e1e890",
    EF: "#64817790",
    EG: "#0d5ac190",
    EH: "#f205e690",
    EI: "#1c036590",
    EJ: "#14a9ad90",
    EK: "#4ca2f990",
    EL: "#a4e43f90",
    EM: "#d298e290",
    EN: "#6119d090",
    EO: "#d2737d90",
    EP: "#c0a43c90",
    EQ: "#f2510e90",
    ER: "#651be690",
    ES: "#79806e90",
    ET: "#61da5e90",
    EU: "#cd2f0090",
    EV: "#9348af90",
    EW: "#01ac5390",
    EX: "#c5a4fb90",
    EY: "#99663590",
    EZ: "#b1157390",
    FA: "#63b59890",
    FB: "#ce7d7890",
    FC: "#ea9e7090",
    FD: "#a48a9e90",
    FE: "#c6e1e890",
    FF: "#64817790",
    FG: "#0d5ac190",
    FH: "#f205e690",
    FI: "#1c036590",
    FJ: "#14a9ad90",
    FK: "#4ca2f990",
    FL: "#a4e43f90",
    FM: "#d298e290",
    FN: "#6119d090",
    FO: "#d2737d90",
    FP: "#c0a43c90",
    FQ: "#f2510e90",
    FR: "#651be690",
    FS: "#79806e90",
    FT: "#61da5e90",
    FU: "#cd2f0090",
    FV: "#9348af90",
    FW: "#01ac5390",
    FX: "#c5a4fb90",
    FY: "#99663590",
    FZ: "#b1157390",
    GA: "#63b59890",
    GB: "#ce7d7890",
    GC: "#ea9e7090",
    GD: "#a48a9e90",
    GE: "#c6e1e890",
    GF: "#64817790",
    GG: "#0d5ac190",
    GH: "#f205e690",
    GI: "#1c036590",
    GJ: "#14a9ad90",
    GK: "#4ca2f990",
    GL: "#a4e43f90",
    GM: "#d298e290",
    GN: "#6119d090",
    GO: "#d2737d90",
    GP: "#c0a43c90",
    GQ: "#f2510e90",
    GR: "#651be690",
    GS: "#79806e90",
    GT: "#61da5e90",
    GU: "#cd2f0090",
    GV: "#9348af90",
    GW: "#01ac5390",
    GX: "#c5a4fb90",
    GY: "#99663590",
    GZ: "#b1157390",
    HA: "#63b598",
    HB: "#ce7d7890",
    HC: "#ea9e7090",
    HD: "#a48a9e90",
    HE: "#c6e1e890",
    HF: "#64817790",
    HG: "#0d5ac190",
    HH: "#f205e690",
    HI: "#1c036590",
    HJ: "#14a9ad90",
    HK: "#4ca2f990",
    HL: "#a4e43f90",
    HM: "#d298e290",
    HN: "#6119d090",
    HO: "#d2737d90",
    HP: "#c0a43c90",
    HQ: "#f2510e90",
    HR: "#651be690",
    HS: "#79806e90",
    HT: "#61da5e90",
    HU: "#cd2f0090",
    HV: "#9348af90",
    HW: "#01ac5390",
    HX: "#c5a4fb90",
    HY: "#99663590",
    HZ: "#b1157390",
    IA: "#63b59890",
    IB: "#ce7d7890",
    IC: "#ea9e7090",
    ID: "#a48a9e90",
    IE: "#c6e1e890",
    IF: "#64817790",
    IG: "#0d5ac190",
    IH: "#f205e690",
    II: "#1c036590",
    IJ: "#14a9ad90",
    IK: "#4ca2f990",
    IL: "#a4e43f90",
    IM: "#d298e290",
    IN: "#6119d090",
    IO: "#d2737d90",
    IP: "#c0a43c90",
    IQ: "#f2510e90",
    IR: "#651be690",
    IS: "#79806e90",
    IT: "#61da5e90",
    IU: "#cd2f0090",
    IV: "#9348af90",
    IW: "#01ac5390",
    IX: "#c5a4fb90",
    IY: "#99663590",
    IZ: "#b1157390",
    JA: "#63b59890",
    JB: "#ce7d7890",
    JC: "#ea9e7090",
    JD: "#a48a9e90",
    JE: "#c6e1e890",
    JF: "#64817790",
    JG: "#0d5ac190",
    JH: "#f205e690",
    JI: "#1c036590",
    JJ: "#14a9ad90",
    JK: "#4ca2f990",
    JL: "#a4e43f90",
    JM: "#d298e290",
    JN: "#6119d090",
    JO: "#d2737d90",
    JP: "#c0a43c90",
    JQ: "#f2510e90",
    JR: "#651be690",
    JS: "#79806e90",
    JT: "#61da5e90",
    JU: "#cd2f0090",
    JV: "#9348af90",
    JW: "#01ac5390",
    JX: "#c5a4fb90",
    JY: "#99663590",
    JZ: "#b1157390",
    KA: "#63b59890",
    KB: "#ce7d7890",
    KC: "#ea9e7090",
    KD: "#a48a9e90",
    KE: "#c6e1e890",
    KF: "#64817790",
    KG: "#0d5ac190",
    KH: "#f205e690",
    KI: "#1c036590",
    KJ: "#14a9ad90",
    KK: "#4ca2f990",
    KL: "#a4e43f90",
    KM: "#d298e290",
    KN: "#6119d090",
    KO: "#d2737d90",
    KP: "#c0a43c90",
    KQ: "#f2510e90",
    KR: "#651be690",
    KS: "#79806e90",
    KT: "#61da5e90",
    KU: "#cd2f0090",
    KV: "#9348af90",
    KW: "#01ac5390",
    KX: "#c5a4fb90",
    KY: "#99663590",
    KZ: "#b1157390",
    LA: "#63b59890",
    LB: "#ce7d7890",
    LC: "#ea9e7090",
    LD: "#a48a9e90",
    LE: "#c6e1e890",
    LF: "#64817790",
    LG: "#0d5ac190",
    LH: "#f205e690",
    LI: "#1c036590",
    LJ: "#14a9ad90",
    LK: "#4ca2f990",
    LL: "#a4e43f90",
    LM: "#d298e290",
    LN: "#6119d090",
    LO: "#d2737d90",
    LP: "#c0a43c90",
    LQ: "#f2510e90",
    LR: "#651be690",
    LS: "#79806e90",
    LT: "#61da5e90",
    LU: "#cd2f0090",
    LV: "#9348af90",
    LW: "#01ac5390",
    LX: "#c5a4fb90",
    LY: "#99663590",
    LZ: "#b1157390",
    MA: "#63b59890",
    MB: "#ce7d7890",
    MC: "#ea9e7090",
    MD: "#a48a9e90",
    ME: "#c6e1e890",
    MF: "#64817790",
    MG: "#0d5ac190",
    MH: "#f205e690",
    MI: "#1c036590",
    MJ: "#14a9ad90",
    MK: "#4ca2f990",
    ML: "#a4e43f90",
    MM: "#d298e290",
    MN: "#6119d090",
    MO: "#d2737d90",
    MP: "#c0a43c90",
    MQ: "#f2510e90",
    MR: "#651be690",
    MS: "#79806e90",
    MT: "#61da5e90",
    MU: "#cd2f0090",
    MV: "#9348af90",
    MW: "#01ac5390",
    MX: "#c5a4fb90",
    MY: "#99663590",
    MZ: "#b1157390",
    NA: "#63b59890",
    NB: "#ce7d7890",
    NC: "#ea9e7090",
    ND: "#a48a9e90",
    NE: "#c6e1e890",
    NF: "#64817790",
    NG: "#0d5ac190",
    NH: "#f205e690",
    NI: "#1c036590",
    NJ: "#14a9ad90",
    NK: "#4ca2f990",
    NL: "#a4e43f90",
    NM: "#d298e290",
    NN: "#6119d090",
    NO: "#d2737d90",
    NP: "#c0a43c90",
    NQ: "#f2510e90",
    NR: "#651be690",
    NS: "#79806e90",
    NT: "#61da5e90",
    NU: "#cd2f0090",
    NV: "#9348af90",
    NW: "#01ac5390",
    NX: "#c5a4fb90",
    NY: "#99663590",
    NZ: "#b1157390",
    OA: "#63b59890",
    OB: "#ce7d7890",
    OC: "#ea9e7090",
    OD: "#a48a9e90",
    OE: "#c6e1e890",
    OF: "#64817790",
    OG: "#0d5ac190",
    OH: "#f205e690",
    OI: "#1c036590",
    OJ: "#14a9ad90",
    OK: "#4ca2f990",
    OL: "#a4e43f90",
    OM: "#d298e290",
    ON: "#6119d090",
    OO: "#d2737d90",
    OP: "#c0a43c90",
    OQ: "#f2510e90",
    OR: "#651be690",
    OS: "#79806e90",
    OT: "#61da5e90",
    OU: "#cd2f0090",
    OV: "#9348af90",
    OW: "#01ac5390",
    OX: "#c5a4fb90",
    OY: "#99663590",
    OZ: "#b1157390",
    PA: "#63b59890",
    PB: "#ce7d7890",
    PC: "#ea9e7090",
    PD: "#a48a9e90",
    PE: "#c6e1e890",
    PF: "#64817790",
    PG: "#0d5ac190",
    PH: "#f205e690",
    PI: "#1c036590",
    PJ: "#14a9ad90",
    PK: "#4ca2f990",
    PL: "#a4e43f90",
    PM: "#d298e290",
    PN: "#6119d090",
    PO: "#d2737d90",
    PP: "#c0a43c90",
    PQ: "#f2510e90",
    PR: "#651be690",
    PS: "#79806e90",
    PT: "#61da5e90",
    PU: "#cd2f0090",
    PV: "#9348af90",
    PW: "#01ac5390",
    PX: "#c5a4fb90",
    PY: "#99663590",
    PZ: "#b1157390",
    QA: "#63b59890",
    QB: "#ce7d7890",
    QC: "#ea9e7090",
    QD: "#a48a9e90",
    QE: "#c6e1e890",
    QF: "#64817790",
    QG: "#0d5ac190",
    QH: "#f205e690",
    QI: "#1c036590",
    QJ: "#14a9ad90",
    QK: "#4ca2f990",
    QL: "#a4e43f90",
    QM: "#d298e290",
    QN: "#6119d090",
    QO: "#d2737d90",
    QP: "#c0a43c90",
    QQ: "#f2510e90",
    QR: "#651be690",
    QS: "#79806e90",
    QT: "#61da5e90",
    QU: "#cd2f0090",
    QV: "#9348af90",
    QW: "#01ac5390",
    QX: "#c5a4fb90",
    QY: "#99663590",
    QZ: "#b1157390",
    RA: "#63b59890",
    RB: "#ce7d7890",
    RC: "#ea9e7090",
    RD: "#a48a9e90",
    RE: "#c6e1e890",
    RF: "#64817790",
    RG: "#0d5ac190",
    RH: "#f205e690",
    RI: "#1c036590",
    RJ: "#14a9ad90",
    RK: "#4ca2f990",
    RL: "#a4e43f90",
    RM: "#d298e290",
    RN: "#6119d090",
    RO: "#d2737d90",
    RP: "#c0a43c90",
    RQ: "#f2510e90",
    RR: "#651be690",
    RS: "#79806e90",
    RT: "#61da5e90",
    RU: "#cd2f0090",
    RV: "#9348af90",
    RW: "#01ac5390",
    RX: "#c5a4fb90",
    RY: "#99663590",
    RZ: "#b1157390",
    SA: "#63b59890",
    SB: "#ce7d7890",
    SC: "#ea9e7090",
    SD: "#a48a9e90",
    SE: "#c6e1e890",
    SF: "#64817790",
    SG: "#0d5ac190",
    SH: "#f205e690",
    SI: "#1c036590",
    SJ: "#14a9ad90",
    SK: "#4ca2f990",
    SL: "#a4e43f90",
    SM: "#d298e290",
    SN: "#6119d090",
    SO: "#d2737d90",
    SP: "#c0a43c90",
    SQ: "#f2510e90",
    SR: "#651be690",
    SS: "#79806e90",
    ST: "#61da5e90",
    SU: "#cd2f0090",
    SV: "#9348af90",
    SW: "#01ac5390",
    SX: "#c5a4fb90",
    SY: "#99663590",
    SZ: "#b1157390",
    TA: "#63b59890",
    TB: "#ce7d7890",
    TC: "#ea9e7090",
    TD: "#a48a9e90",
    TE: "#c6e1e890",
    TF: "#64817790",
    TG: "#0d5ac190",
    TH: "#f205e690",
    TI: "#1c036590",
    TJ: "#14a9ad90",
    TK: "#4ca2f990",
    TL: "#a4e43f90",
    TM: "#d298e290",
    TN: "#6119d090",
    TO: "#d2737d90",
    TP: "#c0a43c90",
    TQ: "#f2510e90",
    TR: "#651be690",
    TS: "#79806e90",
    TT: "#61da5e90",
    TU: "#cd2f0090",
    TV: "#9348af90",
    TW: "#01ac5390",
    TX: "#c5a4fb90",
    TY: "#99663590",
    TZ: "#b1157390",
    UA: "#63b59890",
    UB: "#ce7d7890",
    UC: "#ea9e7090",
    UD: "#a48a9e90",
    UE: "#c6e1e890",
    UF: "#64817790",
    UG: "#0d5ac190",
    UH: "#f205e690",
    UI: "#1c036590",
    UJ: "#14a9ad90",
    UK: "#4ca2f990",
    UL: "#a4e43f90",
    UM: "#d298e290",
    UN: "#6119d090",
    UO: "#d2737d90",
    UP: "#c0a43c90",
    UQ: "#f2510e90",
    UR: "#651be690",
    US: "#79806e90",
    UT: "#61da5e90",
    UU: "#cd2f0090",
    UV: "#9348af90",
    UW: "#01ac5390",
    UX: "#c5a4fb90",
    UY: "#99663590",
    UZ: "#b1157390",
    VA: "#63b59890",
    VB: "#ce7d7890",
    VC: "#ea9e7090",
    VD: "#a48a9e90",
    VE: "#c6e1e890",
    VF: "#64817790",
    VG: "#0d5ac190",
    VH: "#f205e690",
    VI: "#1c036590",
    VJ: "#14a9ad90",
    VK: "#4ca2f990",
    VL: "#a4e43f90",
    VM: "#d298e290",
    VN: "#6119d090",
    VO: "#d2737d90",
    VP: "#c0a43c90",
    VQ: "#f2510e90",
    VR: "#651be690",
    VS: "#79806e90",
    VT: "#61da5e90",
    VU: "#cd2f0090",
    VV: "#9348af90",
    VW: "#01ac5390",
    VX: "#c5a4fb90",
    VY: "#99663590",
    VZ: "#b1157390",
    WA: "#63b59890",
    WB: "#ce7d7890",
    WC: "#ea9e7090",
    WD: "#a48a9e90",
    WE: "#c6e1e890",
    WF: "#64817790",
    WG: "#0d5ac190",
    WH: "#f205e690",
    WI: "#1c036590",
    WJ: "#14a9ad90",
    WK: "#4ca2f990",
    WL: "#a4e43f90",
    WM: "#d298e290",
    WN: "#6119d090",
    WO: "#d2737d90",
    WP: "#c0a43c90",
    WQ: "#f2510e90",
    WR: "#651be690",
    WS: "#79806e90",
    WT: "#61da5e90",
    WU: "#cd2f0090",
    WV: "#9348af90",
    WW: "#01ac5390",
    WX: "#c5a4fb90",
    WY: "#99663590",
    WZ: "#b1157390",
    XA: "#63b59890",
    XB: "#ce7d7890",
    XC: "#ea9e7090",
    XD: "#a48a9e90",
    XE: "#c6e1e890",
    XF: "#64817790",
    XG: "#0d5ac190",
    XH: "#f205e690",
    XI: "#1c036590",
    XJ: "#14a9ad90",
    XK: "#4ca2f990",
    XL: "#a4e43f90",
    XM: "#d298e290",
    XN: "#6119d090",
    XO: "#d2737d90",
    XP: "#c0a43c90",
    XQ: "#f2510e90",
    XR: "#651be690",
    XS: "#79806e90",
    XT: "#61da5e90",
    XU: "#cd2f0090",
    XV: "#9348af90",
    XW: "#01ac5390",
    XX: "#c5a4fb90",
    XY: "#99663590",
    XZ: "#b1157390",
    YA: "#63b59890",
    YB: "#ce7d7890",
    YC: "#ea9e7090",
    YD: "#a48a9e90",
    YE: "#c6e1e890",
    YF: "#64817790",
    YG: "#0d5ac190",
    YH: "#f205e690",
    YI: "#1c036590",
    YJ: "#14a9ad90",
    YK: "#4ca2f990",
    YL: "#a4e43f90",
    YM: "#d298e290",
    YN: "#6119d090",
    YO: "#d2737d90",
    YP: "#c0a43c90",
    YQ: "#f2510e90",
    YR: "#651be690",
    YS: "#79806e90",
    YT: "#61da5e90",
    YU: "#cd2f0090",
    YV: "#9348af90",
    YW: "#01ac5390",
    YX: "#c5a4fb90",
    YY: "#99663590",
    YZ: "#b1157390",
    ZA: "#63b59890",
    ZB: "#ce7d7890",
    ZC: "#ea9e7090",
    ZD: "#a48a9e90",
    ZE: "#c6e1e890",
    ZF: "#64817790",
    ZG: "#0d5ac190",
    ZH: "#f205e690",
    ZI: "#1c036590",
    ZJ: "#14a9ad90",
    ZK: "#4ca2f990",
    ZL: "#a4e43f90",
    ZM: "#d298e290",
    ZN: "#6119d090",
    ZO: "#d2737d90",
    ZP: "#c0a43c90",
    ZQ: "#f2510e90",
    ZR: "#651be690",
    ZS: "#79806e90",
    ZT: "#61da5e90",
    ZU: "#cd2f0090",
    ZV: "#9348af90",
    ZW: "#01ac5390",
    ZX: "#c5a4fb90",
    ZY: "#99663590",
    ZZ: "#b1157690"
}
// let bgColors = {
//     AA: "#63b59860",
//     AB: "#ce7d7860",
//     AC: "#ea9e7060",
//     AD: "#a48a9e60",
//     AE: "#c6e1e860",
//     AF: "#64817760",
//     AG: "#0d5ac160",
//     AH: "#f205e660",
//     AI: "#1c036560",
//     AJ: "#14a9ad60",
//     AK: "#4ca2f960",
//     AL: "#a4e43f60",
//     AM: "#d298e260",
//     AN: "#6119d060",
//     AO: "#d2737d60",
//     AP: "#c0a43c60",
//     AQ: "#f2510e60",
//     AR: "#651be660",
//     AS: "#79806e60",
//     AT: "#61da5e60",
//     AU: "#cd2f0060",
//     AV: "#9348af60",
//     AW: "#01ac5360",
//     AX: "#c5a4fb60",
//     AY: "#99663560",
//     AZ: "#b1157360",
//     BA: "#63b59860",
//     BB: "#ce7d7860",
//     BC: "#ea9e7060",
//     BD: "#a48a9e60",
//     BE: "#c6e1e860",
//     BF: "#64817760",
//     BG: "#0d5ac160",
//     BH: "#f205e660",
//     BI: "#1c036560",
//     BJ: "#14a9ad60",
//     BK: "#4ca2f960",
//     BL: "#a4e43f60",
//     BM: "#d298e260",
//     BN: "#6119d060",
//     BO: "#d2737d60",
//     BP: "#c0a43c60",
//     BQ: "#f2510e60",
//     BR: "#651be660",
//     BS: "#79806e60",
//     BT: "#61da5e60",
//     BU: "#cd2f0060",
//     BV: "#9348af60",
//     BW: "#01ac5360",
//     BX: "#c5a4fb60",
//     BY: "#99663560",
//     BZ: "#b1157360",
//     CA: "#63b59860",
//     CB: "#ce7d7860",
//     CC: "#ea9e7060",
//     CD: "#a48a9e60",
//     CE: "#c6e1e860",
//     CF: "#64817760",
//     CG: "#0d5ac160",
//     CH: "#f205e660",
//     CI: "#1c036560",
//     CJ: "#14a9ad60",
//     CK: "#4ca2f960",
//     CL: "#a4e43f60",
//     CM: "#d298e260",
//     CN: "#6119d060",
//     CO: "#d2737d60",
//     CP: "#c0a43c60",
//     CQ: "#f2510e60",
//     CR: "#651be660",
//     CS: "#79806e60",
//     CT: "#61da5e60",
//     CU: "#cd2f0060",
//     CV: "#9348af60",
//     CW: "#01ac5360",
//     CX: "#c5a4fb60",
//     CY: "#99663560",
//     CZ: "#b1157360",
//     DA: "#63b59860",
//     DB: "#ce7d7860",
//     DC: "#ea9e7060",
//     DD: "#a48a9e60",
//     DE: "#c6e1e860",
//     DF: "#64817760",
//     DG: "#0d5ac160",
//     DH: "#f205e660",
//     DI: "#1c036560",
//     DJ: "#14a9ad60",
//     DK: "#4ca2f960",
//     DL: "#a4e43f60",
//     DM: "#d298e260",
//     DN: "#6119d060",
//     DO: "#d2737d60",
//     DP: "#c0a43c60",
//     DQ: "#f2510e60",
//     DR: "#651be660",
//     DS: "#79806e60",
//     DT: "#61da5e60",
//     DU: "#cd2f0060",
//     DV: "#9348af60",
//     DW: "#01ac5360",
//     DX: "#c5a4fb60",
//     DY: "#99663560",
//     DZ: "#b1157360",
//     EA: "#63b59860",
//     EB: "#ce7d7860",
//     EC: "#ea9e7060",
//     ED: "#a48a9e60",
//     EE: "#c6e1e860",
//     EF: "#64817760",
//     EG: "#0d5ac160",
//     EH: "#f205e660",
//     EI: "#1c036560",
//     EJ: "#14a9ad60",
//     EK: "#4ca2f960",
//     EL: "#a4e43f60",
//     EM: "#d298e260",
//     EN: "#6119d060",
//     EO: "#d2737d60",
//     EP: "#c0a43c60",
//     EQ: "#f2510e60",
//     ER: "#651be660",
//     ES: "#79806e60",
//     ET: "#61da5e60",
//     EU: "#cd2f0060",
//     EV: "#9348af60",
//     EW: "#01ac5360",
//     EX: "#c5a4fb60",
//     EY: "#99663560",
//     EZ: "#b1157360",
//     FA: "#63b59860",
//     FB: "#ce7d7860",
//     FC: "#ea9e7060",
//     FD: "#a48a9e60",
//     FE: "#c6e1e860",
//     FF: "#64817760",
//     FG: "#0d5ac160",
//     FH: "#f205e660",
//     FI: "#1c036560",
//     FJ: "#14a9ad60",
//     FK: "#4ca2f960",
//     FL: "#a4e43f60",
//     FM: "#d298e260",
//     FN: "#6119d060",
//     FO: "#d2737d60",
//     FP: "#c0a43c60",
//     FQ: "#f2510e60",
//     FR: "#651be660",
//     FS: "#79806e60",
//     FT: "#61da5e60",
//     FU: "#cd2f0060",
//     FV: "#9348af60",
//     FW: "#01ac5360",
//     FX: "#c5a4fb60",
//     FY: "#99663560",
//     FZ: "#b1157360",
//     GA: "#63b59860",
//     GB: "#ce7d7860",
//     GC: "#ea9e7060",
//     GD: "#a48a9e60",
//     GE: "#c6e1e860",
//     GF: "#64817760",
//     GG: "#0d5ac160",
//     GH: "#f205e660",
//     GI: "#1c036560",
//     GJ: "#14a9ad60",
//     GK: "#4ca2f960",
//     GL: "#a4e43f60",
//     GM: "#d298e260",
//     GN: "#6119d060",
//     GO: "#d2737d60",
//     GP: "#c0a43c60",
//     GQ: "#f2510e60",
//     GR: "#651be660",
//     GS: "#79806e60",
//     GT: "#61da5e60",
//     GU: "#cd2f0060",
//     GV: "#9348af60",
//     GW: "#01ac5360",
//     GX: "#c5a4fb60",
//     GY: "#99663560",
//     GZ: "#b1157360",
//     HA: "#63b598",
//     HB: "#ce7d7860",
//     HC: "#ea9e7060",
//     HD: "#a48a9e60",
//     HE: "#c6e1e860",
//     HF: "#64817760",
//     HG: "#0d5ac160",
//     HH: "#f205e660",
//     HI: "#1c036560",
//     HJ: "#14a9ad60",
//     HK: "#4ca2f960",
//     HL: "#a4e43f60",
//     HM: "#d298e260",
//     HN: "#6119d060",
//     HO: "#d2737d60",
//     HP: "#c0a43c60",
//     HQ: "#f2510e60",
//     HR: "#651be660",
//     HS: "#79806e60",
//     HT: "#61da5e60",
//     HU: "#cd2f0060",
//     HV: "#9348af60",
//     HW: "#01ac5360",
//     HX: "#c5a4fb60",
//     HY: "#99663560",
//     HZ: "#b1157360",
//     IA: "#63b59860",
//     IB: "#ce7d7860",
//     IC: "#ea9e7060",
//     ID: "#a48a9e60",
//     IE: "#c6e1e860",
//     IF: "#64817760",
//     IG: "#0d5ac160",
//     IH: "#f205e660",
//     II: "#1c036560",
//     IJ: "#14a9ad60",
//     IK: "#4ca2f960",
//     IL: "#a4e43f60",
//     IM: "#d298e260",
//     IN: "#6119d060",
//     IO: "#d2737d60",
//     IP: "#c0a43c60",
//     IQ: "#f2510e60",
//     IR: "#651be660",
//     IS: "#79806e60",
//     IT: "#61da5e60",
//     IU: "#cd2f0060",
//     IV: "#9348af60",
//     IW: "#01ac5360",
//     IX: "#c5a4fb60",
//     IY: "#99663560",
//     IZ: "#b1157360",
//     JA: "#63b59860",
//     JB: "#ce7d7860",
//     JC: "#ea9e7060",
//     JD: "#a48a9e60",
//     JE: "#c6e1e860",
//     JF: "#64817760",
//     JG: "#0d5ac160",
//     JH: "#f205e660",
//     JI: "#1c036560",
//     JJ: "#14a9ad60",
//     JK: "#4ca2f960",
//     JL: "#a4e43f60",
//     JM: "#d298e260",
//     JN: "#6119d060",
//     JO: "#d2737d60",
//     JP: "#c0a43c60",
//     JQ: "#f2510e60",
//     JR: "#651be660",
//     JS: "#79806e60",
//     JT: "#61da5e60",
//     JU: "#cd2f0060",
//     JV: "#9348af60",
//     JW: "#01ac5360",
//     JX: "#c5a4fb60",
//     JY: "#99663560",
//     JZ: "#b1157360",
//     KA: "#63b59860",
//     KB: "#ce7d7860",
//     KC: "#ea9e7060",
//     KD: "#a48a9e60",
//     KE: "#c6e1e860",
//     KF: "#64817760",
//     KG: "#0d5ac160",
//     KH: "#f205e660",
//     KI: "#1c036560",
//     KJ: "#14a9ad60",
//     KK: "#4ca2f960",
//     KL: "#a4e43f60",
//     KM: "#d298e260",
//     KN: "#6119d060",
//     KO: "#d2737d60",
//     KP: "#c0a43c60",
//     KQ: "#f2510e60",
//     KR: "#651be660",
//     KS: "#79806e60",
//     KT: "#61da5e60",
//     KU: "#cd2f0060",
//     KV: "#9348af60",
//     KW: "#01ac5360",
//     KX: "#c5a4fb60",
//     KY: "#99663560",
//     KZ: "#b1157360",
//     LA: "#63b59860",
//     LB: "#ce7d7860",
//     LC: "#ea9e7060",
//     LD: "#a48a9e60",
//     LE: "#c6e1e860",
//     LF: "#64817760",
//     LG: "#0d5ac160",
//     LH: "#f205e660",
//     LI: "#1c036560",
//     LJ: "#14a9ad60",
//     LK: "#4ca2f960",
//     LL: "#a4e43f60",
//     LM: "#d298e260",
//     LN: "#6119d060",
//     LO: "#d2737d60",
//     LP: "#c0a43c60",
//     LQ: "#f2510e60",
//     LR: "#651be660",
//     LS: "#79806e60",
//     LT: "#61da5e60",
//     LU: "#cd2f0060",
//     LV: "#9348af60",
//     LW: "#01ac5360",
//     LX: "#c5a4fb60",
//     LY: "#99663560",
//     LZ: "#b1157360",
//     MA: "#63b59860",
//     MB: "#ce7d7860",
//     MC: "#ea9e7060",
//     MD: "#a48a9e60",
//     ME: "#c6e1e860",
//     MF: "#64817760",
//     MG: "#0d5ac160",
//     MH: "#f205e660",
//     MI: "#1c036560",
//     MJ: "#14a9ad60",
//     MK: "#4ca2f960",
//     ML: "#a4e43f60",
//     MM: "#d298e260",
//     MN: "#6119d060",
//     MO: "#d2737d60",
//     MP: "#c0a43c60",
//     MQ: "#f2510e60",
//     MR: "#651be660",
//     MS: "#79806e60",
//     MT: "#61da5e60",
//     MU: "#cd2f0060",
//     MV: "#9348af60",
//     MW: "#01ac5360",
//     MX: "#c5a4fb60",
//     MY: "#99663560",
//     MZ: "#b1157360",
//     NA: "#63b59860",
//     NB: "#ce7d7860",
//     NC: "#ea9e7060",
//     ND: "#a48a9e60",
//     NE: "#c6e1e860",
//     NF: "#64817760",
//     NG: "#0d5ac160",
//     NH: "#f205e660",
//     NI: "#1c036560",
//     NJ: "#14a9ad60",
//     NK: "#4ca2f960",
//     NL: "#a4e43f60",
//     NM: "#d298e260",
//     NN: "#6119d060",
//     NO: "#d2737d60",
//     NP: "#c0a43c60",
//     NQ: "#f2510e60",
//     NR: "#651be660",
//     NS: "#79806e60",
//     NT: "#61da5e60",
//     NU: "#cd2f0060",
//     NV: "#9348af60",
//     NW: "#01ac5360",
//     NX: "#c5a4fb60",
//     NY: "#99663560",
//     NZ: "#b1157360",
//     OA: "#63b59860",
//     OB: "#ce7d7860",
//     OC: "#ea9e7060",
//     OD: "#a48a9e60",
//     OE: "#c6e1e860",
//     OF: "#64817760",
//     OG: "#0d5ac160",
//     OH: "#f205e660",
//     OI: "#1c036560",
//     OJ: "#14a9ad60",
//     OK: "#4ca2f960",
//     OL: "#a4e43f60",
//     OM: "#d298e260",
//     ON: "#6119d060",
//     OO: "#d2737d60",
//     OP: "#c0a43c60",
//     OQ: "#f2510e60",
//     OR: "#651be660",
//     OS: "#79806e60",
//     OT: "#61da5e60",
//     OU: "#cd2f0060",
//     OV: "#9348af60",
//     OW: "#01ac5360",
//     OX: "#c5a4fb60",
//     OY: "#99663560",
//     OZ: "#b1157360",
//     PA: "#63b59860",
//     PB: "#ce7d7860",
//     PC: "#ea9e7060",
//     PD: "#a48a9e60",
//     PE: "#c6e1e860",
//     PF: "#64817760",
//     PG: "#0d5ac160",
//     PH: "#f205e660",
//     PI: "#1c036560",
//     PJ: "#14a9ad60",
//     PK: "#4ca2f960",
//     PL: "#a4e43f60",
//     PM: "#d298e260",
//     PN: "#6119d060",
//     PO: "#d2737d60",
//     PP: "#c0a43c60",
//     PQ: "#f2510e60",
//     PR: "#651be660",
//     PS: "#79806e60",
//     PT: "#61da5e60",
//     PU: "#cd2f0060",
//     PV: "#9348af60",
//     PW: "#01ac5360",
//     PX: "#c5a4fb60",
//     PY: "#99663560",
//     PZ: "#b1157360",
//     QA: "#63b59860",
//     QB: "#ce7d7860",
//     QC: "#ea9e7060",
//     QD: "#a48a9e60",
//     QE: "#c6e1e860",
//     QF: "#64817760",
//     QG: "#0d5ac160",
//     QH: "#f205e660",
//     QI: "#1c036560",
//     QJ: "#14a9ad60",
//     QK: "#4ca2f960",
//     QL: "#a4e43f60",
//     QM: "#d298e260",
//     QN: "#6119d060",
//     QO: "#d2737d60",
//     QP: "#c0a43c60",
//     QQ: "#f2510e60",
//     QR: "#651be660",
//     QS: "#79806e60",
//     QT: "#61da5e60",
//     QU: "#cd2f0060",
//     QV: "#9348af60",
//     QW: "#01ac5360",
//     QX: "#c5a4fb60",
//     QY: "#99663560",
//     QZ: "#b1157360",
//     RA: "#63b59860",
//     RB: "#ce7d7860",
//     RC: "#ea9e7060",
//     RD: "#a48a9e60",
//     RE: "#c6e1e860",
//     RF: "#64817760",
//     RG: "#0d5ac160",
//     RH: "#f205e660",
//     RI: "#1c036560",
//     RJ: "#14a9ad60",
//     RK: "#4ca2f960",
//     RL: "#a4e43f60",
//     RM: "#d298e260",
//     RN: "#6119d060",
//     RO: "#d2737d60",
//     RP: "#c0a43c60",
//     RQ: "#f2510e60",
//     RR: "#651be660",
//     RS: "#79806e60",
//     RT: "#61da5e60",
//     RU: "#cd2f0060",
//     RV: "#9348af60",
//     RW: "#01ac5360",
//     RX: "#c5a4fb60",
//     RY: "#99663560",
//     RZ: "#b1157360",
//     SA: "#63b59860",
//     SB: "#ce7d7860",
//     SC: "#ea9e7060",
//     SD: "#a48a9e60",
//     SE: "#c6e1e860",
//     SF: "#64817760",
//     SG: "#0d5ac160",
//     SH: "#f205e660",
//     SI: "#1c036560",
//     SJ: "#14a9ad60",
//     SK: "#4ca2f960",
//     SL: "#a4e43f60",
//     SM: "#d298e260",
//     SN: "#6119d060",
//     SO: "#d2737d60",
//     SP: "#c0a43c60",
//     SQ: "#f2510e60",
//     SR: "#651be660",
//     SS: "#79806e60",
//     ST: "#61da5e60",
//     SU: "#cd2f0060",
//     SV: "#9348af60",
//     SW: "#01ac5360",
//     SX: "#c5a4fb60",
//     SY: "#99663560",
//     SZ: "#b1157360",
//     TA: "#63b59860",
//     TB: "#ce7d7860",
//     TC: "#ea9e7060",
//     TD: "#a48a9e60",
//     TE: "#c6e1e860",
//     TF: "#64817760",
//     TG: "#0d5ac160",
//     TH: "#f205e660",
//     TI: "#1c036560",
//     TJ: "#14a9ad60",
//     TK: "#4ca2f960",
//     TL: "#a4e43f60",
//     TM: "#d298e260",
//     TN: "#6119d060",
//     TO: "#d2737d60",
//     TP: "#c0a43c60",
//     TQ: "#f2510e60",
//     TR: "#651be660",
//     TS: "#79806e60",
//     TT: "#61da5e60",
//     TU: "#cd2f0060",
//     TV: "#9348af60",
//     TW: "#01ac5360",
//     TX: "#c5a4fb60",
//     TY: "#99663560",
//     TZ: "#b1157360",
//     UA: "#63b59860",
//     UB: "#ce7d7860",
//     UC: "#ea9e7060",
//     UD: "#a48a9e60",
//     UE: "#c6e1e860",
//     UF: "#64817760",
//     UG: "#0d5ac160",
//     UH: "#f205e660",
//     UI: "#1c036560",
//     UJ: "#14a9ad60",
//     UK: "#4ca2f960",
//     UL: "#a4e43f60",
//     UM: "#d298e260",
//     UN: "#6119d060",
//     UO: "#d2737d60",
//     UP: "#c0a43c60",
//     UQ: "#f2510e60",
//     UR: "#651be660",
//     US: "#79806e60",
//     UT: "#61da5e60",
//     UU: "#cd2f0060",
//     UV: "#9348af60",
//     UW: "#01ac5360",
//     UX: "#c5a4fb60",
//     UY: "#99663560",
//     UZ: "#b1157360",
//     VA: "#63b59860",
//     VB: "#ce7d7860",
//     VC: "#ea9e7060",
//     VD: "#a48a9e60",
//     VE: "#c6e1e860",
//     VF: "#64817760",
//     VG: "#0d5ac160",
//     VH: "#f205e660",
//     VI: "#1c036560",
//     VJ: "#14a9ad60",
//     VK: "#4ca2f960",
//     VL: "#a4e43f60",
//     VM: "#d298e260",
//     VN: "#6119d060",
//     VO: "#d2737d60",
//     VP: "#c0a43c60",
//     VQ: "#f2510e60",
//     VR: "#651be660",
//     VS: "#79806e60",
//     VT: "#61da5e60",
//     VU: "#cd2f0060",
//     VV: "#9348af60",
//     VW: "#01ac5360",
//     VX: "#c5a4fb60",
//     VY: "#99663560",
//     VZ: "#b1157360",
//     WA: "#63b59860",
//     WB: "#ce7d7860",
//     WC: "#ea9e7060",
//     WD: "#a48a9e60",
//     WE: "#c6e1e860",
//     WF: "#64817760",
//     WG: "#0d5ac160",
//     WH: "#f205e660",
//     WI: "#1c036560",
//     WJ: "#14a9ad60",
//     WK: "#4ca2f960",
//     WL: "#a4e43f60",
//     WM: "#d298e260",
//     WN: "#6119d060",
//     WO: "#d2737d60",
//     WP: "#c0a43c60",
//     WQ: "#f2510e60",
//     WR: "#651be660",
//     WS: "#79806e60",
//     WT: "#61da5e60",
//     WU: "#cd2f0060",
//     WV: "#9348af60",
//     WW: "#01ac5360",
//     WX: "#c5a4fb60",
//     WY: "#99663560",
//     WZ: "#b1157360",
//     XA: "#63b59860",
//     XB: "#ce7d7860",
//     XC: "#ea9e7060",
//     XD: "#a48a9e60",
//     XE: "#c6e1e860",
//     XF: "#64817760",
//     XG: "#0d5ac160",
//     XH: "#f205e660",
//     XI: "#1c036560",
//     XJ: "#14a9ad60",
//     XK: "#4ca2f960",
//     XL: "#a4e43f60",
//     XM: "#d298e260",
//     XN: "#6119d060",
//     XO: "#d2737d60",
//     XP: "#c0a43c60",
//     XQ: "#f2510e60",
//     XR: "#651be660",
//     XS: "#79806e60",
//     XT: "#61da5e60",
//     XU: "#cd2f0060",
//     XV: "#9348af60",
//     XW: "#01ac5360",
//     XX: "#c5a4fb60",
//     XY: "#99663560",
//     XZ: "#b1157360",
//     YA: "#63b59860",
//     YB: "#ce7d7860",
//     YC: "#ea9e7060",
//     YD: "#a48a9e60",
//     YE: "#c6e1e860",
//     YF: "#64817760",
//     YG: "#0d5ac160",
//     YH: "#f205e660",
//     YI: "#1c036560",
//     YJ: "#14a9ad60",
//     YK: "#4ca2f960",
//     YL: "#a4e43f60",
//     YM: "#d298e260",
//     YN: "#6119d060",
//     YO: "#d2737d60",
//     YP: "#c0a43c60",
//     YQ: "#f2510e60",
//     YR: "#651be660",
//     YS: "#79806e60",
//     YT: "#61da5e60",
//     YU: "#cd2f0060",
//     YV: "#9348af60",
//     YW: "#01ac5360",
//     YX: "#c5a4fb60",
//     YY: "#99663560",
//     YZ: "#b1157360",
//     ZA: "#63b59860",
//     ZB: "#ce7d7860",
//     ZC: "#ea9e7060",
//     ZD: "#a48a9e60",
//     ZE: "#c6e1e860",
//     ZF: "#64817760",
//     ZG: "#0d5ac160",
//     ZH: "#f205e660",
//     ZI: "#1c036560",
//     ZJ: "#14a9ad60",
//     ZK: "#4ca2f960",
//     ZL: "#a4e43f60",
//     ZM: "#d298e260",
//     ZN: "#6119d060",
//     ZO: "#d2737d60",
//     ZP: "#c0a43c60",
//     ZQ: "#f2510e60",
//     ZR: "#651be660",
//     ZS: "#79806e60",
//     ZT: "#61da5e60",
//     ZU: "#cd2f0060",
//     ZV: "#9348af60",
//     ZW: "#01ac5360",
//     ZX: "#c5a4fb60",
//     ZY: "#99663560",
//     ZZ: "#b1157603"
// }

export { colorsArray1 }