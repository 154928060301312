
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

import { Assessment, Discount } from "@mui/icons-material";
import { IconUserCheck } from "@tabler/icons";
import React from "react";
import { FormattedMessage } from "react-intl";

export const subscription = {
    id: 'Subscription ',
    title: null,
    type: 'group',
    children: [
        {
            id: 'Subscription',
            title: <FormattedMessage id="Subscription" />,
            type: 'collapse',
            icon: Discount,
            children: [
                {
                    id: 'all_Subscription',
                    title: <FormattedMessage id="All Subscriptions" />,
                    type: 'item',
                    url: '/all-subscriptions'
                },
                // {
                //     id: 'manage_Subscription',
                //     title: <FormattedMessage id="Manage Users" />,
                //     type: 'item',
                //     url: '/subscriptions-users'
                // },

            ]
        }
    ]
}