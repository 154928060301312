import React, { useEffect, useState } from 'react'
import ShopServer from './ShopServer';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Navbar from './Navbar';
import Swal from 'sweetalert2';

export default function SellerProfile() {
    const [userDetails, setUserDetails] = useState([]);
    const [address, setAddress] = useState([]);
    const [profile, setProfile] = useState({});
    const [gstNumber, setGstNumber] = useState(null);

    const getUserDetails = () => {


        ShopServer
            .get('/seller/SellerProfile')
            .then(res => {
                console.log('res.data.data', res.data.data)
                setUserDetails(res.data.data[0]);
                setAddress(res.data.data[0]?.addresses.filter(item => item.isActive));
                setProfile(res.data.data[0]);
                setGstNumber(res?.data?.data?.[0]?.gstNumber)
            })
            .catch(err => {
                console.log('error in edit profile', err);
            });
    };
    useEffect(() => {
        getUserDetails()
    }, [])


    const handleSaveDetails = () => {
        let finalObj = {
            ...profile,
            gstNumber: gstNumber
        }
        ShopServer
            .post('/seller/updateSeller', finalObj)
            .then(res => {
                if (res.data.success) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Profile Updated successfully !'
                    })
                }
                getUserDetails();
            })
            .catch(err => {
                console.log('error', err);
                Swal.fire({
                    icon: 'error',
                    text: 'API Error !'
                })
            });
    }



    const handleInputChange = (e) => {
        setGstNumber(e.target.value)
    }


    return (
        <div>
            <Navbar />
            <Grid container gap={2} justifyContent={'center'} mt={2}>
                <Typography variant='h3'>Seller Profile</Typography>
                <Grid item container xs={11} md={11} justifyContent={'center'} gap={2}>
                    <Grid item container xs={12} md={12} justifyContent={'center'}>
                        <img src={profile?.profilePic} height={150} width={150} style={{ objectFit: 'contain', borderRadius: '120%', overflow: 'hidden', boxShadow: '0px 2px 6px lightgray' }} />
                    </Grid>
                    <Grid item container xs={10} md={10} justifyContent={'center'} style={{ border: '1px solid lightgray', borderRadius: '12px', }} p={2} gap={2}>
                        <Grid item container xs={8} md={8}>
                            <Grid item container xs={6} md={6}>Seller Name</Grid>
                            <Grid item container xs={6} md={6}> : {profile?.name} </Grid>
                        </Grid>
                        <Grid item container xs={8} md={8}>
                            <Grid item container xs={6} md={6}>Business Type</Grid>
                            <Grid item container xs={6} md={6}> : {profile?.businessType} </Grid>
                        </Grid>
                        <Grid item container xs={8} md={8}>
                            <Grid item container xs={6} md={6}>Business Name</Grid>
                            <Grid item container xs={6} md={6}> : {profile?.businessName} </Grid>
                        </Grid>
                        <Grid item container xs={8} md={8}>
                            <Grid item container xs={6} md={6}>GST Number</Grid>
                            <Grid item container xs={6} md={6}> : {profile?.gstNumber} </Grid>
                        </Grid>
                        <Grid item container xs={8} md={8}>
                            <Grid item container xs={6} md={6}>Membership</Grid>
                            <Grid item container xs={6} md={6}> : {profile?.activeMembership?.[0]?.name} </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item container xs={11} md={11} justifyContent={'center'} gap={2}>
                    <Grid item xs={12} md={12} container >
                        <TextField
                            fullWidth
                            value={gstNumber}
                            onChange={handleInputChange}
                            label="GST Number"
                            name="gstNumber"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} container>
                        <Button variant='contained' fullWidth onClick={handleSaveDetails}>Save Details</Button>
                    </Grid>
                </Grid>
            </Grid>


        </div>
    )
}
