import React from 'react'
import Navbar from './Navbar'
import { Checkbox, Chip, Grid, Radio, Typography } from '@mui/material'
import ShopServer from './ShopServer'
import { useEffect } from 'react'
import { useState } from 'react'
import dayjs from 'dayjs'
import ProductCard3 from './ProductCard3'
import ShopLoader from './ShopLoader'

export default function OrderHistory() {

    const [orderHistory, setOrderHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(1)

    let orderStatuses = [
        { title: 'All', value: 4, color: 'secondary' },
        { title: 'In Transit', value: 1, color: 'primary' },
        { title: 'Cancelled', value: 2, color: 'error' },
        { title: 'Delivered', value: 3, color: 'success' }
    ]
    let orderStatus = {

        1: { title: 'In Transit', color: 'primary' },
        2: { title: 'Cancelled', color: 'error' },
        3: { title: 'Delivered', color: 'success' },
        4: { title: 'null', color: 'default' }
    }

    const getOrderHistory = (order_status) => {
        setLoading(true)
        let url = '/products/allProductOrders'
        if (order_status && order_status != 4) {
            url = `/products/allProductOrders?status=${order_status}`
        }
        ShopServer.get(url)
            .then((res) => {
                console.log('res?.data?.data', res?.data?.data)
                setOrderHistory(res?.data?.data || [])
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)

            })
    }
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value)
        getOrderHistory(e.target.value)
    }
    useEffect(() => {
        getOrderHistory(1)
    }, [])

    return (
        <>
            <Navbar />
            <Grid container>
                <Grid container justifyContent={'space-around'} alignItems={'center'} >
                    {orderStatuses?.map((item, ind) => <div key={ind} style={{ textAlign: 'center' }}>
                        <Radio color={item?.color || 'default'} checked={selectedStatus == item?.value} onChange={handleChangeStatus} value={item?.value} size={'small'} />
                        <Typography variant='caption' color={selectedStatus == item?.value ? item?.color : 'black'} >{item?.title}</Typography>
                    </div>)}

                </Grid>
                {loading ? <ShopLoader title={'Orders History'} linerLoading /> :
                    <Grid item xs={12} md={12} sm={12} lg={12} container>
                        <Grid item container xs={12} md={12} justifyContent={'space-between'} textAlign={'center'} fontWeight={'bold'} p={2}>
                            <Grid item xs={3} md={3} sm={3}> Order Date</Grid>
                            <Grid item xs={3} md={3} sm={3}> Order ID</Grid>
                            {/* <Grid item xs={3} md={3} sm={3}> Seller Name</Grid> */}
                            <Grid item xs={3} md={3} sm={3}> Amount</Grid>


                        </Grid>
                        {
                            orderHistory && orderHistory.length > 0 ? orderHistory.map((ord, index) =>
                                <Grid item container xs={12} md={12} key={index}
                                    justifyContent={'space-between'} textAlign={'center'} p={2} alignItems={'center'}
                                    style={{ boxShadow: 'inset 0px 2px 6px lightgray' }}

                                >
                                    <Grid item xs={3} md={3} sm={3}> {dayjs(ord?.createdAt).format('DD/MMM/YYYY hh:mm a')}</Grid>
                                    <Grid item xs={3} md={3} sm={3}> {ord?.shiprocketOrderId}</Grid>
                                    <Grid item xs={3} md={3} sm={3}> {"₹ " + ord?.totalPayableAmount}</Grid>
                                    <Grid item xs={12} md={12} sm={12} container justifyContent={'flex-end'}>
                                        {ord?.status ? <Chip size='small' color={(orderStatus[ord?.status]?.color || 'default')} label={orderStatus[ord?.status]?.title || 'error'} /> : null}
                                    </Grid>
                                    <Grid container item xs={12} md={12} sm={12} gap={1}>
                                        {
                                            ord?.products.map((prod, prodId) => <ProductCard3 key={prodId} product={prod} />)
                                        }

                                    </Grid>

                                </Grid>)
                                : !loading && 'No Orders Found !'
                        }
                    </Grid>
                }
            </Grid>
        </>
    )
}
