import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import ImageIcon from '../../layout/Customization/ImageIcon';
import { drawerCoins } from '../../assets/images';
import { fontSizestyles2 as styles } from './fontSizes'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation';
import { Delete } from '@material-ui/icons';

export default function RewardProductCard(props) {
    const { product } = props;
    return (
        <Grid container item xs={12} lg={12} style={{ border: '1px solid lightgray' }} p={1} borderRadius={2}>
            <Grid container item xs={12} lg={12} justifyContent={'center'}>
                <Typography variant='caption'
                    style={{ fontWeight: 600, color: "black", lineHeight: 1, height: 25, overflow: 'hidden' }}>
                    {product.name}
                </Typography>
                <img src={product?.imageUrl} height={70} width={70} style={{ objectFit: 'contain' }} />
            </Grid>
            <Grid container item xs={12} lg={12} mt={2} >
                <Button variant='contained' size='small' fullWidth sx={{ color: 'white', background: '#94c100', fontWeight: 600 }}>
                    <ImageIcon url={drawerCoins} size="small" /> {product?.coinsRequired}
                </Button>
            </Grid>
        </Grid>
    )
}

export function RewardProductCard2(props) {

    const { product, handleRemoveRewardProduct } = props;

    return (
        <Grid container style={styles.container} >
            <Grid container justifyContent={'center'} alignItems={'center'} item style={styles.img_container} lg={3} xs={3} sm={3} md={3}>
                {/* <Link to={`/shopping/product-details/${product?._id}`} style={{ textDecoration: 'none' }}> */}
                <img src={product?.image} style={styles.imgSt} />
                {/* </Link> */}
            </Grid>
            <Grid item lg={9} sm={9} xs={9} md={9} p={2} container justifyContent={'space-between'}>
                <Typography style={styles.fontSize} >{product?.rewardProductName}</Typography>
                <Grid container lg={12} xs={12} sm={12} md={12} alignItems={'center'} >
                    <Grid item container alignItems={'center'} lg={6}>
                        <Typography variant='caption' style={styles.fontSizeMrp}>MRP : </Typography>
                        <Typography style={styles.fontSize1} > <del>{"₹" + numericCommaSeparation(Math.round(product?.mrp || product?.menuPrice))}</del></Typography>
                    </Grid>
                    <Grid item container alignItems={'center'} lg={12} xs={12}  >
                        <Grid item container alignItems={'center'} lg={6} xs={6}>
                            <ImageIcon url={drawerCoins} size="small" /> {product.rewardProductCoins}
                        </Grid>
                        <Grid item container alignItems={'center'} lg={6} xs={6} justifyContent={'flex-end'}>
                            <Button color='error' size='small' variant='contained' onClick={() => handleRemoveRewardProduct(product)}><Delete /> REMOVE</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}
