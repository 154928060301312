// third-party
import { FormattedMessage } from 'react-intl';
import Groups2Icon from '@mui/icons-material/Groups2';

// assets
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const customer = {
    id: 'customer',
    title: <FormattedMessage id="customer" />,
    type: 'group',
    children: [
        {
            id: 'Customers',
            title: <FormattedMessage id="Customers" />,
            type: 'item',
            url: '/customerList',
            // icon: icons['IconUsers'],
            icon: Groups2Icon,
            breadcrumbs: false,

        },

    ]
}