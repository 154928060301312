
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

import { Assessment } from "@mui/icons-material";
import { IconUserCheck } from "@tabler/icons";
import React from "react";
import { FormattedMessage } from "react-intl";

export const reports = {
    id: 'Reports ',
    title: null,
    type: 'group',
    children: [
        {
            id: 'All Reports',
            title: <FormattedMessage id="All Reports" />,
            type: 'collapse',
            icon: Assessment,
            children: [
                {
                    id: 'posts',
                    title: <FormattedMessage id="Sales Report" />,
                    type: 'item',
                    url: '/dashboard'
                },

            ]
        }
    ]
}