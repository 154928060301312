import { History, Home, Inbox, Lock, Logout, Mail, Menu, Search, ShoppingBag, ShoppingCart, Support } from '@material-ui/icons'
import { Autocomplete, Badge, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, SwipeableDrawer, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ListItemText, Typography } from '@material-ui/core'
import { Capa_13x, drawerCoins, user_profile_icon } from '../../assets/images'
import { IconUser } from '@tabler/icons'
import { logoutBoth, saveAdminData, saveSellerData, setAdminLoginStatus, setSellerLoginStatus } from '../../store/Actions/agentLoginActions'
import ShopServer from './ShopServer'
import { useEffect } from 'react'
import { removeAllProductFromCart } from '../../store/Actions/userCartActions'
import ImageIcon from '../../layout/Customization/ImageIcon'

export default function Navbar(props) {
    const { getSuggestedProductNames } = props;
    const productsInCart = useSelector((state) => state?.userCart?.products)
    const adminDataRedux = useSelector((state) => state?.agentInfo?.adminData)
    const sellerDataRedux = useSelector((state) => state?.agentInfo?.sellerData)
    const [earnedCoins, setEarnedCoins] = useState(0)
    const subscriptionDetails = useSelector(state => state?.agentInfo?.seller_subscription)

    let menuItems = [
        // {
        //     name: 'Profile',
        //     route: '/shopping/profile',
        //     icon: <IconUser />
        // },

        {
            name: 'Home',
            route: '/shopping',
            icon: <Home />
        },

        {
            name: 'Cart',
            route: '/shopping/my-cart',
            icon: <ShoppingCart />
        },
        {
            name: 'Orders History',
            route: '/shopping/orders-history',
            icon: <History />
        },


    ]
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, left: open });
    };



    const dispatch = useDispatch()

    const hitApiLogoutUser = () => {
        ShopServer.get('role5/logOutFromPrelive')
            .then((res) => {
                console.log('res after logout', res)
                localStorage.removeItem('sellerToken')
                localStorage.removeItem('brands')

            })
            .catch((err) => console.error(err))
    }

    const logoutSeller = () => {
        hitApiLogoutUser()
        dispatch(setSellerLoginStatus(false))
        dispatch(saveSellerData({}))
        dispatch(removeAllProductFromCart({}))
        localStorage.removeItem('brands')

    }

    const handlelogoutBoth = () => {
        dispatch(logoutBoth())
        localStorage.removeItem('agentId')
        dispatch(removeAllProductFromCart({}))
        localStorage.removeItem('brands')


    }

    const fetchCoins = () => {
        ShopServer.get(`/seller/getSellerCoins`).then(res => {
            setEarnedCoins(res.data.data);
            // setNetCoins(100);
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        fetchCoins()
    }, [])
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem >
                    <Grid container >
                        <Grid container item xs={12} md={12} justifyContent={'center'} alignItems={'center'} style={{ height: 100, width: '100%' }}>

                            <div style={{ borderRadius: '100%', overflow: 'hidden', boxShadow: '0px 2px 6px lightgray' }}>
                                <img src={user_profile_icon} height={90} width={'100%'} style={{ objectFit: 'contain' }} />

                            </div>

                        </Grid>

                        <Grid container item xs={12} md={12}>

                            <Typography>Agent Name : {adminDataRedux?.name}</Typography>
                            <Typography>Agent Number : {adminDataRedux?.userName}</Typography>
                            <Typography>Seller Name : {sellerDataRedux?.name}</Typography>
                            <Typography>Seller Number : {sellerDataRedux?.phoneNumber}</Typography>
                        </Grid >
                    </Grid>

                </ListItem>

                <ListItem >
                    <Link to="/shopping/coin-shop" style={{ width: '100%' }}>
                        <Button size='small' title='Cart' variant='outlined' color='primary' fullWidth >
                            {earnedCoins} Coins
                            <img src={drawerCoins} height={25} width={25} style={{ objectFit: 'contain' }} />
                        </Button>
                    </Link>
                </ListItem>

                {menuItems?.map((item, idd) => <ListItem key={idd}>
                    <Link to={item?.route} style={{ textDecoration: 'none', width: '100%' }}>
                        <ListItemButton  >
                            <ListItemIcon>{item?.icon}</ListItemIcon>
                            <ListItemText primary={item?.name} />
                        </ListItemButton>
                    </Link>
                </ListItem>)}

                <ListItem >
                    <Link to={'/shopping/seller-profile'} style={{ textDecoration: 'none', width: '100%' }}>
                        <ListItemButton >
                            <ListItemIcon><IconUser /></ListItemIcon>
                            <ListItemText primary={'Seller Profile'} />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem >
                    <Link to={'/shopping/login'} style={{ textDecoration: 'none', width: '100%' }}>
                        <ListItemButton onClick={logoutSeller}>
                            <ListItemIcon><Logout /></ListItemIcon>
                            <ListItemText primary={'Logout Seller'} />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem >
                    <Link to={'/shopping/login'} style={{ textDecoration: 'none', width: '100%' }}>
                        <ListItemButton onClick={handlelogoutBoth}>
                            <ListItemIcon><Logout /></ListItemIcon>
                            <ListItemText primary={'Logout Both'} />
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>

        </Box>
    );

    return (
        <Grid container justifyContent={'center'} p={1}>
            <Grid item container xs={12} lg={12} sm={12} md={12} justifyContent={'space-between'} alignItems={'center'}>
                <IconButton title='Cart' onClick={toggleDrawer('left', true)} >
                    <Menu />
                </IconButton>
                <div style={{ display: 'flex' }}>
                    {/* <Button size='small' title='Cart' variant='text' color='primary' style={{ background: '#fcf3c2', color: 'black', padding: '2px 10px', }}  > */}
                    {/* <div> */}
                    {/* <Lock /> */}
                    {/* <ImageIcon url={Capa_13x} size="small" /> */}
                    {/* </div> */}

                    {subscriptionDetails?.active ? <Link to="/shopping/my-subscription" style={{ textDecoration: 'none' }}>
                        <div style={{ color: 'black', padding: '2px', position: 'relative' }}  >
                            <div style={{ display: 'flex', position: 'absolute', top: 2, left: -5 }}>
                                {/* <Lock /> */}
                                <ImageIcon url={Capa_13x} size='small' />
                                <Typography letterSpacing={3} style={{ marginTop: -3, marginLeft: 3, color: 'darkviolet' }}>PRIME</Typography>
                            </div>
                            <div style={{ textAlign: 'left', background: '#fcf3c2', padding: '2px 10px', paddingLeft: '18px', borderRadius: 3, marginTop: '10px' }} >
                                <Typography fontWeight={600} fontSize={18} lineHeight={0.8}> ₹ {subscriptionDetails?.availableAmount} </Typography>
                                <Typography style={{ fontSize: 9 }}> Free Products </Typography>
                            </div>
                        </div>
                        {/* </Button> */}
                    </Link> : null}
                    {/* <Link to="/shopping/coin-shop">
                        <Button size='small' title='Cart' variant='outlined' color='primary'  >
                            {earnedCoins} Coins
                            <img src={drawerCoins} height={25} width={25} style={{ objectFit: 'contain' }} />
                        </Button>
                    </Link> */}
                    <Link to="/shopping/my-cart">
                        <IconButton title='Cart' >
                            <Badge badgeContent={productsInCart && productsInCart?.length} color='success'>
                                <ShoppingCart color='primary' />
                            </Badge>
                        </IconButton>
                    </Link>
                </div>
            </Grid>

            <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {list('left')}
            </SwipeableDrawer>
        </Grid>
    )
}
