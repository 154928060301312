import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
// import Logo from './../../../ui-component/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_OPEN } from '../../../store/actions';
import { buyumeLogo } from '../../../assets/images';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    const dispatch = useDispatch()
    const userDataRedux = useSelector(state => state.userInfo.userData)
    const userRole = userDataRedux?.role
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath} onClick={() => {
            dispatch({ type: MENU_OPEN, id: 'Dashboard' });

        }}>
            {/* <Logo /> */}

            <img src={buyumeLogo} alt="logo" width="80%" />
        </ButtonBase>
    );
};

export default LogoSection;
