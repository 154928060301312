import React from "react";
import { Box } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import { IconX } from "@tabler/icons";

export default function SidebarComponent(props) {
  const windowWidth = window.innerWidth;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: props.openSidebar,
  });

  React.useEffect(() => {
    setState({ ...state, right: props.openSidebar });
  }, [props.openSidebar]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '45vw', }}
      // sx={{ width: windowWidth > 900 ? "45vw" : "100%", }}
      // sx={{ width: windowWidth > 900 ? "50vw" : "50%", }}
      sx={{ width: { xs: "100%", lg: "45vw", md: "60vw", sm: "85vw" } }}
      role="presentation"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          justifyContent="space-between"
          container
          alignItems={"center"}
          style={{ backgroundColor: "#068580", paddingLeft: 25, height: 88 }}
        >
          <span
            style={{
              color: "white",
              fontSize: 22,
              textTransform: "capitalize",
              maxWidth: "80%",
              overflow: "hidden",
              //   overflowY: "auto",
              textOverflow: "ellipsis",
              //   whiteSpace: "nowrap",
            }}
          >
            {props.title}
          </span>
          <Typography
            color="white"
            onClick={() => props.setOpenSidebar(false)}
            style={{ marginRight: 30, cursor: "pointer", fontSize: 18 }}
          >
            <IconX />
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} xs={12} sm={12}>
          {props.component}
        </Grid>
      </Grid>
      {/* <Divider /> */}
    </Box>
  );

  return (
    <div>
      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => {
              toggleDrawer(anchor, false);
              props.setOpenSidebar(false);
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
