import { Button, Grid, Typography } from '@material-ui/core'
import { IconArrowRight } from '@tabler/icons'
import React from 'react'
import { Slide_3 } from '../../assets/images'
import { logFirebaseEvent } from '../../LogFirebaseEvent'



const Circle = ({ title, index, right, top }) => {
    return (<Grid container justifyContent={'center'} alignItems="center"
        sx={{
            height: 74, width: 74,
            boxShadow: '0px 0px 25px 0px #94c10060',
            WebkitBoxShadow: " 0px 0px 17px 0px rgba(148,193,0,1)",
            bgcolor: 'white', borderRadius: '100%',
            position: 'absolute',
            top: top,
            right: right,
            padding: 1
        }}>
        <Typography fontSize={12} textAlign="center">{title}</Typography>
    </Grid>)
}
export default function Slide3(props) {
    const { skipSlider } = props;
    let circleData = [
        { title: "Firmness", right: "24%", top: '21%' },
        { title: "Smoothness", right: "13%", top: '33%' },
        { title: "Hydration Level", right: "9%", top: '46%' },
        { title: "Dullness", right: "15%", top: '59%' },
        { title: "Uneven Skin Tone", right: "32%", top: '69%' },
        { title: "Fine Lines & Wrinkles", right: "52%", top: '76%' },
        { title: "Darkspots", right: "74%", top: '81%' }
    ]


    return (
        <Grid container justifyContent={'space-between'} sx={{ position: "relative" }}>
            <Grid item lg={12} xs={12} md={12} sm={12} p={'24px'} pt={'62px'} container alignItems={'center'} flexDirection="column">
                <Typography color={'#002a5c'} textAlign="center">
                    Your report  will be prepared by detecting and
                    analyzing your skin strengths and focus areas across
                    7 different skin concerns mentioned below

                </Typography>
            </Grid>
            <Grid container item lg={12} xs={12} md={12} sm={12} alignItems="center" justifyContent={'center'}>
                <img src={Slide_3} alt="Slide_3" width={'100%'} style={{ marginTop: 0 }} />
            </Grid>
            <Grid container style={{
                position: 'absolute', top: 0, right: 0,
                height: '100%'
            }} p={3} >
                <Grid container justifyContent={'center'} alignItems="end" flexDirection={"column"}>
                    {circleData.map((item, index) => <Circle title={item?.title} right={item?.right} top={item.top} index={index} key={index} />)}
                </Grid>
                <Grid container justifyContent={'center'} alignItems="end" mb={1}>
                    <Button variant='contained' sx={{ bgcolor: '#002A5C', borderRadius: 3, padding: '7px 25px' }} onClick={() => {
                        skipSlider()
                        logFirebaseEvent('Lets start button')
                    }}>
                        Lets Start &nbsp;&nbsp;  <IconArrowRight size={20} />
                    </Button>
                </Grid>
            </Grid>
        </Grid >
    )
}
