
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

import React from "react";
import { Assessment, Computer } from "@mui/icons-material";
import { IconBox, IconBrandWhatsapp, IconUserCheck } from "@tabler/icons";
import { FormattedMessage } from "react-intl";

export const developer_area = {
    id: 'Dev_Area',
    title: null,
    type: 'group',
    children: [
        {
            id: 'Dev Area',
            title: <FormattedMessage id="Dev Area" />,
            type: 'collapse',
            icon: Computer,
            children: [
                {
                    id: 'Salons List',
                    title: <FormattedMessage id="Salons List" />,
                    type: 'item',
                    url: '/dev-area/allsalons'
                },
                {
                    id: 'Detailed Salons List',
                    title: <FormattedMessage id="Detailed Salons List" />,
                    type: 'item',
                    url: '/dev-area/detailed-salons-list'
                },
                // {
                //     id: 'Promotions',
                //     title: <FormattedMessage id="Promotions" />,
                //     type: 'item',
                //     url: '/promotions'
                // },
                // {
                //     id: 'Products',
                //     title: <FormattedMessage id="Products" />,
                //     type: 'item',
                //     url: '/products',
                // },
                {
                    id: 'Whatsapp',
                    title: <FormattedMessage id="Whatsapp" />,
                    type: 'item',
                    url: '/whatsapp-chat',
                    icon: IconBrandWhatsapp,
                    breadcrumbs: false,
                },

            ]
        }
    ]
}