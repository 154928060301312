
const initialState = {
    adminData: [],
    sellerData: [],
    seller_subscription: {},
    isAdminLoggedIn: false,
    isSellerLoggedIn: false,

}

const agentLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ADMIN_DATA':
            return {
                ...state,
                adminData: action.payload
            };
        case 'SET_SELLER_DATA':
            return {
                ...state,
                sellerData: action.payload
            };
        case 'SET_SELLER_MEMBERSHIP':
            return {
                ...state,
                membershipStatus: action.payload
            };
        case 'SET_ADMIN_LOGIN_STATUS':
            return {
                ...state,
                isAdminLoggedIn: action.payload
            };
        case 'SET_SELLER_LOGIN_STATUS':
            return {
                ...state,
                isSellerLoggedIn: action.payload
            };
        case 'SET_SELLER_SUBSCRIPTION':
            return {
                ...state,
                seller_subscription: action.payload
            };
        case 'LOGOUT_BOTH':
            return {
                ...state,
                isSellerLoggedIn: false,
                isAdminLoggedIn: false,
                adminData: [],
                sellerData: [],
                seller_subscription: {}
            };
        default:
            return state;

    };
}

export default agentLoginReducer;

