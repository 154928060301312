import { Button, Grid } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import Slide1 from './Slides/Slide1'
import Slide2 from './Slides/Slide2'
import Slide3 from './Slides/Slide3'
import 'animate.css'
import Slider from 'react-slick'
import { logFirebaseEvent } from '../LogFirebaseEvent'

export default function SplashScreen(props) {
    const { skipSlider } = props;
    const [currentSlide, setCurrentSlide] = useState(0)

    let settings = {
        dots: currentSlide == 2 ? false : true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
    };


    return (
        <Grid container style={{
            backgroundColor: '#94c10034',
            position: 'relative',
            // height: '100%',
            overflow: 'hidden'

        }}
            alignItems='center' >

            <Slider {...settings}
                afterChange={(value) => setCurrentSlide(value)}
                style={{
                    overflow: 'hidden',

                }}
                className="smart_camera_slider">
                <Slide1 />
                <Slide2 />
                <Slide3 skipSlider={skipSlider} />
            </Slider>

            <Button variant='outlined' onClick={() => {
                skipSlider()
                logFirebaseEvent('Skip button')
            }}
                sx={{
                    color: '#002a5c', borderColor: 'transparent', backgroundColor: '#002a5c30',
                    fontWeight: 'bold', borderRadius: 8, position: 'absolute', top: 15, right: 15
                }}
                size={'small'}>
                Skip
            </Button>
        </Grid>
    )
}
