import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import userInfoReducer from './Reducers/userInfoReducer';
import userCartReducer from './Reducers/userCartReducer';
import agentLoginReducer from './Reducers/agentLoginReducer';
//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    userInfo: userInfoReducer,
    userCart: userCartReducer,
    agentInfo: agentLoginReducer

});

export default reducer;
