import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { Divider, Grid, Typography } from '@mui/material'
import { BuyumeLogo_3, Capa_13x, buyumeLogo, buyumeLogo_2, buyume_2, gradientLine } from '../../assets/images'
import { ArrowDownward, ArrowDropDown, ExpandLess, ExpandMore, Lock, Star } from '@material-ui/icons'
import ShopServer from './ShopServer'
import ImageIcon from '../../layout/Customization/ImageIcon'
import { IconButton } from '@mui/material'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)



export default function Subscription() {
    const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([])
    const [subscriptionInfo, setSubscriptionInfo] = useState([])
    const [expended, setExpended] = useState(0)
    const [purchaseHistory, setPurchaseHistory] = useState({})
    const [freeProducts, setFreeProducts] = useState({})

    const subscriptionDetails = useSelector((state) => state?.agentInfo?.seller_subscription)

    const getSubscriptionTopInfo = () => {
        ShopServer.get(`/subscription/topInfo`)
            .then(res => {
                if (res?.data?.success) {
                    // console.log("topInfo",res?.data?.data)
                    // setTopSection( res?.data?.data );
                    // setLoading( false )
                }
            })
            .catch(error => {
                console.log('errorworld', error);
            });
    };
    useEffect(() => {
        getSubscriptionTopInfo();
    }, []);
    const getSubscriptionInfo = () => {
        ShopServer.get('/subscription/subscriptionStaticInfo').then(res => {
            if (res.data.success) {
                console.log('--0--0', res.data?.data?.data)
                setSubscriptionInfo(res.data?.data?.data);
            }
        })
            .catch(error => {
                console.log('error', error);
            });
    };

    const getFrequentlyAskedQuestion = () => {
        ShopServer.get('/subscription/frequentlyAskedQuestion')
            .then(res => {
                if (res.data.success) {
                    console.log("frequentlyAskedQuestion", res.data?.data)
                    setFrequentlyAskedQuestions(res.data.data);
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    };


    // console.log('purchaseHistory------',purchaseHistory)

    const getCurrentMonthPurchaseHistory = () => {
        ShopServer.get('/subscription/currentMonthPurchaseHistory').then((res) => {
            console.log('=----getCurrentMonthPurchaseHistory', res.data.data)
            if (res.data.success) {
                res.data.data[0]?.freeProducts.flat()
                let free = res.data.data[0]?.freeProducts && res.data.data[0]?.freeProducts.flat() || []
                setPurchaseHistory(res.data.data[0])
                setFreeProducts(free)
            }
        }).catch((error) => {
            console.log('currentMonthPurchaseHistory error', error)
        })
    }
    const getSubscriptionSDetails = () => {
        ShopServer.get('/subscription/subscriptionStaticInfo')
            .then(res => {

                if (res.data.success) {
                    console.log('res.data?.data?.data', res.data?.data?.data)
                    // setSubscriptionInfo(res.data?.data?.data);;
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        getSubscriptionInfo();
        getFrequentlyAskedQuestion();
    }, []);
    useEffect(() => {
        if (subscriptionDetails?.subscriptionId) {
            getCurrentMonthPurchaseHistory()
        }
    }, [subscriptionDetails])
    const handleExpandMore = (index) => {
        if (index + 1 == expended) {
            setExpended(0)
        } else {
            setExpended(index + 1)
        }
    }

    return (
        <div>
            <Navbar />
            <Grid container pt={2} justifyContent={'center'}>
                {/* <Grid item xs={12} lg={12} md={12} sm={12}
                    style={{ border: '1px solid lightgray', padding: '10px', borderRadius: '8px 8px 0px 0px', boxShadow: '0px 2px 6px lightgray' }}>
                    <Typography variant='h3' textAlign={'center'}>My Subscription</Typography>
                </Grid> */}
                <Grid item xs={12} lg={12} md={12} sm={12} p={2} style={{
                    background: `rgb(164,6,171)`,
                    background: `linear-gradient(90deg, rgba(164,6,171,0.7091211484593838) 0%, rgba(119,31,219,0.7175245098039216) 100%)`,
                    borderRadius: '0px 0px 24px 24px',
                    overflow: 'hidden'

                }}>
                    <Grid item container xs={12} lg={12} md={12} sm={12} gap={1} justifyContent={'center'} alignItems={'center'} p={1}>
                        <img src={buyume_2} height={27} />
                        <Typography variant='h3' style={{ color: 'white' }}>Prime</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} mt={1} container justifyContent={'space-between'}
                        style={{
                            background: 'white', borderRadius: "18px", overflow: 'hidden'
                        }}>
                        <Grid item container xs={12} lg={12} md={12} sm={12} p={2} justifyContent={'center'} alignItems={'center'}>
                            <Typography fontWeight={'bold'}>Your {subscriptionDetails?.planYearDuration} year Subscription plan</Typography>
                            <Grid item xs={9} md={9} container color='black' pt={1}>
                                <Grid item xs={5} md={5} >
                                    <Typography fontSize={12}>Start Date</Typography>
                                    <Typography fontWeight={'bold'}>{dayjs(subscriptionDetails?.startDate).format('DD MMMM YYYY')}</Typography>
                                </Grid>
                                <Grid item xs={2} md={2} container justifyContent={'center'}>
                                    <hr />
                                </Grid>
                                <Grid item xs={5} md={5}>
                                    <Typography fontSize={12}>End Date</Typography>
                                    <Typography fontWeight={'bold'}> {dayjs(subscriptionDetails?.endDate).format('DD MMMM YYYY')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} p={1} style={{ background: '#ebb734', color: 'black' }} container justifyContent={'center'}>
                            {/* <Grid item xs={9} md={9} container color='black' pt={1}> */}
                            <Grid item xs={6} md={6} >
                                <Typography >Redeemed Amount</Typography>
                                <Typography fontWeight={'bold'}> ₹{subscriptionDetails?.limitUsed || 0}</Typography>
                            </Grid>
                            <Grid item xs={5} md={5} textAlign={'end'}>
                                <Typography >Locked Amount</Typography>
                                <Typography fontWeight={'bold'}>   ₹{subscriptionDetails?.remainingLimit || 0}/₹{subscriptionDetails?.yearlyLimit || 0}</Typography>
                                <Typography fontSize={10} color={'black'}> Free Products</Typography>
                            </Grid>
                            {/* </Grid> */}
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={11} lg={11} md={11} sm={11} mt={2} style={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                    border: '1px solid violet'

                }}>
                    <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent={'space-between'} alignItems={'center'} p={1}
                        style={{ backgroundColor: '#ff3226', color: 'white' }}>
                        <Typography  >Expires in {dayjs(purchaseHistory?.endDate).from(dayjs())}</Typography>
                        <Typography  >{dayjs(purchaseHistory?.startDate).format('DD MMMM') + "-" + dayjs(purchaseHistory?.endDate).format('DD MMMM')}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} mt={1} container justifyContent={'space-between'}>
                        <Grid item container xs={12} lg={12} md={12} sm={12} p={1} justifyContent={'center'} >
                            <Grid item xs={12} md={12} container color='black'>
                                <Grid item xs={6} md={6} lineHeight={0.6} >
                                    <Typography fontWeight={'bold'}>Locked Amount</Typography>
                                    <Typography variant='caption' color={'black'} fontSize={10}>
                                        {purchaseHistory?.info}</Typography>
                                </Grid>
                                <Grid item xs={6} md={6} container justifyContent={'flex-end'} >
                                    <Grid item xs={9} md={9} container style={{ background: '#fcf3c2', color: 'black', padding: '2px', position: 'relative' }}  >
                                        <div style={{ position: 'absolute', top: -5, left: -5 }}>
                                            {/* <Lock /> */}
                                            <ImageIcon url={Capa_13x} size='small' />
                                        </div>
                                        <div style={{ textAlign: 'left', marginLeft: "20px", marginTop: "5px" }} >
                                            <Typography variant='h3' lineHeight={0.8}> ₹{purchaseHistory?.lockedAmount} <span style={{ fontSize: 12 }}>/ ₹{purchaseHistory?.monthLimit}</span> </Typography>
                                            <Typography style={{ fontSize: 9 }}> Free Products </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12} style={{ color: 'black', fontSize: 11, fontWeight: 600 }} pt={1}
                                gap={1} container pb={3}>
                                <span>Saving Summary</span>
                                <Divider style={{ width: '100%' }} />
                                <Grid item xs={12} md={12} container justifyContent={'space-between'}>
                                    <span>Saving Purchase</span>
                                    <span> ₹{purchaseHistory?.totalPurchase}</span>
                                </Grid>
                                <Grid item xs={12} md={12} container justifyContent={'space-between'}>
                                    <span>Unlocked Amount (10% of purchase)</span>
                                    <span> ₹{purchaseHistory?.unlockedAmount || 0}</span>
                                </Grid>
                                <Grid item xs={12} md={12} container justifyContent={'space-between'}>
                                    <span>Free Products Redeemed</span>
                                    <span> ₹{purchaseHistory?.usedAmount || 0}</span>
                                </Grid>


                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>
                {/* <Grid item container xs={11} lg={11} md={11} sm={11} pt={1} justifyContent={'center'} mt={1}>
                    <Grid item xs={12} lg={12} md={12} sm={12} container gap={1} justifyContent={'center'} alignItems={'center'}>
                        <Star style={{ fontSize: 14, color: '#7f2b94' }} />
                        <Typography fontWeight={600} color={'black'}>Steps to get 10% EXTRA Every Month </Typography>
                        <Star style={{ fontSize: 14, color: '#7f2b94' }} />
                    </Grid>
                    <Grid item xs={11} lg={11} md={11} sm={11}>
                        <hr />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} style={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        border: '1px solid violet',
                        height: 200

                    }}>

                    </Grid>
                </Grid> */}
                {subscriptionInfo && subscriptionInfo.map((info, index) => <Grid key={index} item container xs={12} lg={12} md={12} sm={12} pt={1} justifyContent={'center'} mt={2}>
                    <Grid item xs={12} lg={12} md={12} sm={12} container gap={1} justifyContent={'center'} alignItems={'center'}>
                        <Star style={{ fontSize: 14, color: '#7f2b94' }} />
                        <Typography fontWeight={700} sx={{ width: '65vw', textAlign: 'center', letterSpacing: 1.5 }}>{info?.mainTitle}</Typography>
                        <Star style={{ fontSize: 14, color: '#7f2b94' }} />
                    </Grid>
                    <Grid item xs={11} lg={11} md={11} sm={11}>
                        <hr />
                    </Grid>
                    <Grid item xs={11} lg={11} md={11} sm={11} style={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        border: '1px solid violet',
                        // height: 200

                    }}>
                        {info.subTitles[0].subTitle ? <Grid item xs={12} lg={12} md={12} sm={12} p={1} container justifyContent={'center'} alignItems={'center'}>
                            <img src={gradientLine} height={20} width={60} style={{ objectFit: 'contain' }} />
                            <Typography fontWeight={600} color={'black'}>&nbsp;&nbsp;Product Benefits&nbsp;&nbsp;</Typography>
                            <img src={gradientLine} height={20} width={60} style={{ objectFit: 'contain' }} />
                        </Grid> : null}
                        <Grid item xs={12} lg={12} md={12} sm={12} container alignItems={'center'} gap={1} p={1}>
                            {info.subTitles?.[0]?.bullets?.map((bullet, idx) => <Grid item container xs={12} md={12} gap={2} alignItems={'center'}>
                                <Grid item xs={1} md={1} container alignItems={'center'}>
                                    <ImageIcon url={bullet.image} size="medium" style={{ padding: '3px' }} />
                                </Grid>
                                <Grid item xs={10} md={10} container alignItems={'center'}>
                                    <Typography variant='caption' color={'black'} fontWeight={'bold'} lineHeight={1}>{bullet?.boldText}</Typography>
                                </Grid>
                            </Grid>)}

                        </Grid>

                    </Grid>


                </Grid>)}
                <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent={'center'} mt={2} gap={1}>
                    <Grid item xs={11} lg={11} md={11} sm={11} container pb={2}>
                        <Typography variant='h3' style={{ color: '#7f2b94' }}>FAQs</Typography>
                    </Grid>
                    {frequentlyAskedQuestions?.map((ques, index) =>
                        <Grid item xs={11} lg={11} md={11} sm={11} container key={index} alignItems={'center'}>
                            <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent={'space-between'} alignItems={'center'} onClick={() => handleExpandMore(index)} style={{ cursor: 'pointer' }}>
                                <Grid item xs={10} lg={10} md={10} sm={10} >
                                    <Typography variant='body2' color={'black'}>{ques?.question}</Typography>
                                </Grid>
                                <Grid item xs={2} lg={2} md={2} sm={2} >
                                    {expended == index + 1 ?
                                        <IconButton size='small'><ExpandLess className='animate__animated  animate__rotateInUpLeft' style={{ color: '#7f2b94' }} /></IconButton> :
                                        <IconButton size='small' ><ExpandMore className='animate__animated  animate__rotateInDownLeft' style={{ color: '#7f2b94' }} /></IconButton>
                                    }
                                </Grid>
                            </Grid>
                            {expended == index + 1 ? <Grid item xs={10} lg={10} md={10} sm={10} style={{ transition: 'ease-in' }}>
                                <Typography variant='body2'>{ques?.answer}</Typography>
                            </Grid> : null}
                        </Grid>)
                    }


                    <Grid item xs={11} lg={11} md={11} sm={11} container pb={2}>

                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}
