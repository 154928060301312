// third-party
import { FormattedMessage } from 'react-intl';

// assets
import CampaignIcon from '@mui/icons-material/Campaign';

//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const promotions = {
    id: 'promotions',
    title: <FormattedMessage id="promotions" />,
    type: 'group',
    children: [
        {
            id: 'Promotions',
            title: <FormattedMessage id="Promotions" />,
            type: 'collapse',
            icon: CampaignIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'SMS Promotions',
                    title: <FormattedMessage id="SMS Promotions" />,
                    type: 'item',
                    url: '/sms-promotions',
                    // icon: CampaignIcon,
                    // breadcrumbs: true,

                },
                {
                    id: 'Whatsapp Promotions',
                    title: <FormattedMessage id="Whatsapp Promotion" />,
                    type: 'item',
                    url: '/whatsapp-promotions',
                    // icon: CampaignIcon,
                    // breadcrumbs: true,

                },

            ]
        }]
}