import { Box, Checkbox, Divider, Grid, IconButton, LinearProgress, MenuItem, Modal, Paper, Select, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBar from './Navbar'
import ProductsList from './ProductsList'
import { useDispatch, useSelector } from 'react-redux'
import ProductCard2 from './ProductCard2'
import { Button, Menu, TextField } from '@material-ui/core'
import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation'
import { ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import ShopServer from './ShopServer'
import { IconX } from '@tabler/icons'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { updateCartData, updateRewardProducts } from '../../store/Actions/userCartActions'
import ShopLoader from './ShopLoader'
import RewardProductCard, { RewardProductCard2 } from './RewardProductCard'


const styles = {
    activeBtn: {
        backgroundColor: '#94c100',
        color: 'white',
        "& :hover": {
            backgroundColor: '#94c100',
        }
    },
    inActiveBtn: {
        border: '1px solid #94c100',
        color: '#94c100'
    }

}
let skipKeys = ['_id', 'lat', 'lng', 'isPrimary', 'isActive', 'alternatePhoneNumber']

const AddressModal = ({ sellerAddresses, open, handleClose, handleSelectAddress, selectedAddress, openNewAddressform, handleNewAddressForm, handleSaveNewAddress }) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }} p={2}>
                {openNewAddressform ?
                    <Grid item container justifyContent={'center'} sx={{ background: 'white', padding: '20px 0px' }} gap={1}>
                        <Grid item container justifyContent={'space-between'} xs={11} lg={11}>
                            <span> </span>
                            <Typography>New Address</Typography>
                            <IconX onClick={handleClose} />
                        </Grid>
                        <Grid container item xs={12} md={12} gap={1} justifyContent={'center'}>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="address1"
                                    label="Address Line 1"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="address2"
                                    label="Address Line 2"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="landmark"
                                    label="Landmark"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="postalCode"
                                    label="Postal Code"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="alternatePhoneNumber"
                                    label="Alternate PhoneNumber"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="city"
                                    label="City"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <TextField
                                    fullWidth
                                    name="state"
                                    label="State"
                                    onChange={handleNewAddressForm}

                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <Button onClick={handleSaveNewAddress} fullWidth variant='contained'>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid item container justifyContent={'center'} sx={{ background: 'white', padding: '20px 0px' }} gap={1}>
                        <Grid item container justifyContent={'space-between'} xs={11} lg={11}>
                            <span> </span>
                            <Typography>Saved Address</Typography>
                            <IconX onClick={handleClose} />
                        </Grid>
                        {sellerAddresses && sellerAddresses?.map((adress, addindex) =>
                            <Grid container item xs={11} lg={11} key={addindex} sx={{ boxShadow: '0px 2px 6px lightgray', background: selectedAddress?._id == adress?._id ? '#94c10060' : 'white', color: 'black' }} p={1} onClick={() => handleSelectAddress(adress)} >
                                <Typography >
                                    {Object.keys(adress).length > 0 && Object.keys(adress).map((add) => skipKeys.includes(add) ? null : adress[add] + " , ")}
                                    {selectedAddress?._id == adress?._id ? <Checkbox defaultChecked /> : null}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                }
            </Grid>
        </Modal>
    )

}


export default function UserCartPage() {

    const history = useHistory()
    const dispatch = useDispatch()
    const [isSelected, setIsSelected] = useState('HOME')

    // const productsInCart = useSelector((state) => state?.userCart?.products)
    const agentId = useSelector((state) => state?.agentInfo?.adminData?._id)
    // const [paymentMethod, setPaymentMethod] = useState(1)

    const [totalBill, setTotalBill] = useState(0)

    const addressTypeButtons = ['HOME', 'WORK', "OTHER"]

    const [productsInCart, setProductsInCart] = useState([])
    const [sellerAddresses, setSellerAddresses] = useState([])
    const [productsData, setProductsData] = useState([])
    const [selectedAddress, setSelectedAddress] = useState({})
    const [openNewAddressform, setOpenNewAddressform] = useState(false)
    const [shippingMode, setShippingMode] = useState('SHIPROCKET')
    const [paymentMode, setPaymentMode] = useState('CASH')
    const [newAddress, setNewAddress] = useState({

    })
    const productsFromCart = useSelector((state) => state?.userCart?.products)
    const rewardProductsFromRedux = useSelector((state) => state?.userCart?.rewardProducts)

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingBreakup, setLoadingBreakup] = React.useState(false);
    const [packagingData, setPackagingData] = React.useState({});

    const handleOpen = () => {
        setOpenNewAddressform(false)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);


    const sellerDataRedux = useSelector(state => state?.agentInfo?.sellerData)
    const handleNewAddress = () => {
        setOpen(true)
        setOpenNewAddressform(true)
    }

    const getOrderBreakupDetails = (products) => {
        setLoadingBreakup(true)
        let finalObj = {
            products: products,
            paymentMethod: 1,
            rewardProducts: rewardProductsFromRedux || undefined,
            agentId: agentId || undefined,
            appVersion: 1000
        }
        ShopServer.post('products/createProductOrderV4', finalObj)
            .then((res) => {
                setProductsData(res?.data?.data || [])
                setLoadingBreakup(false)

            })
            .catch((err) => {
                console.log('eer', err)
                setLoadingBreakup(false)
            })
    }

    // const getProductsInCart = () => {
    //     setIsLoading(true)
    //     ShopServer.post('/products/getSellerLatestCart')
    //         .then((res) => {
    //             setIsLoading(false)
    //             if (res.data.success) {

    //                 // console.log('seller cart', res.data)
    //                 let productsfCart = res?.data?.data?.products
    //                 let data = res?.data?.data
    //                 setProductsInCart(productsfCart || [])
    //                 getOrderBreakupDetails(productsfCart)
    //                 dispatch(updateCartData(data))

    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err)
    //             setIsLoading(false)

    //         })
    // }

    const getAlladdresses = () => {
        ShopServer.get('/seller/alladdresses')
            .then((res) => {
                if (res.data.success) {
                    if (res?.data?.data) {
                        setSellerAddresses(res?.data?.data?.[0]?.addresses)
                    }
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }
    const getPrimaryPackaging = () => {
        let ids = productsFromCart && productsFromCart.map((item) => ({ id: item?.productId }))
        ShopServer.post('/products/getPrimaryPackaging', { products: ids })
            .then((res) => {
                if (res.data.success) {
                    let obj = {}
                    let pack = res?.data?.data
                    if (pack.length) {
                        for (let i of pack) {
                            obj[i.id] = i.primaryPackaging
                        }
                        setPackagingData(obj)
                    }
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }
    const handleSaveAddressToV4 = (orderId, adId) => {
        ShopServer.post('/products/addProductOrderAddress', {
            orderId: orderId,
            addressId: adId,
        }).then((res) => {
            // console.log('res after add update in order', res.data)

        }).catch((err) => console.log(err))
    }
    const handleSaveNewAddress = () => {
        ShopServer.post('/seller/addNewAddress', {
            ...newAddress,
            isPrimary: true,
            name: sellerDataRedux?.name,
        })
            .then((res) => {
                // console.log('res after address save', res.data)
                setSelectedAddress({
                    ...newAddress,
                    _id: res?.data?.data?.addressId
                })
                getAlladdresses()
                setNewAddress({})
                setOpen(false)
                setOpenNewAddressform(false)
                handleSaveAddressToV4(productsData?._id, res?.data?.data?.addressId)
            })
            .catch((err) => {
                console.log('eer', err)
            })
    }
    const handlePaymentAndDeliveryMode = () => {
        ShopServer.post('/seller/addPaymentAndShippingModeInOrder', {
            orderId: productsData?._id,
            paymentMode: paymentMode,
            shippingMode: shippingMode
        }).then((res) => {
            if (res.data.success) {
                // console.log('res after payment mode update', res.data)
                history.push(`/shopping/order-successful/${productsData?._id}`)
            } else {
                alert(res?.data?.data?.message || 'Something went wrong !')
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    const handlePlaceOrder = () => {
        if (selectedAddress?._id != null || selectedAddress?._id != undefined) {

            let orderObj = {
                orderId: productsData?._id,
                orderAmount: productsData.totalPayableAmount,
                paymentMethod: 1,
                source: 'INORGANIC'

            }
            ShopServer.post('/products/captureProductOrder', orderObj)
                .then((res) => {
                    if (res.data.success) {
                        // console.log("res after order Capture", res.data)
                        handlePaymentAndDeliveryMode()
                        history.push(`/shopping/order-successful/${productsData?._id}`)
                    } else {
                        alert(res?.data?.data?.message || 'Please refresh and try again !')
                    }
                })
                .catch((err) => {
                    console.error(err)
                    alert('Something went wrong !')

                })
        } else {
            Swal.fire({
                icon: 'info',
                text: 'Please Select Address first !'
            })
        }
    }

    useEffect(() => {
        // getProductsInCart()
        getAlladdresses()
    }, [])
    useEffect(() => {
        // getProductsInCart()
        if (productsFromCart.length > 0) {
            getOrderBreakupDetails(productsFromCart)
            getPrimaryPackaging()
            // console.log('productsFromCart', productsFromCart)
        }
    }, [productsFromCart])
    const handleSelectAddress = (addddd) => {
        setSelectedAddress(addddd)
        handleSaveAddressToV4(productsData?._id, addddd?._id)
        setOpenNewAddressform(false)
        setOpen(false)
    }

    const handleNewAddressForm = (e) => {
        setNewAddress((prev) => ({
            ...prev,
            phoneNumber: sellerDataRedux?.phoneNumber,
            [e.target.name]: e.target.value
        }))
    }

    const handleRemoveRewardProduct = (product) => {
        let fiteredProduct = rewardProductsFromRedux.filter((item) => item?.rewardProductId != product?.rewardProductId)

        // console.log('fiteredProduct', fiteredProduct)
        dispatch(updateRewardProducts(fiteredProduct))
    }

    return (
        <div style={{ backgroundColor: 'white', padding: '0px 10px', borderRadius: 4, }}>

            <Grid item container style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 150, left: 0, width: '100%', }}>
                <Grid item container xs={12} lg={12} sm={12} md={12} mt={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Link to="/shopping">
                        <IconButton color='primary'>
                            <ArrowBack style={{ color: 'black' }} />
                        </IconButton>
                    </Link>
                    <Typography variant='h3' >My Cart ( {productsFromCart && productsFromCart.length} Products )</Typography>
                    <span></span>
                </Grid>
            </Grid>
            {productsFromCart && productsFromCart?.length > 0 ? <Grid container >
                <Grid item container xs={12} lg={6} sm={6} md={6} mt={2} gap={1}>
                    {
                        productsData?.products?.map((prod) => <ProductCard2 product={prod} loadingBreakup={loadingBreakup} primaryPackaging={packagingData[prod?.productId] ?? 0} productsFromCart={productsFromCart} productsData={productsData?.products} />)
                    }
                </Grid>
                {rewardProductsFromRedux && rewardProductsFromRedux.length > 0 ? <Grid item container xs={12} lg={6} sm={6} md={6} mt={2} >
                    <Typography variant='h3' textAlign={'center'} p={2} style={{ width: '100%' }}>Free gifts</Typography>
                    {
                        rewardProductsFromRedux?.map((prod) => <RewardProductCard2 product={prod} handleRemoveRewardProduct={handleRemoveRewardProduct} />)
                    }
                </Grid>
                    : null}
                <Grid item xs={12} lg={6} sm={6} md={6} container justifyContent={'center'} alignItems={'flex-start'} p={1}>
                    <div style={{ position: 'sticky', top: 0, right: 15, zIndex: 110, marginTop: '2vw', width: "100%", maxWidth: 370 }}>
                        <Grid item container gap={1}>
                            <Grid item container justifyContent={'space-between'}>
                                <Typography variant='h4'>Select Delivery Address</Typography>

                                {sellerAddresses && sellerAddresses.length > 0 ? <Button size='small' color='secondary' variant='contained' onClick={handleOpen}>SELECT</Button> : null}
                            </Grid>
                            <Grid container item xs={12} lg={12} gap={1}>
                                <AddressModal
                                    sellerAddresses={sellerAddresses}
                                    open={open}
                                    handleClose={handleClose}
                                    selectedAddress={selectedAddress}
                                    handleSelectAddress={handleSelectAddress}
                                    openNewAddressform={openNewAddressform}
                                    handleNewAddressForm={handleNewAddressForm}
                                    handleSaveNewAddress={handleSaveNewAddress}
                                />

                                {
                                    selectedAddress && <Typography >
                                        {Object.keys(selectedAddress).length > 0 && Object.keys(selectedAddress).map((add) => skipKeys.includes(add) ? null : selectedAddress[add] + " , ")}
                                    </Typography> || null
                                }
                                <Button size='small' fullWidth variant='outlined' onClick={handleNewAddress}>Add New Address</Button>

                            </Grid>
                            {/* <Grid item xs={12} lg={12} container gap={1}>
                                {
                                    addressTypeButtons.map((btn, index) => btn == isSelected ? <Button variant='contained' style={styles.activeBtn} size='small' key={index}>{btn}</Button> : <Button variant='outlined' key={index} size='small' style={styles.inActiveBtn} onClick={() => setIsSelected(btn)}>{btn}</Button>)
                                }
                            </Grid> */}
                        </Grid>
                        <Grid item container letterSpacing={2} mt={2} gap={1}>
                            <Grid item xs={12} lg={12} >
                                <Typography variant='h4'>PRICE DETAILS</Typography>
                                <br />
                                <Divider />
                            </Grid>
                            {loadingBreakup ?
                                <div style={{ padding: 10, width: '100%' }}>

                                    <Skeleton style={{ width: '100%', height: 30 }} />
                                    <Skeleton style={{ width: '100%', height: 30 }} />
                                    <Skeleton style={{ width: '100%', height: 30 }} />
                                    <Skeleton style={{ width: '100%', height: 30 }} />
                                </div>
                                : <>
                                    <Grid item xs={12} lg={12} container spacing={2}>
                                        <Grid item xs={6} lg={6} container>
                                            <Select size='small' value={shippingMode} onChange={(e) => setShippingMode(e.target.value)}>
                                                <MenuItem value="SHIPROCKET"> SHIPROCKET</MenuItem>
                                                <MenuItem value="ROCKETBOX"> ROCKET BOX</MenuItem>
                                                <MenuItem value="PORTER">PORTER</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6} lg={6} container>
                                            <Select size='small' value={paymentMode} onChange={(e) => setPaymentMode(e.target.value)}>
                                                <MenuItem value="CASH"> CASH</MenuItem>
                                                <MenuItem value="RTGS"> RTGS</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent={'space-between'} pt={2} >
                                        <Typography > MRP :  </Typography>
                                        <Typography > {" ₹ " + numericCommaSeparation((productsData?.totalMrpWithQty || 0).toFixed(2))} </Typography>
                                    </Grid>
                                    {productsData && productsData?.discountBreakupInfo1?.map((prr, index) => <Grid item container key={index} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography color={'green'} sx={{ maxWidth: '50%' }}> {Object.keys(prr).toString()} :  </Typography>
                                        <Typography color={'green'}> {"- ₹ " + numericCommaSeparation(Number(Object.values(prr).toString() || 0).toFixed(2))} </Typography>
                                    </Grid>)}


                                    {productsData?.totalPayableAmount > 499 ? null : <Grid item container justifyContent={'space-between'} >
                                        <Typography > Delivery Charge  :  </Typography>
                                        <Typography > {" ₹ 50"} </Typography>
                                    </Grid>}
                                    <Grid item container justifyContent={'space-between'} >
                                        <Typography variant='h4'> TOTAL :  </Typography>
                                        <Typography variant='h4'> {" ₹ " + numericCommaSeparation((productsData?.totalPayableAmount || 0).toFixed(2))} </Typography>
                                    </Grid>
                                </>}

                        </Grid>
                        <Grid item xs={12} lg={12} sm={12} md={12} mt={2} >
                            <Button
                                disabled={loadingBreakup}
                                onClick={handlePlaceOrder}
                                variant='contained'
                                fullWidth sx={{ background: '#94c100', ':hover': { background: '#94c100' } }}>
                                Checkout
                            </Button>

                        </Grid>

                    </div>
                </Grid>
            </Grid> : isLoading ? <ShopLoader title={'Product in Cart'} linerLoading /> : <Typography variant='h2' textAlign={'center'} pt={10}>No Products in your cart !</Typography >}

        </div>
    )
}
