import React, { useEffect } from 'react'
import ShopServer from './ShopServer';
import { useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Navbar from './Navbar'
import { drawerCoins } from '../../assets/images';
import dayjs from 'dayjs';
import RewardProductCard from './RewardProductCard';
import { IconX } from '@tabler/icons';
import ImageIcon from '../../layout/Customization/ImageIcon';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { addRewardProduct } from '../../store/Actions/userCartActions';
import { Link } from 'react-router-dom';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    // backgroundColor: 'lightgray',
    border: 'none',
    boxShadow: 24,
    p: 2,
    borderRadius: 4
};


export default function CoinShop() {
    const [earnedCoins, setEarnedCoins] = useState(0)
    const [spendLimit, setSpendLimit] = useState(0)
    const [monthlySpendLimit, setMonthlySpendLimit] = useState(0)
    const [limitRenewalDate, setLimitRenewalDate] = useState('')
    const [rewardProducts, setRewardProducts] = useState([])
    const [selectedProduct, setselectedProduct] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [page, setPage] = useState(1)
    const [netCoins, setNetCoins] = useState(0);

    const dispatch = useDispatch()

    const rewardProductsInCart = useSelector((state) => state?.userCart?.rewardProducts)
    let totalCoinsOfRewardsProducts = rewardProductsInCart && rewardProductsInCart?.reduce((acc, cur) => acc += cur.quantity * cur.rewardProductCoins, 0) || 0

    const fetchCoins = () => {
        ShopServer.get(`/seller/getSellerCoins`).then(res => {
            setEarnedCoins(res.data.data);
            setNetCoins(res.data.data - totalCoinsOfRewardsProducts);
        }).catch(err => console.log(err));
    };
    const fetchSpendLimit = () => {
        ShopServer.get(`/seller/getCoinsMonthLimit`).then(res => {
            const diff = res.data.data.spendLimitMonthly - res.data.data.monthCoinSpendBucket
            setSpendLimit(diff);
            setMonthlySpendLimit(res.data.data.spendLimitMonthly);
            setLimitRenewalDate(res.data.data.spendRenewalDate);

        }).catch(err => console.log('error while fetching spend limit==>', err));
    }
    const fetchRewardProducts = (search) => {
        ShopServer.get(`/products/getRewardProductsForApp?searchQuery=${search}`).then(res => {
            setRewardProducts(res.data.data);
            //   setLoaded(true);
        }).catch(err => console.log(err));
    };
    useEffect(() => {
        fetchCoins()
        fetchSpendLimit()
        fetchRewardProducts('')
    }, [])
    const handleOpenPopup = (product) => {
        setselectedProduct(product)
        setOpenModal(true)
    }
    const addtoCartHandler = (item) => {

        console.log('item', item)
        if (item?.coinsRequired > earnedCoins) {
            Swal.fire({
                icon: 'error',
                text: 'Earn more coins to purchase this product.'
            });
            return;
        }
        let projectObj = {
            EANCode: item?.EANCode,
            image: item.imageUrl,
            mrp: item.mrp,
            quantity: 1,
            reversed: item?.reversed || false,
            rewardProductCoins: item.coinsRequired,
            rewardProductId: item.productId,
            rewardProductName: item.name
        }

        if (item?.coinsRequired > netCoins) {
            Swal.fire({
                text: 'Earn more coins to purchase this product.',
                icon: 'error'
            })
            return;
        }
        if (item?.coinsRequired > spendLimit - totalCoinsOfRewardsProducts) { //condition to check if coins spend limit has reached
            Swal.fire({
                icon: 'error',
                text: `Your monthly spend limit of this product is ${monthlySpendLimit ? monthlySpendLimit : 'reached'}`
            })
            return;
        }
        ShopServer.post(`/seller/checkMonthlyRewardProductOrderLimit`, { //condition to check if quanity limit of specific product has reached
            rewardProduct: {
                rewardProductId: item.productId,
                rewardProductName: item.name,
                rewardProductCoins: item.coinsRequired,
                quantity: 1,
            }
        }).then(res => {
            const data = res.data.data;
            if (data.quantity > (data.monthlyLimit - data.sellerMonthOrderedQty)) {
                Swal.fire({
                    icon: 'error',
                    text: `Your monthly purchase limit of this product is ${data.monthlyLimit ? data.monthlyLimit : 'reached'}`
                })
            } else {
                dispatch(addRewardProduct(projectObj))
            }
        }).catch(err => console.log('error in checking order===>', err));
    }
    return (<Grid container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: 500, margin: 'auto' }}>
        <Grid item container xs={12} lg={12}>
            <Navbar />
        </Grid>
        <Grid item xs={12} lg={12} container style={{ background: 'whitesmoke' }} alignItems={'flex-start'} justifyContent={'center'} >
            <Grid item xs={12} lg={12} md={12} sm={12} container p={2} borderRadius={2} gap={1} m={2}
                justifyContent='space-between' alignItems={'center'} style={{ backgroundColor: 'white' }}>
                <Grid item container>
                    <Grid xs={6} lg={6} item container alignItems={'center'}>
                        <Typography variant='h4'>Your Total Coins</Typography>
                    </Grid>
                    <Grid xs={6} lg={6} item container alignItems={'center'} justifyContent={'flex-end'}>
                        <img src={drawerCoins} height={25} style={{ paddingTop: 4 }} />
                        <Typography variant='h3'>    {earnedCoins}</Typography>
                    </Grid>
                </Grid>
                <Typography variant='caption'>Spend your coins to get free gifts.</Typography>
                <Grid item container>
                    <Link to='/shopping/coins-history'>
                        <Button variant='contained' sx={{ borderRadius: 8 }} size="small">Coin History</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} container p={1} borderRadius={8} gap={1} border='1px solid lightgray'
                justifyContent='space-between' alignItems={'flex-start'} style={{ backgroundColor: 'white' }}>
                <Grid item container p={1}>
                    <Grid xs={6} lg={6} item >
                        <Typography variant='caption' color={'black'}>This Month Spending</Typography>
                        <Typography variant='h4'>{spendLimit}</Typography>

                    </Grid>
                    <Grid xs={6} lg={6} item container justifyContent={'flex-end'} >
                        <div>
                            <Typography variant='caption' color={'black'}>Limit Renewal date</Typography>
                            <Typography variant='h4'>{dayjs(limitRenewalDate).format('DD MMM YYYY')}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container>
                    {
                        rewardProducts && rewardProducts?.map((reward, rIndex) => reward?._id.type === 'physical' && reward?.products?.length > 0 ? <Grid container key={rIndex}>
                            {(reward?.products.length == 1 && reward?.products[0].available == false) ? null :
                                <Grid item xs={12} lg={12} textAlign={'center'} style={{ background: 'yellow' }} borderRadius={4} mt={1}>
                                    <Typography variant='h3'>{reward?._id?.categoryName}</Typography>
                                </Grid>}
                            <Grid item xs={12} lg={12} container spacing={1} mt={1}>
                                {
                                    reward?.products?.slice(0, page * 10).map((prod, PIndex) => prod?.available && <Grid item xs={4} lg={4} md={4} key={PIndex} sm={4} onClick={() => handleOpenPopup(prod)}><RewardProductCard product={prod} /> </Grid>)
                                }
                            </Grid>
                            <Grid item xs={12} lg={12} container mt={1} justifyContent={'center'}>

                                {reward?.products?.length / page >= page * 10 ? <Button onClick={() => {
                                    setPage((prev) => prev + 1)
                                }}>View More</Button> : null}
                            </Grid>





                            <Modal
                                open={openModal}
                                onClose={() => setOpenModal(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            // style={{ backgroundColor: 'none' }}
                            >
                                <Box sx={style}>
                                    <Grid container justifyContent={'center'} gap={1}>
                                        <Grid item xs={12} lg={12} container justifyContent={'space-between'} alignItems={'center'}>
                                            <span></span>
                                            <Typography variant='h4'>Your Gift Details</Typography>
                                            <IconButton size='small' onClick={() => {
                                                setOpenModal(false)
                                                setselectedProduct({})
                                            }}><IconX /></IconButton>
                                        </Grid>
                                        <Grid item xs={11} lg={11} margin={'auto'} container justifyContent={'center'} border={'1px solid lightgray'} pb={2} borderRadius={3}>
                                            <img src={selectedProduct?.imageUrl} height={150} width={150} style={{ objectFit: 'contain', mixBlendMode: 'darken' }} />
                                            <Grid item xs={10} lg={10} container justifyContent={'center'} flexDirection={'column'} mt={2} >
                                                <Typography variant='h4' lineHeight={1} textAlign={'center'}>{selectedProduct?.name}</Typography>
                                                <Typography variant='caption' fontSize={12} textAlign={'center'}  >MRP : <del>₹ {selectedProduct?.mrp}</del> </Typography>
                                                <Grid item container justifyContent={'center'} alignItems={'center'}>
                                                    <ImageIcon url={drawerCoins} size="medium" />
                                                    <Typography variant='h3'>{selectedProduct?.coinsRequired}</Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={11} md={11} container mt={2} >
                                            {rewardProductsInCart?.filter((item) => item.rewardProductId == selectedProduct.productId).length > 0 ?
                                                <Link to="/shopping/my-cart" style={{ width: '100%' }}>

                                                    <Button variant='contained' fullWidth
                                                        sx={{
                                                            background: '#94c100', ':hover': {
                                                                background: '#94c100'
                                                            }
                                                        }}> GO TO CART</Button>
                                                </Link>
                                                :
                                                <Button variant='contained' fullWidth
                                                    onClick={() => addtoCartHandler(selectedProduct)}
                                                    sx={{
                                                        background: '#94c100', ':hover': {
                                                            background: '#94c100'
                                                        }
                                                    }}> ADD TO CART</Button>}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Modal>
                        </Grid> : null)
                    }
                </Grid>
            </Grid>

        </Grid>
    </Grid>
    )
}
