import React, { useEffect, useState } from 'react';
import MainCard from '../../ui-component/cards/MainCard';
import Grid from '@mui/material/Grid';
import { TableHead, Typography, Table, TableCell, TableBody, TableContainer, TableRow, Avatar, } from '@material-ui/core';
// import Switch from '@mui/material/Switch';
// import Chip from '@mui/material/Chip';
import {
    IconSearch,
    // IconEye,
} from "@tabler/icons";
// import employee1 from '../../assets/images/users/avatar-1.png'

import '../EmployeeHome/EmployeeStyle.module.css';
// import FormControlLabel from '@mui/material/FormControlLabel';

import { Link } from 'react-router-dom';

// import {
//     IconUsers, IconDeviceMobile, IconClockHour8, IconEdit,
//     // IconUserMinus
// } from "@tabler/icons";
// import IconButton from '@mui/material/IconButton'
import axios from "axios"

import AddClientForm from './AddClientForm';
import SidebarComponent from '../SidebarComponent'
import { TablePagination } from '@material-ui/core';
import GenerateAvatar from '../../layout/Customization/GenerateAvatar';
import { customer_blank } from '../../assets/images';
import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation';
import { logFirebaseEvent } from '../../LogFirebaseEvent';
// const data =
// {
//     name: "Adil",
//     doj: "22-01-2022",
//     designation: "Hair Dresser",
//     revenue: "20,000",
//     shiftStartTime: "10:00AM",
//     shiftEndTime: "12:00",
//     commissionService: "10 %",
//     phoneNumber: "9177549852",
//     avatar: employee1,
//     ratio: "12.04%",
//     customerPhoneNumber: "9211547892"


// }

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function EmployeeHome() {

    // const [isToggle, setIsToggle] = useState(true);
    // const [openForm, setOpenForm] = useState(false)
    const [customerList, setCustomerList] = useState([])
    const [copyList, setCopyList] = useState([])
    const [selectedUser, setSelectedUser] = useState([])
    const [openAddClientForm, setOpenAddClientForm] = useState(false)
    const [actionPage, setActionPage] = useState("")

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPage, setTotalPage] = useState(0);

    // const handleChange = () => {
    //     setIsToggle(!isToggle)
    // }

    const getCustomerList = (pageNo, limit, searchTerm) => {
        axios.get(`${process.env.REACT_APP_PATH}/sellerCrm/customers/getCustomerList?page=${pageNo + 1}&limit=${limit}&search=${searchTerm ?? ""}`)
            .then(res => {
                if (res.data.success) {
                    // console.log(res.data.data.data, "customer list")
                    if (res.data.data.data?.length > 0) {
                        setCustomerList(res.data.data.data)
                        setCopyList(res.data.data.data)
                        setTotalPage(res.data.data.metaData)
                    }
                }
            })
            .catch(err => console.log(err))
    }

    const handleSearchCustomer = (e) => {
        logFirebaseEvent('search in customer list page')
        getCustomerList(page, rowsPerPage, e.target.value)
    }
    useEffect(() => {
        getCustomerList(page, rowsPerPage)
        logFirebaseEvent('customer list page')
    }, [])

    const handleChangePage = (event, newPage) => {
        // console.log("newPage", newPage)
        setPage(newPage);
        getCustomerList(newPage, rowsPerPage)


    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0)
        // setPage(event.target.value);
        getCustomerList(0, event.target.value)
    };

    return (
        <MainCard>

            <SidebarComponent
                openSidebar={openAddClientForm}
                setOpenSidebar={setOpenAddClientForm}
                selectedUser={""}
                component={<AddClientForm actionPage={actionPage}
                    selectedUser={selectedUser}
                    setOpenAddClientForm={setOpenAddClientForm}
                    getCustomerList={getCustomerList}
                />}
                title={actionPage} />
            <Grid item lg={4} xs={12}></Grid>
            <Grid container justifyContent="space-between" gap={1} alignItems={'center'}>
                <Grid item lg={2} md={2} sm={2}>
                    <Typography variant='h3'>Clients</Typography>
                </Grid>

                {/* <Grid item container lg={2} md={4} sm={6} xs={12} >
                    <form>
                        <Grid item >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isToggle}
                                        onChange={handleChange}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label="Send to All?" />
                        </Grid>
                    </form>
                </Grid> */}

                <Grid item lg={3} md={4} sm={5} xs={12} style={{ border: "1px solid lightGray", display: "flex", alignItems: "center", borderRadius: 5, padding: 5, }} >
                    <input onChange={handleSearchCustomer} style={{ width: "100%", border: "none", outline: "none" }} placeholder='Search Customer' />
                    <IconSearch />
                </Grid>
                {/* <Grid item container lg={2} md={4} sm={4} xs={4} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <Grid item>
                        <button style={{ borderRadius: 5, border: "1px solid lightGray", padding: 5 }}
                            onClick={(e) => { console.log("clciked") }}><IconUsers size={20} style={{ color: "grey", cursor: "pointer" }} /></button>
                    </Grid>
                    <Grid item>
                        <button
                            style={{ borderRadius: 5, border: "1px solid lightGray", cursor: "pointer", padding: 5 }}
                            onClick={(e) => { console.log("clciked") }} >
                            <IconDeviceMobile size={20} style={{ color: "grey" }} /></button>
                    </Grid>
                    <Grid item>
                        <button
                            style={{ borderRadius: 5, border: "1px solid lightGray", cursor: "pointer", padding: 5 }}
                            onClick={(e) => { console.log("clciked") }} >
                            <IconClockHour8 size={20} style={{ color: "grey" }} />
                        </button>
                    </Grid>
                </Grid> */}

                {/* <Grid item lg={2} sm={6} xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenAddClientForm(true)
                            setActionPage("+Add New Client")
                        }}
                        style={{ backgroundColor: "#002A56", color: "white", cursor: "pointer" }}>
                        +Add Client
                    </Button>
                </Grid> */}

            </Grid>

            {customerList && customerList.length > 0 ? <TableContainer style={{ marginTop: 15 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: 6 }}>
                                {/* <Typography ml={3}> */}
                                Name
                                {/* </Typography> */}
                            </TableCell>
                            <TableCell >Email</TableCell>
                            <TableCell align='center'>Revenue(Monthly)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerList.map((item, index) => {
                            return <TableRow key={index}>
                                <TableCell >
                                    <Grid container alignItems="center" >
                                        <Avatar sx={{ display: { xs: 'none', lg: 'block', sm: 'none', md: 'block' } }}>
                                            {item?.name ? <GenerateAvatar string={(item.name).toUpperCase()} /> : ''}
                                        </Avatar>
                                        <span style={{ paddingLeft: 5 }}>
                                            <Link onClick={() => {
                                                logFirebaseEvent('customer profile from customer list page')
                                            }}
                                                style={{ textDecoration: "none", color: "black", fontWeight: "bold", textTransform: 'capitalize', fontFamily: 'Lato-Regular' }}
                                                to={`/customerProfile/${item._id}`}
                                            >
                                                {item.name}{item?.gender ? `(${item.gender.split("")[0]})` : ''}
                                            </Link>
                                            <Typography>
                                                {item.phoneNumber}
                                            </Typography>
                                        </span>
                                    </Grid>
                                </TableCell>
                                <TableCell >
                                    {item?.emailId || <i style={{ color: 'lightgrey' }}>email id not found !</i>}
                                </TableCell>
                                <TableCell align='center' >
                                    {numericCommaSeparation(Math.round(item?.revenue || 0))}/-
                                </TableCell>
                                {/* <TableCell align="center">
                                    <Chip label="Silver" style={{ background: "linear-gradient(90deg, #B5B5B5 0%, #E8E8E8 52.08%, #B3B3B3 100%)" }} />
                                </TableCell> */}

                                {/* <TableCell style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Edit Profile" >
                                        <IconButton color="primary" onClick={() => {
                                            setOpenAddClientForm(true)
                                            setSelectedUser(item)
                                            setActionPage("Update Client Profile")
                                        }}> <IconEdit size={20} /></IconButton>
                                    </Tooltip>
                                </TableCell> */}

                            </TableRow>
                        })
                        }

                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalPage || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer> :
                <Grid container justifyContent={'center'} alignItems="center" p={2}>
                    <img src={customer_blank} height={500} />
                </Grid>

            }
            {customerList && customerList.length > 0 ? <Grid container justifyContent={'center'}>
                <Typography textAlign={'center'} variant="h5" color={'green'} style={{ backgroundColor: 'whitesmoke', border: '1px solid black', padding: "5px 15px", borderRadius: 10 }}>Page : {page + 1}</Typography>

            </Grid> : ''}

            {/* customer_blank */}
        </MainCard >
    )
}
