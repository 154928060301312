var colors = {
    A: "#1abc9c", B: "#2ecc71",
    C: "#3498db", D: "#9b59b6",
    E: "#34495e", F: "#16a085",
    G: "#27ae60", H: "#7f8c8d",
    I: "#2980b9", J: "#8e44ad",
    K: "#2c3e50", L: "#f1c40f",
    M: "#e67e22", N: "#e74c3c",
    O: "#95a5a6", P: "#f39c12",
    Q: "#d35400", R: "#c0392b",
    S: "#85898c", T: "#8e44ad",
    U: "#7f8c8d", V: "#2c3e50",
    W: "#e74c3c", X: "#3498db",
    Y: "#1abc9c", Z: "#f39c12"
}
var backgroundColors = {
    A: "#1abc9c45", B: "#2ecc7145",
    C: "#3498db45", D: "#9b59b645",
    E: "#34495e45", F: "#16a08545",
    G: "#27ae6045", H: "#7f8c8d45",
    I: "#2980b945", J: "#8e44ad45",
    K: "#2c3e4545", L: "#f1c40f45",
    M: "#e67e2245", N: "#e74c3c45",
    O: "#95a5a645", P: "#f39c1245",
    Q: "#d3540045", R: "#c0392b45",
    S: "#64a5b045", T: "#8e44ad45",
    U: "#7f8c8d45", V: "#2c3e4545",
    W: "#e74c3c45", X: "#3498db45",
    Y: "#1abc9c45", Z: "#f39c1245"
    // ,
    // 0: "#2c3e4545", 1: "#f1c40f45",
    // 6: "#e67e2245", 2: "#e74c3c45",
    // 5: "#95a5a645", 3: "#f39c1245",
    // 7: "#d3540045", 4: "#c0392b45",
    // 8: "#34495e45", 9: "#16a08545",

}
var backgroundColors = {
    A: "#1abc9c45", B: "#2ecc7145",
    C: "#3498db45", D: "#9b59b645",
    E: "#34495e45", F: "#16a08545",
    G: "#27ae6045", H: "#7f8c8d45",
    I: "#2980b945", J: "#8e44ad45",
    K: "#2c3e4545", L: "#f1c40f45",
    M: "#e67e2245", N: "#e74c3c45",
    O: "#95a5a645", P: "#f39c1245",
    Q: "#d3540045", R: "#c0392b45",
    S: "#64a5b045", T: "#8e44ad45",
    U: "#7f8c8d45", V: "#2c3e4545",
    W: "#e74c3c45", X: "#3498db45",
    Y: "#1abc9c45", Z: "#f39c1245"


}


let bgColors1 = {
    "A": "#4cf09d", "B": "#c188a2",
    "C": "#67eb4b", "D": "#b308d3",
    "E": "#fc7e41", "F": "#af3101",
    "G": "#ff065", "H": "#71b1f4",
    "I": "#a2f8a5", "J": "#e23dd0",
    "K": "#d3486d", "L": "#00f7f9",
    'M': "#474893", "N": "#3cec35",
    "O": "#1c65cb", "P": "#5d1d0c",
    "Q": "#2d7d2a", "R": "#ff3420",
    "S": "#5cdd87", 'T': "#a259a4",
    "U": "#e4ac44", 'V': "#1bede6",
    'W': "#8798a4", 'X': "#d7790f",
    'Y': "#b2c24f", 'Z': "#de73c2",
}
export { colors, backgroundColors, bgColors1 }
