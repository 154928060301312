import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { buyumeLogo, Slide_1 } from '../../assets/images'



export default function Slide1() {

    return (
        <Grid container justifyContent={'space-between'} gap={2} style={{ width: '100%' }}>
            <Grid item lg={12} xs={12} md={12} sm={12} pt={6} container alignItems={'center'} flexDirection="column">
                <Typography sx={{ fontSize: 23 }} color="black" >Welcome to</Typography>
                <img src={buyumeLogo} alt="buyumeLogo" width={80} style={{ marginTop: 10 }} />
                <Typography color={'#002a5c'} variant='h3'>BEAUTY ADVISOR</Typography>
                <Typography fontSize={20} fontWeight={'lighten'} color={'#002a5c'}>Powered by AI</Typography>
                <Typography color={'#002a5c'} pt={2} pb={2}>
                    {/* For your personalised Skin and health Analysis */}
                    Your personalized skin routine is just some clicks away.
                </Typography>
            </Grid>
            <Grid container item lg={12} xs={12} md={12} sm={12} alignItems="center" justifyContent={'center'}>
                <img src={Slide_1} alt="Slide_1" width={'100%'} style={{ objectFit: 'cover' }} />

            </Grid>
        </Grid >
    )
}
