import React
    // { lazy } 
    from 'react';
import {
    Redirect,
    Route,
    // Route,
    Switch
} from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
// import Loadable from '../ui-component/Loadable';

// project imports
import config from './../config';
import SmartCamera from '../SmartCamera';
import ResultById from '../SmartCamera/ResultById';
import ShoppingHomepage from '../menu-items/Shopping/ShoppingHomepage';
import UserCartPage from '../menu-items/Shopping/UserCartPage';
import OrderSuccessful from '../menu-items/Shopping/OrderSuccessfull';
import ShopLogin from '../menu-items/Shopping/ShopLogin';
import ProductDetails from '../menu-items/Shopping/ProductDetails';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrderHistory from '../menu-items/Shopping/OrderHistory';
import CoinShop from '../menu-items/Shopping/CoinShop';
import CoinHistory from '../menu-items/Shopping/CoinHistory';
import Subscription from '../menu-items/Shopping/Subscription';
import SellerProfile from '../menu-items/Shopping/SellerProfile';
// import { Dashboard } from '@material-ui/icons';

// const PagesLanding = Loadable(lazy(() => import('../views/pages/landing')));

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    const location = useLocation()
    const history = useHistory()
    const isAgentLoggedIn = useSelector((state) => state?.agentInfo?.isSellerLoggedIn)

    useEffect(() => {
        let currentPath = location.pathname
        let currentPathArr = currentPath.split("/")
        // console.log('currentPathArr', currentPathArr)
        if (currentPath != '/shopping/login' && currentPathArr && currentPathArr[1] == 'shopping') {
            if (isAgentLoggedIn != true) {
                return history.push('/shopping/login')
            } else {
                // console.log("isAgentLoggedIn true")
            }
        }

    }, [location])
    return (
        <Switch>
            <Route exact path="/smart-camera" component={SmartCamera} />
            <Route exact path="/skin-analysis-score/:resId" component={ResultById} />
            <Route exact path="/shopping" component={ShoppingHomepage} />
            <Route exact path="/shopping/login" component={ShopLogin} />
            <Route exact path="/shopping/my-cart" component={UserCartPage} />
            <Route exact path="/shopping/order-successful/:orderID" component={OrderSuccessful} />
            <Route exact path="/shopping/product-details/:productId" component={ProductDetails} />
            <Route exact path="/shopping/order-details/:orderID" component={ProductDetails} />
            <Route exact path="/shopping/orders-history" component={OrderHistory} />
            <Route exact path="/shopping/coins-history" component={CoinHistory} />
            <Route exact path="/shopping/coin-shop" component={CoinShop} />
            <Route exact path="/shopping/my-subscription" component={Subscription} />
            <Route exact path="/shopping/seller-profile" component={SellerProfile} />


            <Redirect exact from="/" to={config.defaultPath} />
            <React.Fragment>
                {/* Routes for authentication pages */}
                <AuthenticationRoutes />

                {/* Route for login */}
                <LoginRoutes />

                {/* Routes for main layouts */}
                <MainRoutes />
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
