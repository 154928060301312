import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Badge, Button, ButtonGroup, Divider, Grid, IconButton } from '@mui/material'
import { Add, ArrowBack, Delete, ShoppingCart } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Typography } from '@material-ui/core'

import { fontSizestyles as styles } from './fontSizes'
import { discount_background_chip } from '../../assets/images'
import { calculateDiscount, calculateSellingPrice } from './calculateProductDiscount'
import { useDispatch, useSelector } from 'react-redux'
import { IconMinus } from '@tabler/icons'
import ShopServer from './ShopServer'
import { addProductToCart, addRewardProduct, descreaseProductQuantity, increaseProductQuantity, removeProductFromCart, updateCartData } from '../../store/Actions/userCartActions'
import ShopLoader from './ShopLoader'



export default function ProductDetails() {
    const { productId } = useParams()
    const [productDetails, setProductDetails] = useState([])
    const [productImages, setProductImages] = useState([])
    const dispatch = useDispatch()
    const productsInCart = useSelector((state) => state?.userCart?.products)
    const [productFromCart, setProductFromCart] = useState({})
    const isMembershipExist = useSelector((state) => state?.agentInfo?.membershipStatus)

    const [loading, setLoading] = useState(false)

    const getProductDetailsbyId = (pId) => {
        setLoading(true)
        ShopServer.get('/api/productDetailByIdV2?productId=' + pId)
            .then((res) => {
                setLoading(false)

                // console.log('res', res.data)
                if (res.data.success) {
                    setProductDetails(res?.data?.data?.products || [])
                    let pImages = res?.data?.data?.products?.images?.map((img) => ({ source: img }))
                    setProductImages(pImages)
                }
            })
            .catch((err) => {
                setLoading(false)

                console.error(err)
            }
            )
    }
    useEffect(() => {
        if (productId != null && productId != undefined) {
            getProductDetailsbyId(productId)
        }
    }, [productId])
    const handleRemoveProduct = () => {
        let productObj = {
            ...productDetails,
            quantity: 0,
            mrp: productDetails?.mrp || productDetails?.menuPrice,
            image: productDetails.image,
            productId: productDetails?._id
        }

        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        }).then((res) => {
            // if (finalQuantity == 0) {
            dispatch(removeProductFromCart(productObj))
            // console.log('productFromcart', productFromCart)
            // getProductDetailsbyId(productId)
            // }
        }).catch((err) => {
            // setLoading(false)
            console.error(err)
        })
    }
    const handleAddProduct = (prod) => {
        let productObj = {
            ...prod,
            quantity: 1,
            image: productDetails['images'][0],
            price: calculateSellingPrice(prod, isMembershipExist),
            mrp: prod?.mrp ?? prod.menuPrice,
        }
        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        })
            .then((res) => {
                // getProductsInCart()
                dispatch(addProductToCart(productObj))
            }).catch((err) => {
                alert('Something went wrong!')
                console.error(err)
            })

    }
    const handleIncreaseQuantity = () => {
        let productObj = {
            ...productFromCart,
            quantity: Number(productFromCart?.quantity || 0) + 1,
            image: productFromCart.image,

        }
        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch(increaseProductQuantity(productObj))
                }

            }).catch((err) => console.error(err))

    }
    const handleDescreaseQuantity = () => {
        let finalQuantity = 0
        if (productFromCart?.quantity > 1) {
            finalQuantity = Number(productFromCart?.quantity || 0) - 1
        } else {
            finalQuantity = 0
        }
        let productObj = {
            ...productFromCart,
            quantity: finalQuantity,
            mrp: productDetails?.mrp || productDetails?.menuPrice,
            image: productFromCart.image,
            // image: productFromCart['images'][0],
        }
        // console.log('productObj', productObj)
        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        }).then((res) => {
            // getProductsInCart()
            if (finalQuantity == 0) {
                dispatch(removeProductFromCart(productObj))
            } else {
                dispatch(descreaseProductQuantity(productObj))
            }

        }).catch((err) => console.error(err))
    }

    useEffect(() => {
        if (productsInCart && productsInCart.length > 0 && productDetails) {
            let prrrrr = productsInCart?.filter((pr1) => pr1?.productId == productDetails?.productId)
            if (prrrrr?.length > 0) {
                setProductFromCart(prrrrr[0])
            }
            else {
                setProductFromCart({})
            }
        } else {
            setProductFromCart({})
        }
    }, [productsInCart, productDetails])

    // useEffect(() => {
    //     console.log('productFromCart', productFromCart)
    //     console.log('productsInCart', productsInCart)
    // }, [productFromCart, productsInCart])


    return (
        <Grid container>
            {loading ? <ShopLoader title={'Product Details'} linerLoading /> : <Grid item container xs={12} lg={12} md={12} sm={12}>
                <Grid item container xs={12} lg={12} md={12} sm={12} p={1} justifyContent={'space-between'}>
                    <Link to="/shopping">
                        <IconButton color='primary'><ArrowBack /></IconButton>
                    </Link>
                    <Link to="/shopping/my-cart">
                        <IconButton color='primary'>
                            <Badge badgeContent={productsInCart && productsInCart?.length} color='success'>
                                <ShoppingCart />
                            </Badge>
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent={'center'}>
                    <div style={{ width: '90%', height: "60%", maxHeight: 310 }}>
                        <Carousel animation="slide" indicators={true}  >
                            {
                                productDetails?.images?.map((pimg, index) => <img key={index} src={pimg} height={280} width={'100%'} style={{ objectFit: 'contain' }} />)
                            }
                        </Carousel>
                    </div>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12} p={1} >
                    <Grid item container xs={12} lg={12} md={12} sm={12} p={1} >
                        <Typography style={styles?.nameFontSize}>{productDetails?.name}</Typography>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} sm={12} p={1} >
                        <Grid item container xs={4} lg={4} md={4} sm={4}  >
                            <Typography >MRP : <del> ₹ {(productDetails?.menuPrice || productDetails?.mrp)}</del></Typography>
                            <Typography style={{ ...styles.nameFontSize, color: '#94c100' }}>₹ {calculateSellingPrice(productDetails, isMembershipExist)}</Typography>
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={1} lg={1} md={1} sm={1}  >
                            <span style={{ borderRight: '1px solid gray' }}></span>
                        </Grid>
                        <Grid item container xs={7} lg={7} md={7} sm={7} style={{ position: 'relative' }} alignItems={'center'} >
                            <Grid container alignItems={'center'} style={{ position: 'absolute', paddingTop: '10px', top: 0, left: 0, zIndex: 10, width: '100%' }}>
                                <Typography variant='h3' style={{ color: 'white', paddingLeft: '22px' }}>{calculateDiscount(productDetails, isMembershipExist)}</Typography>
                                <Typography style={{ color: 'white' }} >{"% OFF"}</Typography>
                            </Grid>
                            <img src={discount_background_chip} height={50} width={150} />
                        </Grid>
                        <Grid item container xs={12} lg={12} md={12} sm={12} p={1} alignItems={'center'} spacing={2} position={'fixed'} style={{ bottom: 0, left: 0, width: '100%' }} >
                            {productFromCart && Object.keys(productFromCart).length > 0 ?
                                <>
                                    <Grid item container xs={6} lg={6} md={6} sm={6} alignItems={'center'} >
                                        <Link to='/shopping/my-cart' style={{ width: '100%' }}>
                                            <Button variant='contained' fullWidth
                                                color='primary' size='small'
                                            // sx={{ background: '#94c100', color: 'white', ':hover': { background: '#94c100', color: 'white' } }}
                                            >
                                                Go to cart
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item container xs={6} lg={6} md={6} sm={6} alignItems={'center'} >
                                        <Button fullWidth sx={{ boxShadow: '0px 2px 6px lightgray', border: 'none', background: 'crimson', }} variant='outlined' onClick={handleRemoveProduct}>
                                            <Delete fontSize='small' style={{ color: 'white' }} />
                                        </Button>
                                    </Grid>
                                </> :
                                <Grid item container xs={12} lg={12} md={12} sm={12} alignItems={'center'} >

                                    <Button
                                        variant='contained'
                                        color='success'
                                        sx={{ backgroundColor: '#94c100', color: 'white', ':hover': { backgroundColor: '#94c100', color: 'white' } }}
                                        onClick={() => handleAddProduct(productDetails)}
                                        fullWidth
                                        size='small'>
                                        ADD
                                    </Button>
                                </Grid>
                            }

                            {/* {productFromCart && Object.keys(productFromCart).length > 0 ?

                                    <ButtonGroup variant="outlined" size='small' fullWidth  >
                                        <Button variant='outlined' sx={styles.btn1} onClick={handleDescreaseQuantity}>
                                            {productFromCart?.quantity > 1 ? <IconMinus /> : <Delete color='error' />}
                                        </Button>
                                        <Button variant='text' sx={styles.btn2}>{productFromCart?.quantity}</Button>
                                        <Button variant='outlined' sx={styles.btn1} onClick={handleIncreaseQuantity}><Add /> </Button>
                                    </ButtonGroup>
                                    : <Button variant='contained' color='success' sx={{ backgroundColor: '#94c100', color: 'white', ':hover': { backgroundColor: '#94c100', color: 'white' } }} onClick={() => handleAddProduct(productDetails)} fullWidth size='small'>ADD</Button>} */}
                            {/* </Grid> */}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            }
        </Grid>
    )
}
