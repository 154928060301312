import { Grid, Typography } from '@material-ui/core'
import { Button, ButtonGroup } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart, descreaseProductQuantity, increaseProductQuantity, removeProductFromCart, updateCartData } from '../../store/Actions/userCartActions'
import { Add, Delete } from '@mui/icons-material'
import { IconMinus } from '@tabler/icons'
import { Link } from 'react-router-dom'
import { fontSizestyles as styles } from './fontSizes'
import { darkBlueStrip } from '../../assets/images'
import ShopServer from './ShopServer'
import { useEffect } from 'react'
import { useState } from 'react'
import { calculateDiscount, calculateSellingPrice } from './calculateProductDiscount'
import { LoadingButton } from '@material-ui/lab'



export default function ProductCard({ product, productsFromCart }) {

    const dispatch = useDispatch()
    const [productFromCart, setProductFromCart] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingQuantity, setloadingQuantity] = useState(false)
    const isMembershipExist = useSelector((state) => state?.agentInfo?.membershipStatus)

    const handleAddProduct = () => {
        setLoading(true)
        let productObj = {
            ...product,
            quantity: 1,
            image: product['images'][0],
            price: calculateSellingPrice(product, isMembershipExist),
            mrp: product?.mrp ?? product.menuPrice
        }
        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        })
            .then((res) => {
                // getProductsInCart()
                dispatch(updateCartData([...productsFromCart, productObj]))
                setLoading(false)

            }).catch((err) => {
                setLoading(false)
                console.error(err)
            })

    }

    // const handleIncreaseQuantity = () => {
    //     setloadingQuantity(true)
    //     setLoading(true)
    //     ShopServer.post('/products/addProductsToCurrentCart', {
    //         product: {
    //             ...product,
    //             quantity: Number(productFromCart?.quantity || 0) + 1,
    //         }
    //     })
    //         .then((res) => {
    //             // getProductsInCart()
    //             setLoading(false)
    //             dispatch(increaseProductQuantity(product))

    //         }).catch((err) => {
    //             setLoading(false)
    //             console.error(err)
    //         })

    // }
    // const handleDescreaseQuantity = () => {
    //     setloadingQuantity(true)

    //     setLoading(true)
    //     let finalQuantity = 0
    //     if (productFromCart?.quantity > 1) {
    //         finalQuantity = Number(productFromCart?.quantity || 0) - 1
    //         dispatch(descreaseProductQuantity(product))
    //     } else {
    //         finalQuantity = 0
    //         dispatch(removeProductFromCart(product))
    //     }

    //     ShopServer.post('/products/addProductsToCurrentCart', {
    //         product: {
    //             ...product,
    //             quantity: finalQuantity,
    //         }
    //     }).then((res) => {
    //         // getProductsInCart()
    //         setLoading(false)



    //     }).catch((err) => {
    //         setLoading(false)
    //         console.error(err)
    //     })
    // }
    useEffect(() => {
        setloadingQuantity(true)
        let prrrrr = productsFromCart?.filter((pr1) => pr1?.productId == product?.productId)
        if (prrrrr?.length > 0) { setProductFromCart(prrrrr[0]) }
        else {
            setProductFromCart({})
        }
        setloadingQuantity(false)

    }, [productsFromCart])
    const handleRemoveProduct = () => {
        let productObj = {
            ...product,
            quantity: 0,
            mrp: product?.mrp || product?.menuPrice,
            image: product.image,
            productId: product?._id
        }

        ShopServer.post('/products/addProductsToCurrentCart', {
            product: productObj
        }).then((res) => {
            // if (finalQuantity == 0) {
            dispatch(removeProductFromCart(productObj))
            // }
        }).catch((err) => {
            // setLoading(false)
            console.error(err)
        })
    }

    return (
        <Grid container style={styles.container}>

            <Grid item xs={12} lg={12} md={12} sm={12} style={styles.img_container} container justifyContent={'center'}>
                <Link to={`/shopping/product-details/${product?._id}`} style={{ textDecoration: 'none' }}>
                    <img src={product?.images?.[0]} style={styles.imgSt} />
                </Link>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12} >
                <Link to={`/shopping/product-details/${product?._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <Typography style={styles.fontSize} >{product?.name}</Typography>
                </Link>
            </Grid>
            <Grid item container xs={12} lg={12} md={12} sm={12} >
                <Grid item xs={7} lg={7} md={7} sm={7} >
                    <Typography variant='caption' style={styles.fontSizeMrp} >
                        <i>MRP :<span style={{ textDecoration: 'line-through' }}> {"₹ " + (product?.mrp ?? product?.menuPrice)}</span></i>
                    </Typography>
                    <Typography style={styles.fontSize1} >
                        {"₹" + calculateSellingPrice(product, isMembershipExist)}

                    </Typography>
                </Grid>
                <Grid item container xs={5} lg={5} md={5} sm={5} position={'relative'} justifyContent={'flex-end'} alignItems={'center'}>
                    <Grid container item alignItems={'center'} justifyContent={'center'} style={{ position: 'absolute', padding: '10px 0px', top: 0, left: 6, zIndex: 10, width: '100%' }}>
                        <Typography variant='h4' style={{ color: 'white' }}>{calculateDiscount(product, isMembershipExist) || 0}</Typography>
                        <Typography style={{ color: 'white' }} >{"% OFF"}</Typography>
                    </Grid>
                    <img src={darkBlueStrip} height={'90%'} width={'90%'} style={{ objectFit: 'contain' }} />
                </Grid>
            </Grid>

            {/* {productsFromCart?.filter((pr1) => pr1?._id === product?._id).length > 0 ?
                <Grid item xs={12} lg={12} md={12} sm={12} mt={1} sx={{ width: '100%' }} >
                    <ButtonGroup variant="outlined" size='small' fullWidth  >
                        <Button disabled={loading} fullWidth variant='outlined' sx={styles.btn1} onClick={handleDescreaseQuantity}>{
                            productFromCart?.quantity > 1 ? <IconMinus /> : <Delete color='error' />
                        }  </Button>
                        <Button disabled={loadingQuantity} fullWidth variant='text' sx={styles.btn2}>{productFromCart?.quantity}</Button>
                        <Button disabled={loading} fullWidth variant='outlined' sx={styles.btn1} onClick={handleIncreaseQuantity}><Add /> </Button>
                    </ButtonGroup>
                </Grid> : <Grid item xs={12} lg={12} md={12} sm={12} mt={2}>
                    <Button disabled={loading} variant='contained' color='success' sx={{ backgroundColor: '#94c100', color: 'white', ':hover': { backgroundColor: '#94c100', color: 'white' } }} onClick={() => handleAddProduct(product)} fullWidth size='small'>ADD</Button >
                </Grid>} */}
            {productFromCart && Object.keys(productFromCart).length > 0 ?
                <Grid item container xs={12} lg={12} md={12} sm={12} alignItems={'center'} >
                    <Button fullWidth sx={{ boxShadow: '0px 2px 6px lightgray', border: 'none', background: 'crimson', ':hover': { background: 'crimson', color: 'white' } }} variant='outlined' onClick={handleRemoveProduct}>
                        <Delete fontSize='small' style={{ color: 'white' }} />
                    </Button>
                </Grid>
                :
                <Grid item container xs={12} lg={12} md={12} sm={12} alignItems={'center'} >

                    <Button
                        variant='contained'
                        color='success'
                        sx={{ backgroundColor: '#94c100', color: 'white', ':hover': { backgroundColor: '#94c100', color: 'white' } }}
                        onClick={handleAddProduct}
                        fullWidth
                        size='small'>
                        ADD
                    </Button>
                </Grid>
            }
        </Grid>
    )
}
