import axios from 'axios'
import { useSelector } from 'react-redux';
const BASE_URL = 'https://prelive.buyume.io'
// const BASE_URL = 'https://7c2c-113-193-27-186.ngrok-free.app'

const ShopServer = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        "Sec-Fetch-Site": "none"
    }
});
ShopServer.interceptors.request.use(
    async (config) => {
        // const userDetails = useSelector((state) => state?.userInfo?.sellerData)
        // const agentDetails = useSelector((state) => state?.userInfo?.adminData)
        var token = localStorage.getItem('sellerToken') || false
        var agentID = localStorage.getItem('agentId') || false
        // var agentID = JSON.parse(agentDetails)?._id || false;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['agentid'] = agentID;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


export default ShopServer;