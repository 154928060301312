import { Grid, Typography } from '@material-ui/core'
import { Button, ButtonGroup, IconButton, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart, descreaseProductQuantity, increaseProductQuantity, removeProductFromCart, setProductQuantity } from '../../store/Actions/userCartActions'
import { Add, Delete } from '@mui/icons-material'
import { IconMinus } from '@tabler/icons'
import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation'
import { Link } from 'react-router-dom'
import ShopServer from './ShopServer'
import { fontSizestyles2 as styles } from './fontSizes'
import { LoadingButton } from '@material-ui/lab'
import { calculateDiscount } from './calculateProductDiscount'
import { useEffect } from 'react'



const getOptions = (primaryPackaging) => {
    // const  = props;
    let initNum = parseInt(primaryPackaging / 10 || 0)
    let arr = []
    let qty_start = (Number(primaryPackaging) - (9 * initNum))
    // let count = 0
    for (let i = 0; i < 30; i++) {
        arr.push(qty_start + (initNum * i))
    }
    return arr

}


export default function ProductCard2({ product, productsFromCart, productsData, primaryPackaging, loadingBreakup }) {

    // const productsInCart = useSelector((state) => state?.userCart?.products)
    const dispatch = useDispatch()
    const isMembershipExist = useSelector((state) => state?.agentInfo?.membershipStatus)

    // console.log('isMembershipExist', isMembershipExist)
    let productFromCart = productsFromCart.filter((item) => item?.productId == product.productId)[0]
    let product_dump = productsData?.filter((item) => item?.productId == product.productId)[0]
    // let product_dump = {}
    const arrayLess10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    const [loadingQuantity, setloadingQuantity] = useState(false)

    // useEffect(() => {
    // let options = getOptions
    // console.log()
    // }, [])

    // const handleIncreaseQuantity = () => {
    //     setloadingQuantity(true)
    //     ShopServer.post('/products/addProductsToCurrentCart', {
    //         product: {
    //             ...product,
    //             quantity: Number(productFromCart?.quantity || 0) + 1,
    //         }
    //     }).then((res) => {
    //         setloadingQuantity(false)
    //         dispatch(increaseProductQuantity(product))

    //     }).catch((err) => {
    //         setloadingQuantity(false)
    //         console.error(err)
    //     })

    // }
    // const handleDescreaseQuantity = () => {
    //     // setloadingQuantity(true)

    //     // setLoading(true)
    //     let finalQuantity = 0
    //     if (productFromCart?.quantity > 1) {
    //         finalQuantity = Number(productFromCart?.quantity || 0) - 1
    //         dispatch(descreaseProductQuantity(productFromCart))

    //     } else {
    //         finalQuantity = 0
    //     }

    //     ShopServer.post('/products/addProductsToCurrentCart', {
    //         product: {
    //             ...product,
    //             quantity: finalQuantity,
    //         }
    //     }).then((res) => {
    //         if (finalQuantity == 0) {
    //             dispatch(removeProductFromCart(productFromCart))
    //         }
    //     }).catch((err) => {
    //         // setLoading(false)
    //         console.error(err)
    //     })
    // }
    const handleRemoveProduct = () => {
        ShopServer.post('/products/addProductsToCurrentCart', {
            product: {
                ...product,
                quantity: 0,
            }
        }).then((res) => {
            // if (finalQuantity == 0) {
            dispatch(removeProductFromCart(productFromCart))
            // }
        }).catch((err) => {
            // setLoading(false)
            console.error(err)
        })
    }
    const handleChangeQuantity = (e) => {
        // console.log('eeee', e)
        setloadingQuantity(true)
        let finalObj = {
            ...product,
            quantity: Number(e.target.value),
        }
        // ShopServer.post('/products/addProductsToCurrentCart', {
        //     product: finalObj
        // }).then((res) => {
        setloadingQuantity(false)
        dispatch(setProductQuantity(finalObj))

        // }).catch((err) => {
        //     setloadingQuantity(false)
        //     console.error(err)
        // })
    }
    return (
        <Grid container style={styles.container} >
            <Grid container justifyContent={'center'} alignItems={'center'} item style={styles.img_container} lg={3} xs={3} sm={3} md={3}>
                <Link to={`/shopping/product-details/${product?._id}`} style={{ textDecoration: 'none' }}>
                    <img src={product?.image} style={styles.imgSt} />
                </Link>
            </Grid>
            <Grid item lg={9} sm={9} xs={9} md={9} p={2} container justifyContent={'space-between'} alignContent={'start'}>
                <Link to={`/shopping/product-details/${product?.productId}`} style={{ textDecoration: 'none' }}>
                    <Typography style={styles.fontSize} >{product?.name}</Typography>
                </Link>
                <Grid container lg={12} xs={12} sm={12} md={12} alignItems={'center'} justifyContent={'space-between'}>
                    {/* <div> */}
                    <Grid item container alignItems={'center'} lg={6} pt={1} pb={1} gap={1}>
                        <Typography variant='caption' >MRP :<del> {"₹" + numericCommaSeparation(Math.round(product?.mrp || product?.menuPrice))}</del> </Typography>
                        {/* <Typography style={{ color: '#94c100', fontWeight: 'bold' }} > {calculateDiscount(product, isMembershipExist)}% Off</Typography> */}
                        <Typography style={{ color: '#94c100', fontWeight: 'bold' }} > {product?.discount}% Off</Typography>
                        {/* <Typography fontWeight={'bold'} color={'black'}> Price : ₹ {Math.round(product?.price * product?.quantity)}</Typography> */}
                    </Grid>
                    {/* </div> */}

                    <Grid item lg={6} xs={12} sm={12} md={12} mb={1} container justifyContent={'space-between'} alignItems={'center'} p={1} sx={{ background: 'whitesmoke' }}>
                        {/* <Typography  > {Math.round(product?.price)}</Typography> */}
                        <Typography fontWeight={'bold'} color={'black'}> Price : ₹ {Math.round(product_dump?.price * product_dump?.quantity) || 'Loading...'}</Typography>

                    </Grid>


                    <Grid item lg={12} xs={12} sm={12} md={12} container justifyContent={'space-between'} >

                        {/* <ButtonGroup variant="outlined" size='small' fullWidth  >
                            <Button variant='outlined' sx={styles.btn1} onClick={handleDescreaseQuantity}>{
                                productFromCart?.quantity > 1 ? <IconMinus /> : <Delete color='error' />
                            }  </Button>
                            <LoadingButton loading={loadingQuantity} size='small' variant='text' sx={styles.btn2}>{productFromCart?.quantity || 0}</LoadingButton>
                            <Button variant='outlined' sx={styles.btn1} onClick={handleIncreaseQuantity}><Add /> </Button>
                        </ButtonGroup> */}
                        <Button sx={{ boxShadow: '0px 2px 6px lightgray', padding: '0px', width: '40px', border: 'none' }} variant='outlined' onClick={handleRemoveProduct}>
                            <Delete color='error' fontSize='small' />
                        </Button>
                        <Grid item lg={6} xs={6} sm={6} md={6} >
                            {(loadingQuantity || loadingBreakup) ? <LoadingButton variant='outlined' loading="true">... </LoadingButton> : null}

                            {primaryPackaging != undefined && primaryPackaging != null && !loadingBreakup && !loadingQuantity ? <Select fullWidth value={product.quantity} size='small' onChange={handleChangeQuantity}>
                                {primaryPackaging < 11 ? arrayLess10.map((item) => <MenuItem sx={{ opacity: "1!important", backgroundColor: primaryPackaging == 0 ? 'white' : (item % primaryPackaging != 0) ? 'whitesmoke' : 'white' }} value={item} key={item} disabled={primaryPackaging == 0 ? false : (item % primaryPackaging != 0)}> {item}</MenuItem>) : null}

                                {primaryPackaging > 10 ? getOptions(primaryPackaging)?.map((item) => <MenuItem value={item} sx={{ opacity: "1!important", backgroundColor: primaryPackaging == 0 ? 'white' : (item % primaryPackaging != 0) ? 'whitesmoke' : 'white' }} disabled={item % primaryPackaging != 0} x key={item}>{item}</MenuItem>) : null}
                            </Select> : null}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid >
    )
}
