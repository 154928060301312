
const saveUserData = (payload) => {
    return {
        type: 'SET_USER_DATA',
        payload: payload
    };
};
const clearUserData = (payload) => {
    return {
        type: 'CLEAR_USER_DATA',
        payload: {}
    };
};

const saveAppointmentData = (payload) => {
    return {
        type: 'SET_APPOINTMENT_DATA',
        payload: payload
    };
}
const increseServiceQuantity = (payload) => {
    return {
        type: 'INCREASE_SERVICE_QUANTITY',
        payload: payload
    }
}

const descreseServiceQuantity = (payload) => {
    return {
        type: 'DESCREASE_SERVICE_QUANTITY',
        payload: payload
    };
}
const saveSalonDetails = (payload) => {
    return {
        type: 'REFRESH_SALON_DATA',
        payload: payload
    };
}
const saveUniqueID = (payload) => {
    return {
        type: 'SAVE_SALON_UNIQUE_ID',
        payload: payload
    };
}



export { saveUserData, saveAppointmentData, increseServiceQuantity, descreseServiceQuantity, saveSalonDetails, saveUniqueID, clearUserData };