import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress } from '@mui/material';
import { Grid } from '@material-ui/core';
import { buyumeLogo_2 } from '../assets/images';

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1301,
        width: '100%',
        height: "100%",
        background: 'white',
        // border: '1px solid red',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

//-----------------------|| Loader ||-----------------------//

const Loader = () => {
    const classes = useStyles();
    let currentLocation = window.location.pathname;
    // console.log('currentLocation', currentLocation)
    let logoLoader = ['/login', "/onboarding", '/register', '/forgot-password']
    return (
        <div className={classes.root}>
            {/* {
                logoLoader.includes(currentLocation) ? */}
            <Grid container justifyContent={'center'} alignItems="center" flexDirection={'column'} gap={4}>
                <img src={buyumeLogo_2} width={190} alt={"buyume_logo"} />
                <CircularProgress />
                {/* <span>Loading ....</span> */}
            </Grid>
            {/* : */}
            {/* <LinearProgress color="primary" style={{ width: '50%' }} /> */}
            {/* } */}
        </div>
    );
};

export default Loader;
