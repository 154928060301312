

const saveAdminData = (payload) => {
    return {
        type: 'SET_ADMIN_DATA',
        payload: payload
    };
}
const saveSellerData = (payload) => {
    return {
        type: 'SET_SELLER_DATA',
        payload: payload
    };
}
const setAdminLoginStatus = (payload) => {
    return {
        type: 'SET_ADMIN_LOGIN_STATUS',
        payload: payload
    };
}
const setSellerLoginStatus = (payload) => {
    return {
        type: 'SET_SELLER_LOGIN_STATUS',
        payload: payload
    };
}
const logoutBoth = (payload) => {
    return {
        type: 'LOGOUT_BOTH',
        payload: payload
    };
}
const setMembershipStatus = (payload) => {
    return {
        type: 'SET_SELLER_MEMBERSHIP',
        payload: payload
    };
}
const setSubscriptionDetails = (payload) => {
    return {
        type: 'SET_SELLER_SUBSCRIPTION',
        payload: payload
    };
}

export { saveAdminData, saveSellerData, setAdminLoginStatus, setSellerLoginStatus, logoutBoth, setMembershipStatus, setSubscriptionDetails };