import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard';
import { Grid, Typography } from '@material-ui/core';
import { Autocomplete, Checkbox, Divider, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ShopServer from './ShopServer';
import { Search } from '@mui/icons-material';
import { allbrands } from './allBrands'
import { updateCartData } from '../../store/Actions/userCartActions';
import { useHistory } from 'react-router-dom';
import ShopLoader from './ShopLoader'
import { setSubscriptionDetails } from '../../store/Actions/agentLoginActions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 8,
            width: 250,
            marginTop: 70
        },
    },
};


export default function ProductsList() {


    const [products, setSuggestedProducts] = useState([])
    const [suggestedProducts, setSuggestedProductsList] = useState([])
    // const [productsInCart, setProductsInCart] = useState([])
    const isAgentLoggedIn = useSelector((state) => state?.agentInfo?.isSellerLoggedIn)
    const [searchStr, setSearchStr] = useState('')
    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedLeafCategories, setselectedLeafCategories] = useState([])
    const [leafCategoriesOptions, setLeafCategoriesOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()

    const productsFromCart = useSelector((state) => state?.userCart?.products)
    const productsInCart = useSelector((state) => state?.userCart?.products)

    const getProductsInCart = () => {
        ShopServer.post('/products/getSellerLatestCart')
            .then((res) => {
                // console.log('seller cart', res.data)
                if (res.data.success) {
                    // setProductsInCart(res?.data?.data?.products || [])
                    dispatch(updateCartData(res?.data?.data.products))
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }
    const getSellerSubscription = () => {
        ShopServer.get('/subscription/getSellerSubscription')
            .then(res => {
                if (res.data.success) {
                    // console.log("getSellerSubscription", res?.data?.data)
                    dispatch(setSubscriptionDetails(res.data.data))
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    };
    const getSuggestedProductNames = (brandIds, leafcats) => {
        setLoading(true)
        let obj = {}
        if (brandIds.length > 0) {
            obj = {
                brandId: brandIds,
                type: 1
            }
        }
        if (brandIds?.length > 0 && leafcats?.length > 0) {
            obj = {
                brandId: brandIds,
                leafCategoryId: leafcats,
                type: 3
            }
        }
        ShopServer.post(`/products/mostSellingProductHomePageV2?pincode=110020`, {
            limit: 50,
            ...obj

        })
            .then((res) => {
                setSuggestedProducts(res.data.data)
                setLoading(false)

            }).catch((err) => {
                alert(err)
                setLoading(false)

            })
    }
    const getSuggestedProducts = (inp) => {

        if (inp != undefined && inp != null) {
            ShopServer.post(`/products/searchProducts`, {
                limit: 50,
                searchText: inp,
                page: 0,
                searchType: 'Type'
            })
                .then((res) => {
                    // console.log('ressssssss', res?.data?.data?.Products)
                    setSuggestedProductsList(res?.data?.data?.Products || [])
                }).catch((err) => {
                    alert(err)

                })
        }
    }

    useEffect(() => {
        if (isAgentLoggedIn) {
            getSuggestedProductNames("")
        }
        getProductsInCart()
        getSellerSubscription()
    }, [])
    // const handleProductSearch = (searchST) => {
    //     getSuggestedProducts(searchStr)
    // }
    const handleSearchSingleProduct = (e, value) => {
        if (value?._id != undefined) {
            history.push("/shopping/product-details/" + value?._id)
        }
    }
    const getLeafCategoriesByBrandId = (brandId) => {
        if (brandId.length < 2) {

            ShopServer.get('/api/leafCategoryIdByBrands?brandId=' + brandId)
                .then((res) => {
                    // console.log('leafCategoryIdByBrands', res?.data?.data?.[0]?.leafInfo)
                    if (res.data.success) {
                        setLeafCategoriesOptions(res?.data?.data?.[0]?.leafInfo)
                    }
                })
                .catch((err) => console.error(err))

        }
    }
    const handleBrandsFilter = (e) => {
        let brands = e.target.value
        getLeafCategoriesByBrandId(brands)
        setSelectedBrands(brands)
        localStorage.setItem('brands', JSON.stringify(brands))
        getSuggestedProductNames(brands)
        if (brands && brands.length == 0) {
            setLeafCategoriesOptions([])
            localStorage.removeItem('brands')
            setselectedLeafCategories([])
        }
    }
    const handleSelectLeafCategory = (e) => {
        // console.log('e.target.value', e.target.value)
        setselectedLeafCategories(e.target.value)
        getSuggestedProductNames(selectedBrands, e.target.value)

    }
    useEffect(() => {
        // console.log('selectedBrands', selectedBrands)
        let brands = localStorage.getItem('brands')
        if (brands) {
            // console.log('brands', brands)
            setSelectedBrands(JSON.parse(brands))
        }
    }, [])

    return (
        <div className="react_perfect_scrollbar_custom" >
            {/* <ScrollBar> */}
            <Grid container p={2} alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={12} >
                    <Autocomplete
                        id="free-solo-demo"
                        // freeSolo
                        fullWidth
                        size='small'
                        getOptionLabel={(option) => option?.name}
                        options={suggestedProducts || []}
                        onChange={handleSearchSingleProduct}
                        onInputChange={(e, value) => getSuggestedProducts(value)}
                        renderInput={(params) => <TextField {...params} label="Search Products..." />}
                    />
                </Grid>
                {/* <Grid item xs={2} md={2} container justifyContent={'center'}>
                    <IconButton sx={{ background: '#94c100', ':hover': { background: '#94c100', color: 'white' } }} >
                        <Search sx={{ color: 'white' }} />
                    </IconButton>
                </Grid> */}
                <Grid item xs={6} md={6} sm={6} lg={6}>
                    {/* <Select>
                        {
                            allbrands?.map((br,brindex)=><MenuItem value={}>{br?.name}</MenuItem>)
                        }
                    </Select> */}
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-checkbox-label">Brands</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            fullWidth
                            size='small'
                            value={selectedBrands}
                            onChange={handleBrandsFilter}
                            input={<OutlinedInput label="Brands" />}
                            renderValue={(selected) => selected?.length + " " + 'Brands'}
                            MenuProps={MenuProps}

                        >
                            {allbrands.map((br) => (
                                <MenuItem key={br?._id} value={br?._id} >
                                    <Checkbox checked={selectedBrands?.indexOf(br?._id) > -1} />
                                    <ListItemText primary={br?.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-checsssskbox-label">Category</InputLabel>
                        <Select
                            labelId="demo-multiple-checsssskbox-label"
                            id="demo-multiple-checkbox"
                            fullWidth
                            size='small'
                            multiple
                            value={selectedLeafCategories}
                            onChange={handleSelectLeafCategory}
                            input={<OutlinedInput label="Category" />}
                            renderValue={(selected) => selected?.length + " " + 'Category'}
                        // MenuProps={MenuProps}
                        >
                            {leafCategoriesOptions && leafCategoriesOptions?.map((cat, catInd) =>
                                <MenuItem key={cat?.leafCategoryId} value={cat?.leafCategoryId} >
                                    <Checkbox checked={selectedLeafCategories.indexOf(cat?.leafCategoryId) > -1} />
                                    <ListItemText primary={cat?.name} />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container justifyContent={'space-evenly'} gap={2} p={1} >
                {products?.map((prod, idx) => prod?.isAvailable ? <ProductCard
                    product={prod}
                    key={idx}
                    getSuggestedProductNames={getSuggestedProductNames}
                    getProductsInCart={getProductsInCart}
                    productsFromCart={productsFromCart}
                /> : null)}

                {
                    products.length > 0 ? null : !loading && <Typography variant='h3'>No Products found !</Typography>
                }
                {loading ? <ShopLoader title={'Products'} linerLoading /> : null}
            </Grid>
            {/* </ScrollBar> */}
        </div>
    )
}
