import React from 'react'
import { fontSizestyles2 as styles } from './fontSizes'
import { Chip, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { numericCommaSeparation } from '../../customFunctions/numericCommaSeparation';

export default function ProductCard3(props) {

    const { product } = props;
    let orderStatuses = {

        1: { title: 'In Transit', color: 'primary' },
        2: { title: 'Cancelled', color: 'error' },
        3: { title: 'Delivered', color: 'success' }
    }
    return (
        <Grid container sx={{ background: 'whitesmoke', borderRadius: 4, color: 'black' }} >
            <Grid container justifyContent={'center'} alignItems={'center'} item style={styles.img_container} lg={3} xs={3} sm={3} md={3}>
                <Link to={`/shopping/product-details/${product?.productId}`} style={{ textDecoration: 'none' }}>
                    <img src={product?.image} height={50} width={50} />
                </Link>
            </Grid>
            <Grid item lg={9} sm={9} xs={9} md={9} p={2} textAlign={'left'} textDecoration="none">
                <Link to={`/shopping/product-details/${product?.productId}`} style={{ textDecoration: 'none', }}>
                    <Typography variant='caption' color={'black'}>{product?.name}</Typography>
                    <Typography style={styles.fontSize1} color={'black'} >MRP :  {"₹" + numericCommaSeparation(Math.round(product?.mrp || product?.menuPrice))}</Typography>
                </Link>

            </Grid>
        </Grid>
    )
}
