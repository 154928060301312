import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';


// login routing
// const AuthLogin = Loadable(lazy(() => import('../Login')));
const LoginNew = Loadable(lazy(() => import('../Login/LoginNew')));
const Onboarding = Loadable(lazy(() => import('../LoginSignup/Onboarding')));
const ForgotPassword = Loadable(lazy(() => import('./ManagePassword/ForgotPassword')));
const SmartCamera = Loadable(lazy(() => import('../SmartCamera')));
// const Register = Loadable(lazy(() => import('../Login/Register')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/login', "/onboarding", '/register', '/forgot-password',]}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <NavMotion>
                        <GuestGuard>
                            <Route path="/login" component={LoginNew} />
                            <Route path="/onboarding" component={Onboarding} />
                            <Route path="/register" component={LoginNew} />
                            <Route path="/forgot-password" component={ForgotPassword} />

                        </GuestGuard>
                    </NavMotion>
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default LoginRoutes;
