import React, { useState } from 'react';

import {
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
    Typography,
} from '@material-ui/core';

import AnimateButton from '../../ui-component/extended/AnimateButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { saveAdminData, saveSellerData, setAdminLoginStatus, setMembershipStatus, setSellerLoginStatus } from '../../store/Actions/agentLoginActions';
import { useHistory } from 'react-router-dom';
import { Logout } from '@material-ui/icons';
import ShopServer from './ShopServer';
import NewSellerModal from './NewSellerModal';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
//===============================|| JWT LOGIN ||===============================//

const ShopLogin = (props, { ...others }) => {
    const history = useHistory()
    const [isAdmin, setIsAdmin] = useState(false)
    const [otpSent, setOtpSent] = useState(false)
    const [adminData, setAdminData] = useState({})
    const [adminLogin, setAdminLogin] = useState(false)
    const [sellerPhoneNumber, setSellerPhoneNumber] = useState('')
    const [loginDetails, setLoginDetails] = useState({
        userName: '',
        password: ''
    })
    // const scriptedRef = useScriptRef();
    const dispatch = useDispatch()

    const isSellerLoggedIn = useSelector((state) => state?.agentInfo?.isSellerLoggedIn)
    const isAdminLoggedIn = useSelector((state) => state?.agentInfo?.isAdminLoggedIn)
    const adminDataRedux = useSelector((state) => state?.agentInfo?.adminData)

    const [showPassword, setShowPassword] = React.useState(false);
    const [openNewSellerModal, setOpenNewSellerModal] = React.useState(false);
    const [newSellerForm, setNewSellerForm] = React.useState({});
    const [newSellerName, setNewSellerName] = useState('')
    const [isSellerCreated, setIsSellerCreated] = useState(false)



    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const sellerLoginWithOtp = (phone_number) => {
        ShopServer.post('/seller/resendOtp', {
            phoneNumber: phone_number
        })
            .then((res) => {
                console.log('res after seller otp', res)
            })
            .catch((err) => console.log(err))
    }


    const handleSubmit = async (event) => {
        event.preventDefault()
        ShopServer.post(`/role5/sendOtp`, {
            phoneNumber: loginDetails?.userName,
        }).then((res) => {
            if (res.data.success) {
                setOtpSent(res?.data?.data?.otpSent || false)
                setIsAdmin(res?.data?.data?.otpSent || false)
                if (res?.data?.data?.otpSent == false && res.data.data?.message == "User is invalid.") {
                    sellerLoginWithOtp(loginDetails?.userName)
                }
            }
        }).catch((err) => {
            console.error(err)
            alert('Something went wrong!')
        })
    }
    const loginWithOtp = () => {
        ShopServer.post(`/role5/loginWithOtp`, {
            phoneNumber: loginDetails?.userName,
            otp: loginDetails?.password
        }).then((res) => {
            // console.log("res after hit", res)
            if (res.data.success) {
                if (res?.data?.data?.adminData?.agentType == 'BULK') {
                    localStorage.setItem('agentId', res?.data?.data?.adminData?._id)
                    setAdminData(res?.data?.data?.adminData || {})
                    dispatch(saveAdminData(res?.data?.data?.adminData))
                    setAdminLogin(true)
                    dispatch(setAdminLoginStatus(true))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: 'You are not allowed to login !'
                    })
                    setAdminLogin(false)
                    setOtpSent(false)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    text: res?.data?.message
                })
            }

        }).catch((err) => console.log(err))
    }



    const switchUserLogin = () => {
        ShopServer.post(`/seller/switchUserLoginWithOtp`, {
            phoneNumber: sellerPhoneNumber,
            agentType: adminDataRedux?.agentType,
            agentId: adminDataRedux?._id
        }).then((res) => {
            if (res.data.success) {
                if (res.data.data) {
                    if (res.data.data.refferdByAgenttype != 'BULK') {

                        Swal.fire({
                            icon: 'error',
                            text: 'Seller is not tagged in BULK '
                        })
                    } else {

                        dispatch(saveSellerData(res?.data?.data))
                        localStorage.setItem('sellerToken', res?.data?.data?.token)
                        dispatch(setAdminLoginStatus(true))
                        dispatch(setSellerLoginStatus(true))

                        if (res?.data?.data?.token) {
                            ShopServer
                                .post('/seller/sellerMemberShipDash')
                                .then(
                                    res => {
                                        console.log('res', res)
                                        dispatch(setMembershipStatus(Boolean(res.data?.data?.isCurrentMemberShipExist || false)))
                                    },
                                    error => console.log('sellerMemberShipDash', error)
                                )
                        }

                        history.push('/shopping')
                    }

                } else {
                    if (res?.data?.message == 'Invalid Login') {
                        setOpenNewSellerModal(true)
                    } else {
                        alert(res?.data?.message)
                    }
                }
            } else {
                if (res?.data?.message == 'Invalid Login') {
                    setOpenNewSellerModal(true)
                } else {

                    Swal.fire({
                        icon: 'error',
                        text: res.data.message
                    })
                }
            }
        }).catch((err) => console.error(err))
    }
    const logoutAgent = () => {
        dispatch(setAdminLoginStatus(false))
        dispatch(saveAdminData({}))
        localStorage.removeItem('brands')
    }



    // const handleCloseNewSellerModal = () => {
    //     setOpenNewSellerModal(false)
    // }

    // const handleNewSellerForm = (e) => {
    //     setNewSellerForm((prev) => ({
    //         ...prev,
    //         [e.target.name]: e.target.value
    //     }))
    // }
    const handleCreateNewSeller = (e) => {

        // ShopServer.post('seller/createSellerBulk', {
        ShopServer.post('/seller/createSellerBulk', {
            name: newSellerName,
            phoneNumber: sellerPhoneNumber,
            agentId: adminDataRedux?._id,
            referedBy: adminDataRedux?.referralCode
        }).then((res) => {
            // console.log('res after create seller', res.data)
            if (res.data.success) {
                switchUserLogin()
                setIsSellerCreated(true)
                setOpenNewSellerModal(false)
            } else {
                alert(res?.data?.message || 'Something went wrong, please try again !')
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                text: (err.response?.data?.message || 'Something went wrong , please try again!')
            })
        })
    }

    return (<>

        <form  {...others} onSubmit={handleSubmit} style={{ height: '100vh' }}>
            <Grid container justifyContent="center" alignItems={'center'} style={{ height: '100%' }} >
                <Grid item xs={11} lg={4} md={4} sm={6} container spacing={3} justifyContent="center">
                    <Grid container item lg={12} xs={10} md={12} sm={12} >
                        <Typography style={{ color: '#002a5c', fontSize: 26, fontWeight: 900 }}>Log in</Typography>
                    </Grid>
                    {isAdminLoggedIn ? <span>Agent Phone Number : {adminDataRedux?.userName}</span> : <Grid container item lg={12} xs={12} md={12} sm={12} >
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>Registered Mobile Number </Typography>
                        <TextField
                            id="outlined-adornment-email-login"
                            type="text"
                            size='small'
                            // disabled={otpSent}
                            value={loginDetails.userName}
                            name="userName"
                            placeholder='Registered Mobile Number'
                            fullWidth
                            onChange={(e) => setLoginDetails({
                                ...loginDetails,
                                userName: e.target.value
                            })}

                        />
                    </Grid>}

                    {otpSent && !isAdminLoggedIn ? <Grid item lg={12} xs={12} md={12} sm={12}>

                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>OTP </Typography>

                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={showPassword ? 'text' : 'password'}
                            value={loginDetails.password}
                            name="password"
                            fullWidth
                            size='small'
                            placeholder='Enter OTP'
                            onChange={(e) => setLoginDetails({
                                ...loginDetails,
                                password: e.target.value
                            })}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"

                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid> : null}


                    {isAdminLoggedIn ? <Grid container item lg={12} xs={12} md={12} sm={12} >
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>Seller Phone Number </Typography>
                        <TextField
                            id="outlined-adornment-email-login"
                            type="text"
                            size='small'
                            value={sellerPhoneNumber}
                            name="seller Phone Number"
                            placeholder='Seller Phone Number '
                            fullWidth
                            onChange={(e) => setSellerPhoneNumber(e.target.value)}

                        />
                    </Grid>
                        : null}
                    {isAdminLoggedIn && openNewSellerModal ? <Grid container item lg={12} xs={12} md={12} sm={12} >
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>Seller Name </Typography>
                        <TextField
                            id="outlined-adornment-email-login"
                            type="text"
                            size='small'
                            value={newSellerName}
                            name="newSellerName"
                            placeholder='Seller Name '
                            fullWidth
                            onChange={(e) => setNewSellerName(e.target.value)}

                        />
                    </Grid>
                        : null}



                    <Grid item lg={12} xs={12} md={12} sm={12}>
                        <AnimateButton>
                            {isAdminLoggedIn && !openNewSellerModal ? <Button onClick={switchUserLogin} style={{ backgroundColor: '#002a5c' }} fullWidth size="large" variant="contained">
                                Switch User
                            </Button> : null}
                            {openNewSellerModal && isAdminLoggedIn ? <>
                                <Button onClick={handleCreateNewSeller} style={{ backgroundColor: '#002a5c' }} fullWidth size="large" variant="contained">
                                    Create Seller
                                </Button>
                                <Button color='error' onClick={() => setOpenNewSellerModal(false)} fullWidth variant="outlined" sx={{ marginTop: '7px' }}>
                                    Cancel
                                </Button>
                            </>
                                : null}

                            {isAdminLoggedIn && !isSellerLoggedIn ? <Button onClick={logoutAgent}>
                                <Logout />  Logout Agent
                            </Button> : null}

                            {otpSent && !isAdminLoggedIn ? <Button onClick={loginWithOtp} style={{ backgroundColor: '#002a5c' }} fullWidth size="large" variant="contained">
                                Sign In
                            </Button> : null}
                            {(otpSent || isAdminLoggedIn) ? null : <Button type='submit' style={{ backgroundColor: '#002a5c' }} fullWidth size="large" variant="contained">
                                Send Otp
                            </Button>}
                        </AnimateButton>
                    </Grid>
                </Grid>
            </Grid>
        </form >

        {/* <NewSellerModal
            open={openNewSellerModal}
            handleClose={handleCloseNewSellerModal}
            handleNewSellerForm={handleNewSellerForm}
            handleCreateNewSeller={handleCreateNewSeller}
        /> */}
    </>
    );
};

export default ShopLogin;
