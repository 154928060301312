import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Slide_2 } from '../../assets/images'

export default function Slide2() {
    return (
        <Grid container justifyContent={'space-between'} gap={2}>
            <Grid item lg={12} xs={12} md={12} sm={12} p={3} pt={6} gap={1} container alignItems={'center'} flexDirection="column">
                <Typography color={'#002a5c'} variant='h2'>3 Simple Steps</Typography>
                <Typography color={'#002a5c'} textAlign='center' pt={2}>1. Click or upload your image</Typography>
                <Typography color={'#002a5c'} textAlign='center'>2. Scan & analysis</Typography>
                <Typography color={'#002a5c'} textAlign='center'>3. Get the result</Typography>
            </Grid>
            <Grid container item lg={12} xs={12} md={12} sm={12} pt={2} alignItems="center" justifyContent={'center'}>
                <img src={Slide_2} alt="Slide_2" width={'100%'} />
            </Grid>
        </Grid >
    )
}

