import React from 'react'
import ProductsList from './ProductsList'
import Navbar from './Navbar'


export default function ShoppingHomepage() {


    return (
        <div style={{ backgroundColor: 'white', borderRadius: 4 }}>
            {/* <ShopNavbar /> */}
            {/* <Grid container > */}
            <Navbar />
            <ProductsList />
            {/* </Grid> */}
        </div>
    )
}
