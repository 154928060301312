import React, { useEffect, useState } from 'react'
import { Typography, Select, MenuItem } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import { CRMTextField as TextField } from '../../layout/Customization/CRMTextfield';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { lightGreen } from '@material-ui/core/colors';
import Button from '@mui/material/Button'






const style = {
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    p: 4,
};


const theme = createTheme({
    typography: {
        fontFamily: 'Lato-Regular',
    },

});

export default function AddClientForm(props) {
    const { actionPage, selectedUser, setOpenAddClientForm, getCustomerList } = props;
    const [customerData, setCustomerData] = useState({
        gender: 'MALE',
        // dob: new Date()
    })
    const [address, setAddress] = useState([])
    // const handleAddClient = () => {
    //     console.log("hello")
    // }
    const handleUpdateClient = (e) => {
        e.preventDefault();
        if (customerData != null && customerData != undefined) {
            let obj = {
                ...customerData,
                customerId: customerData?._id,
                addresses: address
            }
            axios.post(process.env.REACT_APP_PATH + '/sellerCrm/customers/updateCustomer', obj)
                .then((res) => {
                    if (res.data.success) {
                        // console.log('res.data', res.data.message)
                        alert(res.data.message)
                        setOpenAddClientForm(false)
                        getCustomerList(0, 10)
                    }
                }).catch((err) => {
                    console.warn(err)
                    alert(err)
                })
        }
    }


    const handlePinCode = (e) => {

        const pinCode = e.target.value
        if (pinCode != null || pinCode != undefined) {

            setAddress((prev) => ({
                ...prev, postalCode: Number(pinCode)
            }))
            if (pinCode.length === 6) {

                axios.get(`${process.env.REACT_APP_PATH}/sellerCrm/admin/getCityByPincode/${e.target.value}`)
                    .then(res => {
                        if (res.data.success) {
                            setAddress((prev) => ({
                                ...prev, "city": res?.data?.data?.city, "state": res?.data?.data?.state
                            }))
                        }
                    })
                    .catch((err) => alert(err))
            }
        }
    }
    // useEffect(() => {
    //     console.log('customerData', customerData)

    // }, [customerData])
    useEffect(() => {
        // console.log('selectedUser', selectedUser)
        setCustomerData(selectedUser)
        setAddress(selectedUser?.addresses[0] || "")
    }, [selectedUser])
    return (<>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Box sx={style} >
                    {/* appointment form starts here */}
                    <form
                        onSubmit={handleUpdateClient}>
                        <Grid container spacing={2} alignItems="center"
                            className='scrollbar' >
                            {/*Customer Name Input Box */}
                            <Grid item xs={6} md={6} >
                                Customer Name
                                <TextField
                                    fullWidth
                                    size='small'
                                    placeholder='Name'
                                    value={customerData?.name || ""}
                                    onChange={(e) => setCustomerData((prev) => ({ ...prev, 'name': e.target.value }))}
                                />
                            </Grid>
                            {/*Mobile Number Input Box */}
                            <Grid item xs={6} md={6} >
                                Mobile Number
                                <TextField
                                    fullWidth
                                    style={{ width: "90%" }}
                                    size='small'
                                    placeholder='Mobile Number'
                                    value={customerData?.phoneNumber || ""}
                                    disabled
                                    inputProps={
                                        {
                                            minLength: 10,
                                            maxLength: 10,
                                        }
                                    }
                                // onChange={(e) => setCustomerData((prev) => ({ ...prev, 'name': e.target.value }))}
                                />
                            </Grid>


                            <Grid item xs={6} md={6}  >
                                {/*Select Gender Box */}
                                <Typography>Select Gender</Typography>
                                <FormControl >
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        aria-label="anonymous"
                                        row
                                        value={customerData?.gender}
                                        name="gender"
                                        style={{ width: '110%' }}
                                        size="small"
                                        onChange={(e) => setCustomerData((prev) => ({ ...prev, 'gender': e.target.value }))}

                                    >
                                        <FormControlLabel
                                            value="MALE"
                                            control={
                                                <Radio sx={{
                                                    color: lightGreen[600],
                                                    '&.Mui-checked': {
                                                        color: lightGreen[600],
                                                    },
                                                }} />
                                            }
                                            label="Male"
                                        />
                                        <FormControlLabel value="FEMALE"
                                            control={
                                                <Radio sx={{
                                                    color: lightGreen[600],
                                                    '&.Mui-checked': {
                                                        color: lightGreen[600],
                                                    },
                                                }} />
                                            }
                                            label="Female" />
                                        <FormControlLabel
                                            value="OTHERS"
                                            control={
                                                <Radio sx={{
                                                    color: lightGreen[600],
                                                    '&.Mui-checked': {
                                                        color: lightGreen[600],
                                                    },
                                                }} />
                                            }
                                            label="Other" />
                                    </RadioGroup>

                                </FormControl>
                            </Grid>


                            <Grid item xs={6} md={6} lg={6} sm={6}>

                                <Typography>Date of Birth</Typography>
                                {/* <TextField
                                    type="date"
                                    style={{ width: '100%' }}
                                    disablePast
                                    size='small'
                                    value={customerData?.dob && (customerData?.dob).toString().slice(0, 10)}
                                    onChange={(e) => setCustomerData((prev) => ({ ...prev, 'dob': e.target.value }))}

                                /> */}
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    renderInput={(params) => <TextField {...params} helperText={null} size="small" style={{ width: '90%' }} />}
                                    placeholder='Date of Birth'
                                    value={customerData?.dob || null}
                                    name="dob"
                                    fullWidth
                                    size='small'
                                    disableFuture={true}
                                    onChange={(date) => {
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            dob: date['$d']
                                        }))
                                    }}

                                />

                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography>Email Id </Typography>
                                <TextField
                                    type="email"
                                    style={{ width: '100%' }}
                                    size='small'
                                    value={customerData?.emailId}
                                    onChange={(e) => setCustomerData((prev) => ({ ...prev, 'emailId': e.target.value }))}

                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Typography>Date of Aniversary </Typography>
                                {/* <TextField
                                type="date"
                                style={{ width: '100%' }}
                                size='small'
                                onChange={(e) => setCustomerData((prev) => ({ ...prev, 'dateOfAnniversery': e.target.value }))}
                                value={customerData?.dateOfAnniversery && (customerData?.dateOfAnniversery).toString().slice(0, 10)}
                            /> */}
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    renderInput={(params) => <TextField {...params} helperText={null} size="small" style={{ width: '90%' }} />}
                                    placeholder='Date of Anniversery'
                                    value={customerData?.dateOfAnniversery || null}
                                    name="dateOfAnniversery"
                                    size='small'
                                    fullWidth
                                    // disableFuture={false}
                                    onChange={(date) => {
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            dateOfAnniversery: date['$d']
                                        }))
                                    }}

                                />
                            </Grid>


                            {/*Select Customer's Membership type */}
                            {/* <Grid item xs={6} md={6} lg={6}>
                            <Typography>Membership</Typography>
                            <FormControl fullWidth>
                                <Select name="membership" size='small' >
                                    <MenuItem value={1}>Bronze</MenuItem>
                                    <MenuItem value={2}>Silver</MenuItem>
                                    <MenuItem value={3}>Gold</MenuItem>
                                    <MenuItem value={4}>Platinum </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                            <Grid item xs={6} md={6} lg={6}>
                                {/*Customer's gst number input box*/}
                                Customer GST
                                <TextField
                                    id="outlined-basic1"
                                    fullWidth
                                    name="gstNumber"
                                    value={customerData?.gstNumber}
                                    size='small'
                                    onChange={(e) => setCustomerData((prev) => ({ ...prev, 'gstNumber': e.target.value }))}

                                />
                            </Grid> <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h5" style={{ color: "grey" }}>Address</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='Address Line 1'
                                            name="address1"
                                            size='small'
                                            value={address?.address1 || ""}
                                            onChange={(e) => {
                                                setAddress((prev) => ({
                                                    ...prev, address1: e.target.value
                                                }))
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='Address Line 2'
                                            name="address2"
                                            size='small'
                                            value={address?.address2 || ""}
                                            onChange={(e) => {
                                                setAddress((prev) => ({
                                                    ...prev, address2: e.target.value
                                                }))
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='Landmark'
                                            name="landmark"
                                            size='small'
                                            value={address?.landmark || ""}
                                            onChange={(e) => {
                                                setAddress((prev) => ({
                                                    ...prev, landmark: e.target.value
                                                }))
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='Pin Code'
                                            name="postalCode"
                                            size='small'
                                            value={address?.postalCode || ""}
                                            onChange={handlePinCode}
                                            inputProps={{ maxLength: 6, minLength: 6 }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='City'
                                            name="city"
                                            size='small'
                                            value={address?.city || ""}
                                            onChange={(e) => {
                                                setAddress((prev) => ({
                                                    ...prev, city: e.target.value
                                                }))
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} lg={4} md={4} sm={4}>
                                        <TextField
                                            placeholder='State'
                                            name="state"
                                            size='small'
                                            value={address?.state || ''}
                                            onChange={(e) => {
                                                setAddress((prev) => ({
                                                    ...prev, state: e.target.value
                                                }))
                                            }}
                                        >
                                        </TextField>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent='flex-end' mt={2} mr={3}>
                            <Button variant="contained" color="inherit" type="submit" style={{ backgroundColor: '#002A56', color: 'white', textTransform: 'capitalize' }}>
                                {/* +Add New Client */}
                                {actionPage}
                            </Button>

                        </Grid>
                    </form>
                </Box>
            </LocalizationProvider>
        </ThemeProvider>

    </>
    )
}
