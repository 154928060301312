import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import HomeScreen from './HomeScreen'
import ImageScan from './ImageScan'
import SplashScreen from './SplashScreen'
import WebcamCapture from './Webcam'
import ResultScreen from './ResultScreen'
import axios from 'axios'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import classes from './smart_camera.module.css'
import { logFirebaseEvent } from '../LogFirebaseEvent'

export default function SmartCamera() {
    const [image, setImage] = useState(null)
    const [currentScreen, setCurrentScreen] = useState(1)
    const [resData, setResData] = useState({})
    const [dcScore, setDCscore] = useState(0)
    const [dcScore1, setDCscore1] = useState({})
    const [resultID, setResultId] = useState('')
    const [dcLoading, setDcLoading] = useState(false)
    let prelive_url = 'https://prelive.buyume.io'

    const saveResponseInDB = (data) => {
        axios.post(prelive_url + "/api/saveProcessedImageData", data)
            .then(res => {
                if (res.data.success) {
                    setResultId(res?.data?.data?._id)
                }
            })
            .catch(err => {
                console.error(err)
                Swal.fire({ icon: 'error', text: "Something went wrong ! please try again" })

            }
            )
    }
    const saveDcScoreInDB = () => {
        axios.post(prelive_url + "/api/saveProcessedImageData", {
            type: 'updateDcScore',
            imageId: resultID,
            dcScore: dcScore1?.dc_score,
            dcClass: dcScore1?.class
        })
            .then(res => {
                if (res.data.success) {
                    // setResultId(res?.data?.data?._id)
                    console.log('res.data', res.data)
                }
            })
            .catch(err => {
                console.error(err)

            }
            )
    }

    const uploadImage = async (file) => {
        // console.log('file', file)
        await axios.post("https://ai.buyume.io/face_ai", file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (Object.keys(res?.data).length > 0) {

                    setResData(res?.data || [])
                    setCurrentScreen(5)
                    saveResponseInDB(res?.data)
                } else {
                    Swal.fire({ icon: 'error', text: "Complete Face is not visible or blur image ! please try with another image !" })
                    goToHome()
                }
                // alert(res)
            }
            )
            .catch(err => {
                // console.log('err?.response', err?.response?.data)
                Swal.fire({ icon: 'error', text: err?.response?.data?.detail || 'Something went wrong , please try again !' })
                goToHome()
                // alert("something went wrong !")
            }
            )
    }
    const darkCircleScore = (file) => {
        // console.log('file', file)
        setDcLoading(true)
        axios.post("https://ai.buyume.io/dc_score", file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                // console.log('res for dark circle', res.data)
                setDCscore(res?.data?.dc_score || 0)
                setDCscore1(res?.data || {})

                setDcLoading(false)

            }
            )
            .catch(err => {
                setDcLoading(false)
                console.log(err)
            }

                //  alert(err + ' Please try again !')
            )
    }
    const handleCameraStart = () => {
        setCurrentScreen(3)
        logFirebaseEvent('start camera skin analysis')
    }
    const handleCaptureImage = (src, webcamProps) => {
        // console.log('src', src)
        logFirebaseEvent('capture image in skin analysis')
        setImage(src)
        setCurrentScreen(4)

        fetch(src)
            .then(res => res.blob())
            .then(blob => {
                const formdata = new FormData();
                // const file = new File([blob], "captured_image.jpg");
                formdata.append('file', blob)
                // console.log("formdata", formdata)
                uploadImage(formdata)
                darkCircleScore(formdata)
            })
            .catch((err) => console.log(err))
    }
    const handleuploadPhoto = (e) => {
        logFirebaseEvent('gallery button in skin analysis')
        let selectedFile = e.target.files[0]
        // console.log('selectedFile', selectedFile)
        // if (selectedFile && selectedFile.size > 51200 && selectedFile.size < 2097152) {

        if (selectedFile) {
            setImage(URL.createObjectURL(selectedFile))
            setCurrentScreen(4)
            var formData = new FormData();
            formData.append("file", selectedFile);
            uploadImage(formData)
            darkCircleScore(formData)
        }
        // } else {
        //     Swal.fire({
        //         text: 'Size of image should be between 50kb to 2 mb',
        //         icon: 'error'
        //     })
        // }
    }

    const skipSlider = (e) => {
        setCurrentScreen(2)
    }
    const goToHome = (e) => {
        setCurrentScreen(2)
        setResData({})
        setImage("")
        setDCscore(0)
        setDCscore({})
    }
    useEffect(() => {
        if (resultID && dcScore1 && resData?.dark_circle_profile?.flag && dcScore1?.class) {
            saveDcScoreInDB()
        }
    }, [dcScore1, resultID])
    return (
        <div className={classes.container}>

            {currentScreen == 1 ? <SplashScreen skipSlider={skipSlider} /> : null}
            {currentScreen == 2 ? <HomeScreen handleCameraStart={handleCameraStart}
                handleuploadPhoto={handleuploadPhoto}
                goToHome={goToHome}
            /> : null}
            {currentScreen == 3 ? <WebcamCapture handleCaptureImage={handleCaptureImage} goTohome={goToHome} /> : null}
            {currentScreen == 4 ? <ImageScan image={image} goToHome={goToHome} /> : null}
            {currentScreen == 5 ? <ResultScreen resData={resData} dcScore={dcScore} dcLoading={dcLoading} resultID={resultID} goToHome={goToHome} /> : null}
            {/* </Grid> */}
        </div>
    )
}
