import { Grid, makeStyles, Typography } from '@material-ui/core'
import { IconCamera, IconPhoto } from '@tabler/icons'
import React, { useEffect, useRef } from 'react'
import { buyumeLogo, facescan } from '../assets/images'

const useStyles = makeStyles({

    btnStyle: {
        height: 75,
        width: 85,
        borderRadius: 8,
        backgroundColor: '#94c10030',
        color: 'black',
        boxShadow: '0px 2px 6px lightgray',
        padding: "15px 10px",
        fontWeight: 400,
        cursor: 'pointer'
    }
})
export default function HomeScreen(props) {

    const classes = useStyles()
    const { handleCameraStart, handleuploadPhoto, goToHome } = props;
    const fileInput = useRef()


    return (
        <Grid container sx={{ bgcolor: 'white', }} spacing={2} pb={2}>
            <Grid item xs={12} lg={12} md={12} sm={12} p={2}>

                <Grid item p={2} container xs={12} lg={12} md={12} sm={12} >
                    <img src={buyumeLogo} alt="buyumeLogo" width={100} onClick={goToHome} />
                </Grid>
                <Grid sx={{ textAlign: 'center' }} p={1} >
                    <Typography color={'black'}>No Makeup Selfie!</Typography>
                    <Typography sx={{ fontSize: 20 }} color={'#fc6d6f'}>Take a Selfie or upload one</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent={'center'}>
                <img src={facescan} alt="facescan" width="90%" />
            </Grid>
            <Grid item container xs={12} lg={12} md={12} sm={12} justifyContent={'center'} gap={2}  >
                <Typography sx={{ color: '#002a5c', maxWidth: "90%", textAlign: 'center', fontWeight: 600, mb: 1, fontFamily: 'Lato-Regular', fontSize: 16 }}>
                    For best results click the picture in natural light and keep expression neutral.
                </Typography>

                <Grid item lg={3} xs={4} sm={4} md={3}
                    variant='outlined' p={1}
                    className={classes.btnStyle}
                >

                    <label htmlFor='uploadPhotofromGallary'>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <input
                                type={'file'}
                                ref={fileInput}
                                id="uploadPhotofromGallary"
                                style={{ display: 'none' }}
                                onChange={handleuploadPhoto}
                                accept="image/jpg, image/jpeg"
                            />
                            <IconPhoto />
                            Gallery
                        </div>
                    </label>




                </Grid>
                <Grid item lg={3} xs={4} sm={4} md={3}
                    variant='outlined' container justifyContent='center'
                    alignItems={"center"} p={1}
                    className={classes.btnStyle}
                    onClick={handleCameraStart}
                ><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                        <IconCamera />
                        Camera
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
