import { Typography } from '@material-ui/core'
import { CircularProgress, Grid, LinearProgress } from '@mui/material'
import React from 'react'


const styles = {
    loader: {
        height: '50vh',
        width: '100%'
    }
}

export default function ShopLoader({ title, linerLoading }) {



    return (
        <Grid container justifyContent={'center'} alignItems={'center'} style={styles.loader}>
            <div>
                {linerLoading ? <LinearProgress /> : <CircularProgress />}
                <Typography>{'Loading ' + (title || "")} . . .</Typography>
            </div>
        </Grid>
    )
}
