// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime, IconListNumbers } from '@tabler/icons';
// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime,
    IconListNumbers: IconListNumbers
};
// let role = localStorage.getItem('setRole')
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const Appointments =
{
    id: 'Appointments',
    title: <FormattedMessage id="Appointments" />,
    type: 'group',
    children: [

        {
            id: 'Calendar',
            title: <FormattedMessage id="Calendar" />,
            type: 'item',
            url: '/appointments',
            icon: icons['IconCalendarTime'],
            breadcrumbs: false,

        },
        {
            id: 'Appointments List',
            title: <FormattedMessage id="Appointments List" />,
            type: 'item',
            url: "/AppointmentsList",
            icon: icons['IconListNumbers'],
            breadcrumbs: false,

        },


    ]
} 
