


const updateCartData = (payload) => {
    return {
        type: 'UPDATE_CART_DATA',
        payload: payload
    };
}
const updateRewardProducts = (payload) => {
    return {
        type: 'UPDATE_REWARD_PRODUCTS',
        payload: payload
    };
}
const addProductToCart = (payload) => {
    return {
        type: 'ADD_PRODUCT_TO_CART',
        payload: payload
    };
}
const addRewardProduct = (payload) => {
    return {
        type: 'ADD_REWARD_PRODUCT',
        payload: payload
    };
}
const removeProductFromCart = (payload) => {
    return {
        type: 'REMOVE_PRODUCT_FROM_CART',
        payload: payload
    };
}
const removeRewardProduct = (payload) => {
    return {
        type: 'REMOVE_REWARD_PRODUCT',
        payload: payload
    };
}
const removeAllProductFromCart = (payload) => {
    return {
        type: 'MAKE_CART_EMPTY',
        payload: payload
    };
}
const increaseProductQuantity = (payload) => {
    return {
        type: 'INCREASE_PRODUCT_QUANTITY',
        payload: payload
    };
}
const descreaseProductQuantity = (payload) => {
    return {
        type: 'DESCREASE_PRODUCT_QUANTITY',
        payload: payload
    };
}
const setProductQuantity = (payload) => {
    return {
        type: 'SET_PRODUCT_QUANTITY',
        payload: payload
    };
}



export { addProductToCart, removeAllProductFromCart, increaseProductQuantity, setProductQuantity, descreaseProductQuantity, removeProductFromCart, updateCartData, addRewardProduct, removeRewardProduct, updateRewardProducts };