import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import MainCard from '../ui-component/cards/MainCard'

export default function Forbidden() {
    return (
        <MainCard >
            <h3> You Don't have access to this Page !</h3>
            <Button variant='contained' color='error' size='small'><Link to="/dashboard/default" style={{ color: 'white', textDecoration: 'none' }}>Back to Home</Link></Button>
        </MainCard>
    )
}
