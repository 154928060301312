import React, { useEffect, useState } from 'react'
import ShopServer from './ShopServer';
import Navbar from './Navbar';
import { Divider, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { drawerCoins } from '../../assets/images';
import ImageIcon from '../../layout/Customization/ImageIcon'
import dayjs from 'dayjs';
export default function CoinHistory() {

    const [coinHistory, setCoinsHistory] = useState([])

    const fetchHistoryData = () => {
        ShopServer.get(`/seller/getRewardHistory`).then(res => {
            // console.log('sssssss', res.data.data)
            setCoinsHistory(res.data.data);
        }).catch(err => console.log('Error in Coins History Page', err))

    }
    useEffect(() => {
        fetchHistoryData()
    }, [])
    return (<>
        <Navbar />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ImageIcon url={drawerCoins} size="medium" />
            <Typography p={2} variant='h3' textAlign={'center'}>
                Coins Statement
            </Typography>
        </div>
        <div style={{
            width: '100%', padding: '10px',
            //  border: '2px solid whitesmoke',
            //  boxShadow: '0px 2px 6px  whitesmoke', 
        }} >
            <Grid container style={{ fontWeight: 600 }} p={1}>
                <Grid item xs={3} lg={3} md={3} >
                    Date
                </Grid>
                <Grid item xs={4} lg={4} md={4} textAlign={'center'}>
                    Description
                </Grid>
                <Grid item xs={3} lg={3} md={3} textAlign={'center'}>
                    Coins
                </Grid>
                <Grid item xs={2} lg={2} md={2} >
                    Balance
                </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container gap={1} mt={2}>
                {coinHistory && coinHistory?.map((item, index) => <Grid key={index} style={{
                    fontSize: '10px', lineHeight: 1, color: 'grey',
                    // background: item?.credit > 0 ? '#94c10060' : '#ffc9d4'

                }} p={1} container>

                    <Grid item xs={3} lg={3} md={3} container>
                        <Typography lineHeight={1} fontSize={'12px'}>
                            {dayjs(item.createdAt).format('hh:mm A')}
                        </Typography>
                        <Typography variant='caption' lineHeight={1} fontSize={10}>
                            {dayjs(item.createdAt).format('DD/MMM/YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4} style={{ wordBreak: 'break-all' }} textAlign={'center'} >
                        {item?.numericOrderId || item?.rewardProductName}
                    </Grid>
                    <Grid item xs={3} lg={3} md={3} textAlign={'center'}
                        color={item?.credit > 0 ? 'green' : 'red'}
                        fontWeight={600}
                    >
                        {item?.credit > 0 ? "+" : null}{item?.credit}
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} textAlign={'center'} fontWeight={600}>
                        {item?.currentCoins}
                    </Grid>
                </Grid>)}
            </Grid>

        </div >
    </>
    )
}
