import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { IconGenderFemale, IconGenderMale } from '@tabler/icons';
import React, { useState } from 'react'
import { Button, IconButton } from '@mui/material';
import { ArrowBack, Close, Fullscreen } from '@mui/icons-material';
import { useEffect } from 'react';
import { image_not_found, whatsapp_background_3 } from '../assets/images';
import 'animate.css'
import { logFirebaseEvent } from '../LogFirebaseEvent';
const useStyles = makeStyles({
    active: {
        borderStartEndRadius: 10,
        borderStartStartRadius: 10,
        height: 48,
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Lato-Regular',
        fontSize: 17,
        boxShadow: '-3px -4px 8px lightgray'
    },
    inActive: {
        color: 'gray',
        height: 48,
        fontSize: 17,
        fontFamily: 'Lato-Regular',
        // fontWeight: 'bold',
        backgroundColor: 'whitesmoke',
        boxShadow: 'inset -4px -5px 5px 0px lightgray'
    },
    btnContainer: {
        backgroundColor: 'white',
        borderStartStartRadius: 10,
        borderStartEndRadius: 10,
        overflow: 'hidden'
    },
    cardCss: {
        borderRadius: 8,
        maxHeight: 115,
        minHeight: 115,
        minWidth: 95,
        maxWidth: 95,
        overflow: 'hidden',
        // padding: 10,
        border: '1px solid lightgray',
        boxShadow: '0px 2px 6px lightgray',
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
})


const ResultCard = (props) => {
    const { title, icon, value, onClick, isSelected, fontSize } = props;
    const classes = useStyles()
    return (<Grid container flexDirection={"column"} p={2}
        sx={{
            cursor: onClick ? 'pointer' : 'default',
            backgroundColor: isSelected ? '#002a5c' : 'white',
            color: isSelected ? 'white' : '#002a5c',

        }}
        onClick={onClick ? onClick : () => { return; }}
        alignItems="center" justifyContent={'space-between'}
        className={classes.cardCss}>
        <Typography sx={{ textTransform: 'uppercase', textSizeAdjust: 'auto', textAlign: 'center', mt: icon ? 0 : 2, width: '100%', textAlign: 'center' }}>  {title}</Typography>
        {icon}
        <Typography color={value === 'Loading...' ? 'lightgray' : '#94c100'} sx={{ fontSize: value === 'Loading...' ? 10 : fontSize, width: '100%', textAlign: 'center' }}>
            {value}
        </Typography>
    </Grid>)
}

export default function ResultScreen(props) {


    const classes = useStyles()
    const [selectedButton, setSelectedButton] = useState(1)
    const [selected, setSelected] = useState(1)
    const [displayImage, setDisplayImage] = useState('')
    const [showImageFullscreen, setShowImageFullscreen] = useState(false)
    const { resData, goToHome, dcScore, dcLoading, resultID, resultByIdScreen } = props;


    const handleButtonClick = (id) => {
        setSelectedButton(id)
        if (id == 2) {
            logFirebaseEvent('view lifestyle tab in skin analysis')
        }

    }
    let imagesObj = {
        1: resData?.original_image?.image_url,
        2: resData?.dark_circle_profile?.darkcircle_image_url,
        3: resData?.acne_profile?.acne_image_url,
        4: resData?.oiliness_profile?.oil_img_url,
        5: resData?.uneven_profile?.uneven_image_url,
        6: resData?.eyebag_profile?.eyebag_img_url,
        7: resData?.spots_profile?.spot_image_url,
        8: resData?.redness_profile?.redness_img_url,
        9: resData?.env_profile?.env_img_url,


    }

    const sendDataToReactNativeApp = async () => {
        let resid = JSON.stringify({ resId: resultID })
        window.ReactNativeWebView.postMessage(resid);
        logFirebaseEvent('link profile in skin analysis')
    };

    useEffect(() => {
        logFirebaseEvent('results screen in skin analysis')
    }, [])

    // console.log("resData", resData)
    return (
        <Grid container sx={{ height: '99vh', width: '100%', position: 'relative' }}>
            {/* height:{window.innerHeight} <br />width:{window.innerWidth} */}

            {
                showImageFullscreen ?
                    <div className='animate__animated animate__fadeIn'
                        onClick={() => setShowImageFullscreen(false)}
                        style={{
                            position: 'absolute', zIndex: 1300, top: 0, left: 0,
                            backgroundColor: '#17181a95',
                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                            height: '100%', width: '100%'
                        }}>
                        <IconButton sx={{ position: 'absolute', top: 5, right: 5, }} >
                            <Close style={{ color: 'white', }} />
                        </IconButton>
                        <img src={imagesObj[selected]} width={'100%'} height={'100%'} alt='Image not available' style={{ objectFit: 'contain' }} />

                    </div> : null
            }

            {/* {resultByIdScreen ? null : <IconButton
                size='small'

                sx={{
                    position: 'absolute',
                    top: 5, left: 0, color: 'black',
                    boxShadow: '0px 2px 10px black',
                    backgroundColor: 'white', borderRadius: 25
                }} onClick={goToHome}> <ArrowBack />
            </IconButton>} */}
            <Grid item lg={12} xs={12} sm={12} md={12} sx={{ height: '60%', width: '100%', backgroundColor: 'white' }} >
                <img src={resData ? imagesObj[selected] : image_not_found} width="100%"
                    height={'100%'}
                    onClick={() => setShowImageFullscreen(true)}
                    style={{ objectFit: 'contain', cursor: 'pointer' }} />
            </Grid>

            <Grid item lg={12} xs={12} sm={12} md={12} container sx={{
                position: 'absolute',
                bottom: 0, left: 0, overflow: 'hidden',
                borderStartEndRadius: 10, borderStartStartRadius: 10,
                height: 300
            }}>
                <Grid item lg={12} xs={12} sm={12} md={12} container justifyContent={'flex-end'}>

                    <IconButton
                        size='small'
                        color='success'

                        // sx={{ boxShadow: '25px lightgray' }}
                        onClick={() => {
                            setShowImageFullscreen(true)
                        }}>
                        <Fullscreen color='info' />
                    </IconButton>
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} container
                    alignItems={'start'} justifyContent="space-between"
                    className={classes.btnContainer}
                >
                    <Grid item lg={6} xs={6} sm={6} md={6}>
                        <Button
                            style={{ color: 'black', fontWeight: 900 }}
                            className={classes[selectedButton == 1 ? "active" : 'inActive']}
                            fullWidth onClick={() => handleButtonClick(1)} >
                            Skin Care

                        </Button>
                    </Grid>
                    <Grid item lg={6} xs={6} sm={6} md={6} >
                        <Button
                            // className={classes.inActive}
                            className={classes[selectedButton == 2 ? "active" : 'inActive']}
                            fullWidth onClick={() => handleButtonClick(2)}>
                            Lifestyle

                        </Button>
                    </Grid>
                </Grid>

                <Grid item lg={12} xs={12} sm={12} md={12}
                    container
                    flexDirection="row"
                    wrap='nowrap'

                    gap={1}
                    p={2} pt={3}
                    sx={{ backgroundColor: 'white', overflowX: 'scroll', overflowY: 'hidden' }}
                >
                    {
                        selectedButton == 2 ? <>
                            <ResultCard
                                title={'gender'}
                                icon={resData?.human_profile?.gender == 'male' ? <IconGenderMale /> : <IconGenderFemale />}
                                value={resData?.human_profile?.gender}
                            />
                            <ResultCard
                                title={'age'}
                                value={resData?.human_profile?.age}
                            />
                            <ResultCard
                                title={'skin score'}
                                fontSize={18}
                                value={(resData?.human_profile?.skin_score).toFixed(2)}
                            />
                        </> : null
                    }

                    {
                        selectedButton == 1 ? <>
                            <ResultCard onClick={() => setSelected(1)} isSelected={selected === 1} value={null} title="Original" />

                            {resultByIdScreen ? null : <ResultCard
                                onClick={() => setSelected(2)}
                                isSelected={selected === 2}
                                title={'Dark Circle'}
                                value={resData?.dark_circle_profile?.flag == false ? 0 : (dcLoading ? 'Loading...' : dcScore > 0 ? (Number(dcScore || 0)).toFixed(2) : dcScore)}
                                fontSize={18}
                            />}
                            {resultByIdScreen ? <ResultCard
                                onClick={() => setSelected(2)}
                                isSelected={selected === 2}
                                title={'Dark Circle'}
                                value={(resData?.dark_circle_profile?.flag == false) ? 0 : (resData?.dark_circle_profile?.dark_circle_density > 0 ? (resData?.dark_circle_profile?.dark_circle_density || 0).toFixed(2) : resData?.dark_circle_profile?.dark_circle_density)}
                                fontSize={18}
                            /> : null}
                            <ResultCard onClick={() => setSelected(3)} isSelected={selected === 3} title={'Acne'} value={Math.round(resData?.acne_profile?.acne_density || 0)}
                                fontSize={18}
                            />
                            <ResultCard onClick={() => setSelected(4)} isSelected={selected === 4} title={'Oiliness'} value={(resData?.oiliness_profile?.oil_density || 0).toFixed(2)} fontSize={18} />

                            {resData?.eyebag_profile?.flag ?
                                <ResultCard onClick={() => setSelected(6)} isSelected={selected === 6} title={'Eyebag profile'} value={(resData?.eyebag_profile?.flag == false) ? 0 : resData?.eyebag_profile?.score} />
                                : null}
                            {resData?.spots_profile ?
                                <ResultCard onClick={() => setSelected(7)} isSelected={selected === 7} title={'Spots profile'} value={resData?.spots_profile?.spot_count} />
                                : null}
                            {resData?.redness_profile?.redness_img_url ?
                                <ResultCard onClick={() => setSelected(8)} isSelected={selected === 8} title={'Redness profile'} value={''} />
                                : null}
                            {resData?.env_profile?.env_img_url ?
                                <ResultCard onClick={() => setSelected(9)} isSelected={selected === 9} title={'ENV profile'} value={''} />
                                : null}
                            <ResultCard onClick={() => setSelected(5)} isSelected={selected === 5} title={'UNEVEN SKIN'} value={''} />
                            {/* <ResultCard
                                title={'age'}
                                value={(resData?.human_profile?.age || 0)}
                            /> */}
                        </> : null
                    }
                </Grid>
                {resultByIdScreen ? null : <Grid item lg={12} xs={12} sm={12} md={12} container p={2} mb={1}>
                    <Button fullWidth variant='contained'
                        onClick={sendDataToReactNativeApp}
                        disabled={!resultID}
                        sx={{
                            backgroundColor: '#002a5c',
                            color: 'white', borderRadius: 2,
                            height: 45
                        }}>
                        Link Your Profile  &nbsp;&nbsp;<ArrowForward />
                    </Button>

                </Grid>}
            </Grid>
        </Grid>
    )
}
