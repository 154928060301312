// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconListNumbers, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime, IconUserCircle, IconClockHour10 } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconUserCircle: IconUserCircle,
    IconCalendarTime: IconCalendarTime,
    IconListNumbers: IconListNumbers,
    IconClockHour10: IconClockHour10
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const employeeAccess = {
    id: 'employee_profile',
    title: <FormattedMessage id="Menu" />,
    type: 'group',
    children: [

        {
            id: 'Calendar',
            title: <FormattedMessage id="Calendar" />,
            type: 'item',
            url: '/appointments',
            icon: icons['IconCalendarTime'],
            breadcrumbs: false,

        },
        {
            id: 'Employee_Home',
            title: <FormattedMessage id="Profile" />,
            type: 'item',
            url: '/employeeSection',
            icon: icons['IconUserCircle'],
            breadcrumbs: true,

        },
        {
            id: 'Appointments List',
            title: <FormattedMessage id="Appointments List" />,
            type: 'item',
            url: '/employeeAppointments',
            icon: icons['IconListNumbers'],
            breadcrumbs: true,

        },
        {
            id: 'Employee Attendance',
            title: <FormattedMessage id="My Attendance" />,
            type: 'item',
            url: '/employeeAttendance',
            icon: icons['IconClockHour10'],
            breadcrumbs: true,

        },
    ]
}