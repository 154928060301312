import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';


// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
// import RTLLayout from './ui-component/RTLLayout';
import Snackbar from './ui-component/extended/Snackbar';

// auth provider
// import { FirebaseProvider } from './contexts/FirebaseContext';
import { JWTProvider } from './contexts/JWTContext';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
//-----------------------|| APP ||-----------------------//
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import config from './config';

// Initialize Firebase
const app = initializeApp(config.firebase);

const analytics = getAnalytics(app);
logEvent(analytics, 'website loaded');
// Add the public key generated from the console here.
// getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY })


const App = () => {

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme("theme1")}>
                <CssBaseline />

                <Locales>
                    <NavigationScroll>
                        <JWTProvider>
                            <Routes />
                            <Snackbar />

                        </JWTProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
