import React, { useEffect, useState } from 'react'
import ResultScreen from './ResultScreen'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useParams } from 'react-router'
import { Grid } from '@mui/material'
import { no_results_found } from '../assets/images'
import { logFirebaseEvent } from '../LogFirebaseEvent'

export default function ResultById() {
    const params = useParams()
    const [resData, setResData] = useState({})
    const getResultsById = (resId) => {
        axios.post('https://prelive.buyume.io/api/getResponseImageProcessedData', {
            _id: params?.resId
        })
            .then((res) => {
                if (res.data.success) {
                    setResData(res.data.data || {})
                }
            }).catch((err) => {
                console.log('err', err)
                Swal.fire({
                    text: 'Something went wrong , please try again !'
                })
            })
    }
    useEffect(() => {
        getResultsById()
        logFirebaseEvent('view results screen in skin analysis')
    }, [])
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center'
        }}>
            <div style={{
                maxWidth: 400,
                width: '100%'
            }}>
                {resData && Object.keys(resData).length > 0 ? <ResultScreen resData={resData} resultByIdScreen />
                    : null}
            </div>
        </div>
    )
}
