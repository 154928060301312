import React, { createContext, useEffect, useReducer } from 'react';

// third-party
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../store/actions';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { initializeApp } from "firebase/app";

import accountReducer from '../store/accountReducer';
// import { saveUserData } from '../store/userActions';
import { clearUserData, saveAppointmentData, saveSalonDetails, saveUniqueID, saveUserData } from '../store/Actions/userActions';
// project imports
// import axios from '../utils/axios';
import Loader from '../ui-component/Loader';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import config from '../config';
import { useSelector } from 'react-redux';
// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    // user: null
};
// const time = localStorage.getItem('tokenTime')
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
let getTokenTime = parseJwt(localStorage.getItem('serviceToken'))

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    else {
        return getTokenTime?.exp * 1000 - Date.now() - 1000 * 60 * 3 > 0;
    }
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${serviceToken}`;
        axios.interceptors.response.use(response => response, error => {
            if (error.response.status === 403) {
                localStorage.removeItem('serviceToken');
                localStorage.removeItem('name')
                delete axios.defaults.headers.common["Authorization"];
                window.location.reload()
            } else {
                return Promise.reject(error);
            }
        })

    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('name')
        delete axios.defaults.headers.common["Authorization"];
        console.log("error h")
    }
};

//-----------------------|| JWT CONTEXT & PROVIDER ||-----------------------//

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => { }
});

const app = initializeApp(config.firebase);

// const messaging = getMessaging(app);
// const messaging = getMessaging(app);
// console.log('getMessaging isSupported', isSupported())


export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    // const [state1, dispatch1] = useReducer(userInfoReducer);
    const userDataRedux = useSelector((state) => state.userInfo)
    const dispatch1 = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const login = async (userName, password) => {
        const reqObj = {
            userName: userName,
            password: password
        }
        // console.log("reqObj", reqObj)
        try {
            const response = await axios.post(process.env.REACT_APP_PATH + "/sellerCrm/admin/login", reqObj)
            // console.log("response", response.data)
            if (response.data.data.message !== "Invalid credentials") {
                // console.log("response.data.data after login", response.data.data)
                let salonData = response.data.data && response.data.data?.salonDetails
                getFirebaseId(response?.data?.data?.firebaseId || null)
                let sId = salonData?.[0]?.uniquePrefix || ''
                // console.log('sId', sId)
                // console.log("res.data.data", response.data.data)
                // let sName = salonData?.[0]?.name.replace(/[^a-zA-Z0-9 ]/g, '') || 'BYM'
                // sName = sName.replaceAll(" ", "")
                // sName = sName.slice(0, 4)
                // let sId = salonData?.[0]?._id
                // sId = sName && sName.slice(0, 3) + sId.slice(0, 4)
                // sId = sId.toString().toUpperCase()

                setSession(response.data.data.token);
                localStorage.setItem('setRole', response.data.data.role);
                localStorage.setItem("phoneNumber", response.data.data.phoneNumber)
                localStorage.setItem('_id', response.data.data._id);
                localStorage.setItem('name', response.data.data.name)
                localStorage.setItem('expiredTime', response.data.data.expiredTime)
                dispatch({
                    type: LOGIN,
                });
                dispatch1(saveUserData(response.data.data));
                dispatch1(saveUniqueID(sId));
                // if (sessionStorage.getItem('url') != null) {
                //     history.push(sessionStorage.getItem('url'))
                //     sessionStorage.removeItem('url')
                // }
                // window?.__insp.push(['identify', response.data.data.phoneNumber])
            }
        } catch (e) {
            alert("User Name and Password Not Match")
        }

    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        // sessionStorage.setItem('url', location.pathname)
        dispatch1(saveAppointmentData({}))
        dispatch1(saveSalonDetails({}))
        dispatch1(saveUserData({}));
        dispatch1(clearUserData({}));
        // localStorage.clear()
    };
    const updateTokenInDB = (ftoken) => {
        // console.log("ftoken updated", ftoken)

        axios.post(process.env.REACT_APP_PATH + '/sellerCrm/admin/updateFirebaseToken', {
            firebaseToken: ftoken
        })
            .then((res) => {
                // console.log("token updated")
            })
            .catch((err) => {
                console.log("err in updating token", err)
            })
    }

    const getFirebaseId = (prevToken) => {

        // console.log('messaging', messaging)
        (async () => {
            const hasFirebaseMessagingSupport = await isSupported();
            if (hasFirebaseMessagingSupport) {
                const messaging = getMessaging(app)
                onMessage(messaging, (payload) => {
                    console.log('Message received. ', payload);
                    const n = payload.notification
                    new Notification(n?.title,
                        {
                            icon: n?.image,
                            body: n?.body,
                            vibrate: true,
                            // actions: [{
                            //     title: 'Ok',
                            //     action: 'nothing'
                            // },
                            // {
                            //     title: 'Open',
                            //     action: 'open'
                            // }
                            // ]
                        }
                    ).addEventListener('click', (event) => {
                        window.open(`http://erp.buyume.io/AppointmentDetailById/${payload.data.appointmentId}`)
                    })
                    // ...
                });
                getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY })
                    .then((newToken) => {
                        if (newToken) {
                            // Send the token to your server and update the UI if necessary
                            // console.log('newToken found', newToken)
                            if (newToken === prevToken) {
                                // console.log("token not changed")
                            } else {
                                // console.log("token  changed")
                                updateTokenInDB(newToken)
                                // new Notification(`Firebase id changed ${newToken}`)
                            }
                        } else {
                            // Show permission request UI
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                    })
            } else {
                console.log('Firebase messaging not supported')
            }
        })();



    }
    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            // user
                        }
                    });

                    // if (sessionStorage.getItem('url') != null) {
                    //     history.push(sessionStorage.getItem('url'))
                    //     sessionStorage.removeItem('url')
                    // }

                } else {
                    localStorage.removeItem('serviceToken');
                    // sessionStorage.setItem('url', location.pathname)
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            // user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        // user: null
                    }
                });
                // sessionStorage.setItem('url', location.pathname)
            }
        };

        init();
    }, []);


    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
