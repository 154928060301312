import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import { buyumeLogo } from '../assets/images';
import { logFirebaseEvent } from '../LogFirebaseEvent'





export default function ImageScan(props) {
    const { image, goToHome } = props;

    useEffect(() => {
        logFirebaseEvent('scan image screen in skin analysis')
    }, [])
    return (
        <Grid container justifyContent={'center'} style={{ height: '99dvh', maxHeight: 700 }}  >
            <Grid item lg={12} xs={12} sm={12} md={12} p={2}>
                <img src={buyumeLogo} alt='buyumeLogo' width={100} onClick={goToHome} />
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} p={2} sx={{ textAlign: 'center', height: 70 }}  >

                <Typography color={'black'}>Please wait</Typography>
                <Typography sx={{ fontSize: 20 }} color={'#fc6d6f'}>Analyzing . . .</Typography>

            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} container justifyContent={'center'} sx={{ position: 'relative' }} >
                <hr className='vert-move' style={{ position: 'absolute' }} />
                <Grid
                    sx={{
                        width: 300,
                        height: 320,
                        borderRadius: '10%',
                        overflow: 'hidden',
                        boxShadow: '5px 10px 10px lightgray',
                    }} >
                    <img src={image} alt="image1" style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} p={10} container justifyContent={'center'} alignItems="center"  >

                    <Typography color={'green'} variant="h3" fontFamily={'Lato-Regular'} textAlign="center" >
                        <CircularProgress color='secondary' />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
