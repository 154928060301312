
export const allbrands = [
    // {
    //     "margin": {
    //         "retail": 31.29,
    //         "nonRetail": 21.54
    //     },
    //     "nonretailUpFrontDiscount": 0,
    //     "retailUpFrontDiscount": 0,
    //     "_id": "60f8198155498632e803cef7",
    //     "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Cheryls.png",
    //     "name": "Cheryls",
    //     "sort": 10,
    //     "videoUrl": ""
    // },
    {
        "margin": {
            "retail": 41.06,
            "nonRetail": 41.06
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef9",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/iKONIC.png",
        "name": "Ikonic",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650283148/ikonic_new_debar4.mp4"
    },
    {
        "margin": {
            "retail": 44,
            "nonRetail": 36
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "6113ce606bfb9502a17981bf",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Streax.png",
        "name": "Streax Professional",
        "sort": 10
    },
    {
        "margin": {
            "retail": 50,
            "nonRetail": 50
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "6113d3936bfb9502a19e9b4e",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Ozone.png",
        "name": "Ozone",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650286913/ozone_jsoft5.mp4"
    },
    {
        "margin": {
            "retail": 43,
            "nonRetail": 28.75
        },
        "_id": "60f8198155498632e803ceeb",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Casmara.png",
        "name": "Casmara",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650287530/casmara_frltvf.mp4",
        "nonretailUpFrontDiscount": 25,
        "retailUpFrontDiscount": 40
    },
    {
        "margin": {
            "retail": 52.5,
            "nonRetail": 52.5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cf00",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678210/Mjmt%20Avnish/brands%20logo/Keratherapy.png",
        "name": "Keratherapy",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650283260/keratherapy_video_ggmkgo.mp4"
    },
    {
        "margin": {
            "retail": 51,
            "nonRetail": 51
        },
        "_id": "60f8198155498632e803ceee",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678207/Mjmt%20Avnish/brands%20logo/Mamaearth.png",
        "name": "Mamaearth",
        "sort": 2,
        "videoUrl": "",
        "nonretailUpFrontDiscount": 35,
        "retailUpFrontDiscount": 35
    },
    {
        "margin": {
            "retail": 52,
            "nonRetail": 52
        },
        "_id": "6103f15629f0e71514cd6539",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Richelon.png",
        "name": "Richelon",
        "sort": 1,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650287176/richelon_ysmzsa.mp4",
        "nonretailUpFrontDiscount": 52,
        "retailUpFrontDiscount": 52
    },
    {
        "margin": {
            "retail": 38.25,
            "nonRetail": 38.25
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef2",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640782352/Mjmt%20Avnish/brands%20logo/Waxxo.png",
        "name": "Waxxo",
        "sort": 11,
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 52.5,
            "nonRetail": 52.5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef8",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Ola_Candy.png",
        "name": "OlaCandy",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650344475/ola_candy_gs75qj.mp4"
    },
    {
        "margin": {
            "retail": 50,
            "nonRetail": 50
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef5",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Plum.png",
        "name": "Plum",
        "sort": 1,
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 50.5,
            "nonRetail": 50.5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803ceed",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Skin_Co.png",
        "name": "Skin Co",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650342331/SKIN_CO._tlpkcs.mp4"
    },
    {
        "margin": {
            "retail": 0,
            "nonRetail": 0
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803ceff",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678210/Mjmt%20Avnish/brands%20logo/JRL.png",
        "name": "JRL",
        "sort": 10
    },
    {
        "margin": {
            "retail": 40,
            "nonRetail": 36
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cefe",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678207/Mjmt%20Avnish/brands%20logo/Lotus.png",
        "name": "Lotus",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650287318/lotus_1_c1lfrf.mp4"
    },
    {
        "margin": {
            "retail": 28.75,
            "nonRetail": 28.75
        },
        "_id": "60f8198155498632e803cee9",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Rica.png",
        "name": "Rica",
        "sort": 2,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650342454/rica_gp7svk.mp4",
        "nonretailUpFrontDiscount": 25,
        "retailUpFrontDiscount": 25
    },
    {
        "margin": {
            "retail": 40.83,
            "nonRetail": 40.83
        },
        "_id": "6108efde77aa5378f8c0da79",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Aroma_magic.png",
        "name": "Aroma Magic",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650287014/Aroma_magic_i0otrg.mp4",
        "nonretailUpFrontDiscount": 38,
        "retailUpFrontDiscount": 38
    },
    {
        "margin": {
            "retail": 42,
            "nonRetail": 40
        },
        "_id": "60f8198155498632e803cef4",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678210/Mjmt%20Avnish/brands%20logo/Kerafusion.png",
        "name": "Kerafusion",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650284761/kKerafusion_brand_lyoj36.mp4",
        "nonretailUpFrontDiscount": 40,
        "retailUpFrontDiscount": 42
    },
    {
        "margin": {
            "retail": 52.5,
            "nonRetail": 52.5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef1",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678207/Mjmt%20Avnish/brands%20logo/Mr_Barber.png",
        "name": "Mr Barber",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650283830/mr_barber_u4b4lf.mp4"
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 35
        },
        "_id": "61000259ddcef2faf8275d4a",
        "name": "Be U",
        "imageUrl": "",
        "sort": 10,
        "nonretailUpFrontDiscount": 20,
        "retailUpFrontDiscount": 15,
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 25
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cefa",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Footlogix.png",
        "name": "Footlogix",
        "sort": 20,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650287421/footologix_vdosmb.mp4"
    },
    // {
    //     "margin": {
    //         "retail": 46.73,
    //         "nonRetail": 36.58
    //     },
    //     "_id": "60f8198155498632e803cefc",
    //     "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678210/Mjmt%20Avnish/brands%20logo/Loreal.png",
    //     "name": "Loreal",
    //     "sort": 1,
    //     "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650283367/loreal_video_fjkcqi.mp4",
    //     "nonretailUpFrontDiscount": 0,
    //     "retailUpFrontDiscount": 16
    // },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 35
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef3",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Biosoft.png",
        "name": "BioSoft",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650342554/biosoft_alxhc3.mp4"
    },
    // {
    //     "margin": {
    //         "retail": 37.89,
    //         "nonRetail": 20.96
    //     },
    //     "nonretailUpFrontDiscount": 0,
    //     "retailUpFrontDiscount": 0,
    //     "_id": "60f8198155498632e803ceec",
    //     "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678207/Mjmt%20Avnish/brands%20logo/Matrix.png",
    //     "name": "Matrix",
    //     "sort": 10,
    //     "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650284995/Matrix_jh9p3w.mp4"
    // },
    {
        "margin": {
            "retail": 50.5,
            "nonRetail": 50.5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cee8",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Argatin.png",
        "name": "Argatin",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650285225/Argatin_brand_1_znnr6g.mp4"
    },
    {
        "margin": {
            "retail": 52,
            "nonRetail": 41
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cefd",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/Godrej.png",
        "name": "Godrej Professional",
        "sort": 10
    },
    {
        "margin": {
            "retail": 47.75,
            "nonRetail": 47.75
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "60f8198155498632e803cef6",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/IBD.png",
        "name": "IBD",
        "sort": 10,
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 40,
            "nonRetail": 34
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "614dcc1195feb41019c004e5",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678207/Mjmt%20Avnish/brands%20logo/O3.png",
        "name": "o3+",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650343170/03_a5kuhf.mp4"
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 35
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "614dcc3395feb41019c00858",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Raaga.png",
        "name": "Raaga",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650342983/RAAGA_bgfwox.mp4"
    },
    {
        "margin": {
            "retail": 46,
            "nonRetail": 46
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "614dcc4295feb41019c0098b",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Wahl.png",
        "name": "Wahl",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650285083/WAHL_mdggyy.mp4"
    },
    {
        "margin": {
            "retail": 48,
            "nonRetail": 48
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61bd8eecfc90489a87e50e68",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678208/Mjmt%20Avnish/brands%20logo/Sara.png",
        "name": "SARA",
        "sort": 10,
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 39,
            "nonRetail": 39
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61c18ceee31531c48ff32a3d",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640074441/Mjmt%20Avnish/shussain.jpg",
        "name": "Shahnaz Husain",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650286605/shahnaaz_u3qrhh.mp4"
    },
    {
        "margin": {
            "retail": 55,
            "nonRetail": 55
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61c1bbe127b19f6cc33380f7",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640678209/Mjmt%20Avnish/brands%20logo/VLCC.png",
        "name": "VLCC",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650286505/organic_harvest_e7zt8e.mp4"
    },
    {
        "margin": {
            "retail": 40,
            "nonRetail": 35
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61c1d33927b19f6cc33389af",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1640092424/Mjmt%20Avnish/shahnaz%20husain/kChAv-1R_400x400.jpg",
        "name": "Organic Harvest",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650286269/organic_harvest_owzubr.mp4"
    },
    {
        "margin": {
            "retail": 22.4,
            "nonRetail": 22.4
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61c2efbd27b19f6cc333969e",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1642747245/buyume%20images/forever52-500x500.jpg",
        "name": "Forever52",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650344350/forever_52_oygrzi.mp4"
    },
    {
        "margin": {
            "retail": 22.4,
            "nonRetail": 22.4
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61cbfb47f43f50fc4c6894a7",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1642747131/buyume%20images/MAC-Logo.png",
        "name": "M.A.C",
        "sort": 10,
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650343863/mac_oiphxy.mp4"
    },
    {
        "margin": {
            "retail": 34.04,
            "nonRetail": 34.04
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61d6b37e3f0630af9de1e56b",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1642746859/buyume%20images/VEGA.png",
        "sort": 9,
        "name": "VEGA",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650285563/VEGA_tw9hr7.mp4"
    },
    {
        "margin": {
            "retail": 40,
            "nonRetail": 40
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61d94788a89c8003b938d53a",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1641629502/Mjmt%20Avnish/The%20Man%20company/07a3ba412bdf9f84aec8a6c5eca315f2.w1667.h1667.png",
        "sort": 12,
        "name": "The Man Company",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650282992/the_man_company_wk6dzz.mp4"
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 35
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61e795913892bf43406431eb",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1642566938/Mjmt%20Avnish/M.A.C/b6922a131d3b2a45fdbe8fbb215202e7.jpg",
        "sort": 14,
        "name": "Sleek",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650286015/sleek_cme5ko.mp4"
    },
    {
        "margin": {
            "retail": 30,
            "nonRetail": 30
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "61ea8e490abe1f690d353b6e",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1642761706/Mjmt%20Avnish/Biotique/183.jpg",
        "sort": 13,
        "name": "BIOTIQUE"
    },
    {
        "margin": {
            "retail": 53,
            "nonRetail": 53
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "620b41a7fdbdfa178f3029e0",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1644904788/Mjmt%20Avnish/Nature%20Essence/521021_14802725_2707467_15611c3a_image.jpg",
        "sort": null,
        "name": "Nature's Essence",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650285884/nature_essense_ua8mef.mp4"
    },
    {
        "margin": {
            "retail": 53,
            "nonRetail": 53
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "620b421efdbdfa178f302b86",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1644904956/Mjmt%20Avnish/Nature%20Essence/download.png",
        "sort": 15,
        "name": "Blue Heaven",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650343640/blue_heaven_atfl7z.mp4"
    },
    {
        "margin": {
            "retail": 45,
            "nonRetail": 45
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62382b9fe1575f986a777d1d",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1647848300/Mjmt%20Avnish/o3%2B/5dee75a909746a0009930bc9_wow-skin-science-logo-900.webp",
        "sort": null,
        "name": "Wow Skin",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 20,
            "nonRetail": 20
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "624fc5c6e225b7c3465ef555",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1649395018/Mjmt%20Avnish/o3%2B/kfTDc4nO5MdI.jpg",
        "sort": 0,
        "name": "FacesCanada",
        "videoUrl": "http://res.cloudinary.com/dyqcevdpm/video/upload/v1650343408/faces_canada_uzkpdy.mp4"
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 10
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "625e6d8b31953ded4278cdce",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1650355536/Mjmt%20Avnish/o3%2B/f3wambo01whcftnhimoy.webp",
        "sort": null,
        "name": "Batiste"
    },
    {
        "margin": {
            "retail": 50,
            "nonRetail": 50
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "625fb3ad2108a8058358836f",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1650439064/Mjmt%20Avnish/o3%2B/bkdnqprfiwemhi3cqori.webp",
        "sort": null,
        "name": "Pee Safe",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "626148fe4b382631291aaaff",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1650542773/Mjmt%20Avnish/o3%2B/2054.jpg",
        "sort": null,
        "name": "Toppik"
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 5
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "626149184b382631291aac28",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1650542774/Mjmt%20Avnish/o3%2B/Viviscal_HG_HGCProgram_Grey.png",
        "sort": null,
        "name": "Viviscal",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 50,
            "nonRetail": 50
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "627b8b9c980c873a2941b9ae",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1652263805/Mjmt%20Avnish/aman/Jovees_Logo.jpg",
        "sort": null,
        "name": "Jovees",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 25,
            "nonRetail": 25
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "6296f7ec1c4a67944db54415",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1654060945/Mjmt%20Avnish/aman/Olivia/Olivia_Logo1.jpg",
        "sort": null,
        "name": "Olivia",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 10
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62a6c720ce592b461eadde26",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1655097030/Mjmt%20Avnish/aman/Dabur/Dabur-Logo.wine.png",
        "sort": null,
        "name": "Dabur",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 26.67,
            "nonRetail": 26.67
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62aaf41d3efbe4cff0f22cf5",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1655370580/Mjmt%20Avnish/aman/Sugar%20Cosmetics/SUGAR-Cosmetics-logo.png",
        "sort": null,
        "name": "Sugar Cosmetics",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 35
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62bd8f245cf85913c1395bad",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1656590053/Mjmt%20Avnish/aman/ColorBar/ColorbarLogo.webp",
        "sort": null,
        "name": "Colorbar Cosmetics",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 35,
            "nonRetail": 45
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62dfbdd27e0271e9e420fec1",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1658830228/Mjmt%20Avnish/aman/OLAPLEX/Olaplex_Logo.png",
        "sort": null,
        "name": "OLAPLEX",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 26,
            "nonRetail": 26
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "62ff3757a7f529a4e1da7bac",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1660891141/Mjmt%20Avnish/aman/Kryolan/Kryolan.png",
        "sort": null,
        "name": "Kryolan",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 10
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "6343c6a7f2ff00cc06d2313c",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1665386053/Mjmt%20Avnish/aman/Sebamed/SebamedLogo.jpg",
        "sort": null,
        "name": "Sebamed",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 50,
            "nonRetail": 50
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "63735bd1050ee44db6d22edd",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1668502856/Mjmt%20Avnish/aman/Bblunt/1647259145-1_crop.png",
        "sort": null,
        "name": "BBlunt",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 25,
            "nonRetail": 25
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "63981e79e1d6d3bb69efe30d",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1670913631/Mjmt%20Avnish/aman/unnamed.png",
        "sort": null,
        "name": "Buyume",
        "videoUrl": ""
    },
    {
        "margin": {
            "retail": 42,
            "nonRetail": 40
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "63ad5c15f65de6c906a36c41",
        "name": "Cadiveu",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1672305049/Mjmt%20Avnish/aman/Cadiveu/Cadiveu_Logo.png",
        "videoUrl": "",
        "sort": null
    },
    {
        "margin": {
            "retail": 10,
            "nonRetail": 10
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "63ce71a8c77fc5509ce04f07",
        "name": "Gillette",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1674473821/ProductImages/Aman/Gillette/Gillette-logo.png",
        "videoUrl": "",
        "sort": null
    },
    {
        "margin": {
            "retail": 40,
            "nonRetail": 25
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "63dcb10020b6d1c0f93d6e1b",
        "name": "Beauty Garage",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1675407359/ProductImages/Aman/Beautygarage/BG-NEw-Logo-300x169.png",
        "videoUrl": "",
        "sort": null
    },
    {
        "margin": {
            "retail": 30,
            "nonRetail": 30
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "64904471a27a61c1bfea709b",
        "name": "Laser",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1687176273/ProductImages/Aman/Laser/images.png",
        "videoUrl": "",
        "sort": null
    },
    {
        "margin": {
            "retail": 78,
            "nonRetail": 78
        },
        "nonretailUpFrontDiscount": 0,
        "retailUpFrontDiscount": 0,
        "_id": "6496c6b654cd62adebcce78f",
        "name": "Svish",
        "imageUrl": "https://res.cloudinary.com/dyqcevdpm/image/upload/v1687602665/ProductImages/Aman/Svish/logo_dark.jpg",
        "videoUrl": "",
        "sort": null
    }
]