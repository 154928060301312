// export const calculateDiscount = (product, membershipExist) => {
//     if (product.lightningSaleDiscount > 0) {
//         return product.baseDiscount + product.flashSaleDiscount + product.lightningSaleDiscount
//     } else if (product.totalProductDiscount >= 0) {
//         return product.totalProductDiscount;
//     } else {
//         product.clubDiscount = product?.clubDiscount || product.clubdiscount
//         if (membershipExist) {

//             if (product.flashSaleDiscount <= product.clubDiscount) {
//                 return product.baseDiscount + product.clubDiscount
//             }
//             else {
//                 return product.baseDiscount + product.flashSaleDiscount;
//             }
//         } else {
//             return product.baseDiscount + product.flashSaleDiscount;
//         }
//     }
// };
export const calculateDiscount = (product, membershipExist) => {
    // console.log('product.clubDiscount',product)
    // console.log("membershipExist",isCurrentMemberShi)
    if (product?.extraDiscount) {
        // console.log('product?.extraDiscount',product?.baseDiscount + product?.flashSaleDiscount +product?.extraDiscount+product?.membership?.discountPercent)
        return product?.baseDiscount + product?.flashSaleDiscount + product?.extraDiscount + product?.membership?.discountPercent
    }

    if (product.lightningSaleDiscount > 0) {
        return product.baseDiscount + product.flashSaleDiscount + product.lightningSaleDiscount
    } else if (product.totalProductDiscount >= 0 && product.totalProductDiscount != null && product.totalProductDiscount != undefined) {
        return product.totalProductDiscount;
    } else {

        product.clubDiscount = product?.clubDiscount || 0 || product.clubdiscount || 0
        if (membershipExist) {


            if (product?.extraDiscount) {
                return product?.baseDiscount + product?.flashSaleDiscount + product?.extraDiscount + product.clubDiscount
            }

            if (product.flashSaleDiscount <= product.clubDiscount) {
                return product.baseDiscount + product.clubDiscount
            }
            else {
                return product.baseDiscount + product.flashSaleDiscount;
            }
        } else {
            return product.baseDiscount + product.flashSaleDiscount;
        }
    }
};
export const calculateSellingPrice = (product, membershipExist) => {
    let value = Math.ceil(product?.mrp ? Math.ceil(product.mrp) * (100 - calculateDiscount(product, membershipExist)) / 100 : Math.ceil(product.menuPrice) * (100 - calculateDiscount(product, membershipExist)) / 100)
    return value;
}

